import { useCallback } from "react"
import { useDownload } from "../../../utils/fetch/useFetch"
import { BChangeRequestListResult, bChangeRequestListResultToJson } from "../models/BChangeRequestListResult"
import { BUChangeOrderDetailResult, buChangeOrderDetailResultToJson } from "../models/BUChangeOrderDetailResult"
import { ChangeOrderDetailResult, changeOrderDetailResultToJson } from "../models/ChangeOrderDetailResult"
import { CustomerOrderListFactor, customerOrderListFactorToJson } from "../models/CustomerOrderListFactor"
import { POBalanceFactor, POBalanceFactorToJson } from "../models/POBalanceFactor"
import { PlaceChangeWCDetailResult, placeChangeWCDetailResultSerializer } from "../models/PlaceChangeWCDetailResult"
import { PlaceRegularOrderResult, placeRegularOrderResultSerializer } from "../models/PlaceOrderDetailResult"
import { PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResultSerializer } from "../models/PlaceOrderForCustomerDetailResult"
import { PurchaseOrderListFactor, purchaseOrderDownloadListFactorToJson } from "../models/PurchaseOrderListFactor"
import { ReSpotOrderResult, reSpotOrderResultSerializer } from "../models/ReSpotOrderDetailResult"
import { SChangeOrderDetailResult, sChangeOrderDetailResultToJson } from "../models/SChangeOrderDetailResult"
import { SalesOrderDownloadListFactor, salesOrderDownloadListFactorToJson } from "../models/SalesOrderListFactor"
import { SpotOrderResult, spotOrderResultSerializer } from "../models/SpotOrderDetailResult"

export const useDownloadPrice = () => {
    const url = '/lcbm-order-api/order/download/downloadPrice'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    return useDownload<{ orderId: number }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDrForSupplier = () => {
    const url = '/lcbm-order-api/order/download/downloadDrForSupplier'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    return useDownload<{ orderId: number }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadRegularOrderformForCustomer = () => {
    const url = '/lcbm-order-api/order/download/downloadRegularOrderformForCustomer'
    const payloadProject = useCallback(placeRegularOrderResultSerializer, [])
    return useDownload<PlaceRegularOrderResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' })
}

export const useDownloadSpotOrderformForCustomer = () => {
    const url = '/lcbm-order-api/order/download/downloadSpotOrderformForCustomer'
    const payloadProject = useCallback(placeRegularOrderResultSerializer, [])
    return useDownload<PlaceRegularOrderResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' })
}

export const useDownloadOrderChangeByCustomer = () => {
    const url = '/lcbm-order-api/order/download/downloadOrderChangeByCustomer'
    const payloadProject = useCallback(changeOrderDetailResultToJson, [])
    return useDownload<ChangeOrderDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Change Form.xlsx' })
}

export const useDownloadFcChangeByCustomer = () => {
    const url = '/lcbm-order-api/order/download/downloadFcChangeByCustomer'
    const payloadProject = useCallback(changeOrderDetailResultToJson, [])
    return useDownload<ChangeOrderDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Change Form.xlsx' })
}

export const useDownloadSpotOrderWithContractForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/download/downloadSpotOrderWithContractformForCustomer'
    const payloadProject = useCallback(spotOrderResultSerializer, [])
    return useDownload<SpotOrderResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' })
}

export const useDownloadSpotOrderWithContractStep1ForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/download/downloadSpotOrderWithContractStep1formForCustomer'
    const payloadProject = useCallback(spotOrderResultSerializer, [])
    return useDownload<SpotOrderResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' })
}

export const useDownloadReceivedSpotOrder = () => {
    const url = '/lcbm-spot-order-api/order/download/downloadReceivedSpotOrder'
    const payloadProject = useCallback(reSpotOrderResultSerializer, [])
    return useDownload<ReSpotOrderResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Received Place Order Form.xlsx' })
}

export const useDownloadPlaceOrderChangeWithoutContract = () => {
    const url = '/lcbm-spot-order-api/order/download/downloadPlaceOrderChangeWithoutContract'
    const payloadProject = useCallback(placeChangeWCDetailResultSerializer, [])
    return useDownload<PlaceChangeWCDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Place Order Change/Cancel.xlsx' })
}

export const useDownloadPlaceOrderChangeWithoutContractForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/download/downloadPlaceOrderChangeWithoutContractForCus'
    const payloadProject = useCallback(PlaceOrderForCustomerDetailResultSerializer, [])
    return useDownload<PlaceOrderForCustomerDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Place Order Change/Cancel For Customer.xlsx' })
}

export const useDownloadCustomerOrderByExcel = () => {
    const url = '/lcbm-order-api/order/download/downloadCustomerOrderByExcel'
    const payloadProject = useCallback((obj: { coIds: number[] }) => obj.coIds, [])
    return useDownload<{ coIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadPurchaseOrderByExcel = () => {
    const url = '/lcbm-order-api/order/download/downloadPurchaseOrderByExcel'
    const payloadProject = useCallback((obj: { poIds: number[] }) => obj.poIds, [])
    return useDownload<{ poIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSalesOrderByExcel = () => {
    const url = '/lcbm-order-api/order/download/downloadSalesOrderByExcel'
    const payloadProject = useCallback((obj: { soIds: number[] }) => obj.soIds, [])
    return useDownload<{ soIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

// PO DOWNLOAD
export const useDownloadPOByOrderIds = () => {
    const url = '/lcbm-order-api/order/download/downloadPOByPoIds';
    const payloadProject = useCallback((obj: { poIds: number[] }) => obj, []);
    return useDownload<{ poIds: number[] }>(url, payloadProject, { fileTimestamp: true });
}
// PO DOWNLOAD
export const useDownloadPOByFilters = () => {
    const url = '/lcbm-order-api/order/download/downloadPOByFilters';
    const payloadProject = useCallback(purchaseOrderDownloadListFactorToJson, []);
    return useDownload<PurchaseOrderListFactor>(url, payloadProject, { fileTimestamp: true });
}

// SO DOWNLOAD
export const useDownloadSOByOrderIds = () => {
    const url = '/lcbm-order-api/order/download/downloadSOByPoIds';
    const payloadProject = useCallback((obj: { soIds: number[] }) => obj, []);
    return useDownload<{ soIds: number[] }>(url, payloadProject, { fileTimestamp: true });
}

// SO DOWNLOAD
export const useDownloadSOByFilters = () => {
    const url = '/lcbm-order-api/order/download/downloadSOByFilters';
    const payloadProject = useCallback(salesOrderDownloadListFactorToJson, []);
    return useDownload<SalesOrderDownloadListFactor>(url, payloadProject, { fileTimestamp: true });
}

// CO DOWNLOAD
export const useDownloadCOByOrderIds = () => {
    const url = '/lcbm-order-api/order/download/downloadCOByPoIds';
    const payloadProject = useCallback((obj: { coIds: number[] }) => obj.coIds, []);
    return useDownload<{ coIds: number[] }>(url, payloadProject, { fileTimestamp: true });
}

// CO DOWNLOAD
export const useDownloadCOByFilters = () => {
    const url = '/lcbm-order-api/order/download/downloadCOByFilters';
    const payloadProject = useCallback(customerOrderListFactorToJson, [])
    return useDownload<CustomerOrderListFactor>(url, payloadProject, { fileTimestamp: true });
}

// Supplier DOWNLOAD
export const useDownloadSuppOrderChange = () => {
    const url = '/lcbm-order-api/order/download/downloadSuppOrderChange'
    const payloadProject = useCallback(sChangeOrderDetailResultToJson, [])
    return useDownload<SChangeOrderDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Change Form.xlsx' })
}
//useDownloadPOBalanceByFilter
export const useDownloadPOBalanceByFilter = () => {
    const url = '/lcbm-order-api/order/download/poBalance'
    const payloadProject = useCallback(POBalanceFactorToJson, [])
    return useDownload<POBalanceFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadBUOrderChange = () => {
    const url = '/lcbm-order-api/order/download/downloadBuOrderChange'
    const payloadProject = useCallback(bChangeRequestListResultToJson, [])
    return useDownload<BChangeRequestListResult>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Change Form.xlsx' });
}

export const useDownloadMapaCCHistory = () => {
    const url = '/lcbm-order-api/order/download/downloadBuChangeHistory'
    const payloadProject = useCallback((obj: { changeHistoryId: number }) => obj.changeHistoryId, []);
    return useDownload<{ changeHistoryId: number }>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Change History.xlsx' });
}

export const useDownloadOrderChangeByBU = () => {
    const url = '/lcbm-order-api/order/download/downloadOrderChangeByBuyerBu'
    const payloadProject = useCallback(buChangeOrderDetailResultToJson, [])
    return useDownload<BUChangeOrderDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'BU Order Change Form.xlsx' })
}

export const useDownloadFcChangeByBU = () => {
    const url = '/lcbm-order-api/order/download/downloadFcChangeByBuyerBu'
    const payloadProject = useCallback(buChangeOrderDetailResultToJson, [])
    return useDownload<BUChangeOrderDetailResult>(url, payloadProject, { fileTimestamp: true, fileName: 'BU Order Change Form.xlsx' })
}