import { IconButton, Tooltip } from '@material-ui/core'
import { DoneAll, LocationOn } from '@material-ui/icons'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import { DateRangeItem, StringItem } from '@rithe/form'
import { Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFunctionStore } from '../../../Root'
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from '../../../components/DataGrid/rowActions/CallbackRowAction'
import { DetailRedirectRowAction } from '../../../components/DataGrid/rowActions/DetailRedirectRowAction'
import { ForecastRedirectRowAction } from '../../../components/DataGrid/rowActions/ForecastRedirectRowAction'
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { PrintGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction'
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { CodesItem } from '../../../components/Form/CodesItem'
import { View } from '../../../components/View/View'
import { usePrintDownloadPDF } from '../../../services/delivery/apis/deliveryOutboundApi'
import { useCheckShippingDetailConfirm } from '../../../services/delivery/apis/deliveryShippingDetailApi'
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadPOByFilters, useDownloadPOByOrderIds, useDownloadPurchaseOrderByExcel } from '../../../services/order/apis/OrderDownloadApi'
import { useForceComplete } from '../../../services/order/apis/PurchaseOrderApi'
import { OrderType } from "../../../services/order/enums/OrderType"
import { PoStatus } from '../../../services/order/enums/PoStatus'
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import { PurchaseOrderListFactor } from "../../../services/order/models/PurchaseOrderListFactor"
import { useGetCodeName } from '../../../utils/CodeCategoryUtil'
import { applicationActions } from '../../Application/applicationSlice'

export interface OPS010PcUiProps {
    filters: PurchaseOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<PurchaseOrderListFactor>>,
    search: (filters: PurchaseOrderListFactor) => void,
    data: OrderListResult[],
    totalCount: number
}

export const OPS010PcUi = (props: OPS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = memo(({ filters, data, setFilters, search }: OPS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const { getCodeName } = useGetCodeName()

    const getStatusName = useCallback((row: Row) => {
        const status = row.status as number
        const orgStatus = row.orgStatus as number
        return (orgStatus === PoStatus.FORCE_COMPLETED && status === PoStatus.PROCESSING) ? getCodeName(CodeCategory.PoStatus, status) + "(" + getCodeName(CodeCategory.PoStatus, orgStatus) + ")" : getCodeName(CodeCategory.PoStatus, status)
    }, [getCodeName])

    const columns = useMemo(() => [
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'sellerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerOrderNo' }), width: 300 },
        { field: 'orderRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderReference' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'contractOrGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractOrGroupNo' }), width: 300 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 180 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 240, getCellValue: getStatusName },
        { field: 'delayStatus', dataTypeName: CodeCategory.DelayStatus, title: intl.formatMessage({ id: 'delayStatus' }), width: 180 },
        { field: 'reviseVersion', dataTypeName: 'number', title: intl.formatMessage({ id: 'reviseVersion' }), width: 180 },
    ], [getStatusName, intl])

    const getRowId = useCallback((row: any) => row.orderId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, search, setFilters])
    const actionProps1 = useMemo(() => ({ selections, filters }), [filters, selections])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const displayForForecastDetail = useCallback((tableRow: TableRow) => (tableRow.row?.orderType === OrderType.REGULAR) && tableRow.row?.forecastNum !== undefined && tableRow.row?.forecastNum > 0, [])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status !== PoStatus.RECEIVED && tableRow.row?.status !== PoStatus.CANCELLED, [])
    const displayData = data.map(d => ({ ...d, contractOrGroupNo: d.contractNo ? d.contractNo : d.groupNo }))
    const displayForForecomplete = useCallback((tableRow: TableRow) => (tableRow.row?.status === PoStatus.CONFIRMED || tableRow.row?.status === PoStatus.PROCESSING) && tableRow.row?.orgStatus !== PoStatus.FORCE_COMPLETED && tableRow.row?.contractNo !== null, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DelayStatus} />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        {/* <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} /> */}
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <RowActionProvider name="forecast" Action={ForecastRowAction} display={displayForForecastDetail} />
        <RowActionProvider name="forceComplete" Action={ForceCompleteRowAction} actionProps={actionProps2} display={displayForForecomplete} />
        <RowActionProvider name="viewCargoTracking" Action={ViewCargoTrackingRowAction} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['reviseVersion', 'contractRouteCode']}
            columnSettings={{
                orderNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={150} />
    </DataGrid>
})



const FilterItem = memo((props: {
    filters: PurchaseOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<PurchaseOrderListFactor>>,
    search: (filters: PurchaseOrderListFactor) => void
}) => {
    const { filters, setFilters, search } = props


    const orderDateGetValue = useCallback((filters: PurchaseOrderListFactor) => {
        return [filters.orderDateStart ?? null, filters.orderDateEnd ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: PurchaseOrderListFactor, value: any) => {
        return { ...filters ?? {}, orderDateStart: value[0], orderDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: PurchaseOrderListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: PurchaseOrderListFactor) => {
        return [
            filters.orderNo,
            filters.sellerOrderNo,
            filters.orderRefNo,
            filters.contractNo,
            filters.contractRouteCode,
            filters.orderTypeList,
            filters.orderFrequencyList,
            filters.shippingModeList,
            filters.statusList,
            filters.delayStatusList,
            filters.orderDateStart || filters.orderDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <StringItem field="sellerOrderNo" label={intl.formatMessage({ id: 'field.sellerOrderNo' })} />
        <StringItem field="orderRefNo" label={intl.formatMessage({ id: 'field.orderReference' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractOrGroupNo' })} labelWidth={150} />
        <StringItem field="contractRouteCode" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.PoStatus} />
        <CodesItem field="delayStatusList" label={intl.formatMessage({ id: 'delayStatus' })} code={CodeCategory.DelayStatus} />
        <DateRangeItem field="orderDate" label={intl.formatMessage({ id: 'orderDate' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>
})

const PrintAction = ({ selections }: { selections: number[] }) => {
    const printApi = usePrintDownloadPDF()
    const print = useCallback(() => printApi({ reportId: 'OPR010', labelId: 'Purchase Order', param: JSON.stringify(selections) }), [printApi, selections])
    return <PrintGroupedToolbarAction access="ORDER.OPS010.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/po/detail-${tableRow.rowId}`, [])
    return <DetailRedirectRowAction tableRow={tableRow} access="ORDER.OPS010.VIEWDETAIL" path={path} />
}

const ViewCargoTrackingRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const checkConfirm = useCheckShippingDetailConfirm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const click = useCallback(() => {
        checkConfirm({ orderNo: tableRow.row?.orderNo }, { silent: true }).then(result => {
            if (result === false) {
                dispatch(applicationActions.pushError({ title: { code: 'viewCargoTracking' }, messages: { code: 'w1039' } }))
            } else {
                navigate(`/po/cargotrackingDetail-${tableRow.row?.orderNo}`)
            }
        })
    }, [checkConfirm, dispatch, navigate, tableRow.row?.orderNo])

    return <Tooltip title={<FormattedMessage id="viewCargoTracking" />}>
        <IconButton onClick={click}>
            <LocationOn />
        </IconButton>
    </Tooltip>
}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/po/fc-${tableRow.rowId}`, [])
    return <ForecastRedirectRowAction tableRow={tableRow} access="ORDER.OPS010.VIEWFORECAST" path={path} />
}
const DownloadAction = ({ selections, filters }: { selections: number[], filters: PurchaseOrderListFactor }) => {
    const dispatch = useDispatch()
    const dowloadApi = useDownloadPurchaseOrderByExcel()
    const downloadPOByOrderIds = useDownloadPOByOrderIds()
    const downloadPOByFilters = useDownloadPOByFilters()

    const dowload = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadByExcel' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ poIds: selections })
    }, [dispatch, dowloadApi, selections])

    const download2 = useCallback(() => {
        if (selections.length <= 0) {
            downloadPOByFilters(filters)
        } else {
            downloadPOByOrderIds({ poIds: selections })
        }
    }, [downloadPOByFilters, downloadPOByOrderIds, filters, selections])

    return <DownloadGroupedToolbarAction access="ORDER.OPS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadByExcel" />} callback={dowload} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download2} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ForceCompleteRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: PurchaseOrderListFactor, search: (filters: PurchaseOrderListFactor) => void }) => {
    const forceCompleteApi = useForceComplete()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        const functionId = functionStore.register(() => {
            forceCompleteApi({ orderIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, forceCompleteApi, functionStore, search, title])
    return <CallbackRowAction tableRow={tableRow} access="ORDER.OPS010.FORCECOMPLETED" title={<FormattedMessage id="forceComplete" />} icon={<DoneAll />} callback={callback} disabled={disabled} />
}

