import { MenuItem, MenuList, Tooltip } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateItem } from "@rithe/form"
import { Records, usePopover } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { PopoverMenu } from "../../../components/PopoverMenu"
import { View } from "../../../components/View/View"
import { useExportContainerDataByBatch, useRefreshCargoTrackingBybatch } from "../../../services/cargo/api/cargoTackingApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useCombineOrder } from "../../../services/order/apis/PlaceOrderApi"
import {
    useCheckInventoryAlert,
    useCheckToDoList,
    useDoCalculatePokayoke,
    useDoCompareBalance,
    useDoGenerationDailyStock,
    useDoInitOnlineChatUser,
    useDoSyncETLDataLogic,
    useRunETLDataProcessing,
    useSendMailEtlBatchFail,
    useSyncETLData,
    useSyncEtlForPnaWip
} from "../../../services/smt/api/ETLBatchApi"
import { EtlBatchTransfer } from "../../../services/smt/models/EtlBatchTransfer"
import { EtlBatchView } from "../../../services/smt/models/EtlBatchView"
import { applicationActions } from "../../Application/applicationSlice"

interface BTETL010PcUiProps {
    filters: EtlBatchView,
    setFilters: React.Dispatch<React.SetStateAction<EtlBatchView>>,
    data: EtlBatchTransfer[],
    totalCount: number,
    search: (filters: EtlBatchView) => void,
}

export const BTETL010PcUi = (props: BTETL010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={'ETL BATCH RUN LIST'} subtitle={"Auto Batch excute SEQ : COMBINE BATCH -> LCBM DAILY BATCH -> ETL BATCH -> SMT DAILY BATCH"} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ data, search, filters, setFilters }: BTETL010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const [disabled, setDisabled] = useState<boolean>(false)

    const columns = useMemo(() => [
        // { field: 'batchId', dataTypeName: 'number', title: intl.formatMessage({ id: 'batchId' }), width: 200 },
        { field: 'dataSource', dataTypeName: CodeCategory.DataSource, title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
        { field: 'generateTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'generateTime' }), width: 200 },
        { field: 'launchTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'launchTime' }), width: 200 },
        // { field: 'backupTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'backupTime' }), width: 200 },
        { field: 'verifyTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'verifyTime' }), width: 200 },
        { field: 'storeTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'storeTime' }), width: 200 },
        { field: 'runTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'runTime' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'createdDate' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'updatedDate' }), width: 200 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters, disabled, setDisabled }), [search, filters, disabled, setDisabled])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} />
        <ToolbarActionProvider Action={RunLCBMBatchAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={ETLDataLogicAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={RunSmtBatchAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={RunOnlineChatBatchAction} actionProps={actionProps1} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: EtlBatchView,
    setFilters: React.Dispatch<React.SetStateAction<EtlBatchView>>,
    search: (filters: EtlBatchView) => void
}) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: EtlBatchView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: EtlBatchView) => {
        return [
            // filters.batchId,
            filters.generateDate
        ].filter(value => value !== undefined && value !== null).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        {/* <NumberItem field='batchId' label={intl.formatMessage({ id: 'batchId' })} /> */}
        <DateItem field='generateDate' label={intl.formatMessage({ id: 'generateDate' })} />
    </FilterToolbarItem>
}

interface BatchButtonItem {
    filters: EtlBatchView,
    search: (filters: EtlBatchView) => void,
    disabled: boolean,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const ETLDataLogicAction = ({ filters, search, disabled, setDisabled }: BatchButtonItem) => {

    const { open, anchorEl, onOpen, onClose } = usePopover()

    // all step
    const syncAllETLData = useDoSyncETLDataLogic();
    const syncAllETLDataAction = useCallback(() => {
        setDisabled(true)
        syncAllETLData(undefined, { silent: false, serialized: true })
            .then(() => search(filters))
            .finally(() => setDisabled(false))
    }, [filters, search, setDisabled, syncAllETLData])

    // step 1: copy data from LCBM
    const step1EtlData = useSyncETLData();
    const step1EtlDataAction = useCallback(() => {
        setDisabled(true)
        step1EtlData(undefined, { silent: false, serialized: true }).then(() => search(filters)).finally(() => setDisabled(false))
    }, [filters, search, setDisabled, step1EtlData])

    // step 2: Process Normal IF Data
    const step2EtlData = useRunETLDataProcessing();
    const step2EtlDataAction = useCallback(() => {
        setDisabled(true)
        step2EtlData(undefined, { silent: false, serialized: true }).then(() => search(filters)).finally(() => setDisabled(false))
    }, [filters, search, setDisabled, step2EtlData])

    // step 3: Process PNA WIP information
    const step3EtlData = useSyncEtlForPnaWip();
    const step3EtlDataAction = useCallback(() => {
        setDisabled(true)
        step3EtlData(undefined, { silent: false, serialized: true }).then(() => search(filters)).finally(() => setDisabled(false))
    }, [filters, search, setDisabled, step3EtlData])

    // step 4: batch fail send mail
    const step4EtlData = useSendMailEtlBatchFail();
    const step4EtlDataAction = useCallback(() => {
        setDisabled(true)
        step4EtlData(undefined, { silent: false, serialized: true }).then(() => search(filters)).finally(() => setDisabled(false))
    }, [filters, search, setDisabled, step4EtlData])


    return <>
        <Tooltip title={<FormattedMessage id="The batch which copy outbound/inbound/order/contract etc information from LCBM to SMT side, generate inventory/inout/order etc data in SMT. will auto excute 02:00 SG Time" />}>
            <NeumorphismButton onClick={onOpen} variant="contained" disabled={disabled} style={{ marginLeft: 8, width: 160, backgroundColor: '#003362', color: '#fff' }}>
                <FormattedMessage id="ETL BATCH" />
            </NeumorphismButton >
        </Tooltip>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            onClose={onClose}
        >
            <MenuList>
                <Tooltip title={<FormattedMessage id="Process all step from step 1 to step 3" />}>
                    <MenuItem onClick={() => {
                        syncAllETLDataAction()
                        onClose()
                    }}><FormattedMessage id="SYNC ALL STEPS (SUGGEST)" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Step 1: Copy realtime data to smt side" />}>
                    <MenuItem onClick={() => {
                        step1EtlDataAction()
                        onClose()
                    }}><FormattedMessage id=" >> STEP 1:TRANSFER DATA TO SMT" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Step 2: Process transferd data, and write order/inout/parts/plan information" />}>
                    <MenuItem onClick={() => {
                        step2EtlDataAction()
                        onClose()
                    }}><FormattedMessage id=" >> STEP 2:PROCESS ETL DATA" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Step 3: Process manual inbound/outbound to wip data for PNA" />}>
                    <MenuItem onClick={() => {
                        step3EtlDataAction()
                        onClose()
                    }}><FormattedMessage id=" >> STEP 3:PROCESS PNA WIP" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Step 4: batch faliurs send email" />}>
                    <MenuItem onClick={() => {
                        step4EtlDataAction()
                        onClose()
                    }}><FormattedMessage id=" >> STEP 4:BATCH FAILED SEND MAIL" /></MenuItem>
                </Tooltip>
            </MenuList>
        </PopoverMenu>
    </>
}


const RunLCBMBatchAction = ({ disabled, setDisabled }: BatchButtonItem) => {

    const { open, anchorEl, onOpen, onClose } = usePopover()

    const combineOrder = useCombineOrder()
    const combineOrderAction = useCallback(() => {
        setDisabled(true)
        combineOrder(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [combineOrder, setDisabled])

    const runDailyBatch = useCheckToDoList()
    const runDailyBatchAction = useCallback(() => {
        setDisabled(true)
        runDailyBatch(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [runDailyBatch, setDisabled])

    const refreshCargoTracking = useRefreshCargoTrackingBybatch()
    const refreshCargoTrackingAction = useCallback(() => {
        setDisabled(true)
        refreshCargoTracking(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [refreshCargoTracking, setDisabled])

    const exportContainerData = useExportContainerDataByBatch()
    const exportContainerDataAction = useCallback(() => {
        setDisabled(true)
        exportContainerData(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [exportContainerData, setDisabled])

    return <>
        <Tooltip title={<FormattedMessage id="Include daily batch and combine batch" />}>
            <NeumorphismButton onClick={onOpen} variant="contained" disabled={disabled} style={{ marginLeft: 8, width: 160, backgroundColor: '#003362', color: '#fff' }}>
                <FormattedMessage id="LCBM BATCH" />
            </NeumorphismButton >
        </Tooltip>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            onClose={onClose}
        >
            <MenuList>
                <Tooltip title={<FormattedMessage id="Combine order." />}>
                    <MenuItem onClick={() => {
                        combineOrderAction()
                        onClose()
                    }}><FormattedMessage id="COMBINE BATCH" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Check inbound/outbound delay/re-run simulation/supplier order confirm.(pending: mail to do list to user)." />}>
                    <MenuItem onClick={() => {
                        runDailyBatchAction()
                        onClose()
                    }}><FormattedMessage id="DAILY BATCH" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Sync container tracking data from port cast API(only for uncompleted tracking)." />}>
                    <MenuItem onClick={() => {
                        refreshCargoTrackingAction()
                        onClose()
                    }}><FormattedMessage id="REFRESH PORTCAST DATA" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Export container tracking data" />}>
                    <MenuItem onClick={() => {
                        exportContainerDataAction()
                        onClose()
                    }}><FormattedMessage id="EXPORT CONTAINER DATA" /></MenuItem>
                </Tooltip>
            </MenuList>
        </PopoverMenu>
    </>
}

const RunSmtBatchAction = ({ disabled, setDisabled }: BatchButtonItem) => {

    const { open, anchorEl, onOpen, onClose } = usePopover()

    const doGenerationDailyStock = useDoGenerationDailyStock()
    const smtDailyBatchAction = useCallback(() => {
        setDisabled(true)
        doGenerationDailyStock(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [doGenerationDailyStock, setDisabled])

    const doCalculatePokayoke = useDoCalculatePokayoke()
    const okayokeBatchAction = useCallback(() => {
        setDisabled(true)
        doCalculatePokayoke(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [doCalculatePokayoke, setDisabled])

    const doCompareBalance = useDoCompareBalance()
    const compareBalanceBatchAction = useCallback(() => {
        setDisabled(true)
        doCompareBalance(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [doCompareBalance, setDisabled])

    const checkInventoryAlert = useCheckInventoryAlert()
    const checkInventoryAlertAction = useCallback(() => {
        setDisabled(true)
        checkInventoryAlert(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
    }, [checkInventoryAlert, setDisabled])

    return <>
        <Tooltip title={<FormattedMessage id="Include smt daily batch and pokayoke batch" />}>
            <NeumorphismButton onClick={onOpen} variant="contained" disabled={disabled} style={{ marginLeft: 8, width: 120, backgroundColor: '#003362', color: '#fff' }}>
                <FormattedMessage id="SMT BATCH" />
            </NeumorphismButton >
        </Tooltip>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
            onClose={onClose}
        >
            <MenuList>
                <Tooltip title={<FormattedMessage id="Run daily batch for SMT(include refresh daily inventory and daily wip qty), those data will used in OC and Rundown. so if you need use rundown or OC, be sure this batch already done. will auto excute each 02:00 SG time" />}>
                    <MenuItem onClick={() => {
                        smtDailyBatchAction()
                        onClose()
                    }}><FormattedMessage id="DAILY BATCH" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Generate pokayoke data(currently no used)" />}>
                    <MenuItem onClick={() => {
                        okayokeBatchAction()
                        onClose()
                    }}><FormattedMessage id="POKAYOKE BATCH" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Balance Order Comparison" />}>
                    <MenuItem onClick={() => {
                        compareBalanceBatchAction()
                        onClose()
                    }}><FormattedMessage id="BALANCE COMPARE BATCH" /></MenuItem>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Inventory Alert Batch" />}>
                    <MenuItem onClick={() => {
                        checkInventoryAlertAction()
                        onClose()
                    }}><FormattedMessage id="INVENTORY ALERT BATCH" /></MenuItem>
                </Tooltip>
            </MenuList>
        </PopoverMenu>
    </>
}

const RunOnlineChatBatchAction = ({ disabled, setDisabled }: BatchButtonItem) => {

    const dispatch = useDispatch()
    const functionStore = useFunctionStore()

    const doInitOnlineChatUser = useDoInitOnlineChatUser()
    const callInitBatchAction = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            doInitOnlineChatUser(undefined, { silent: false, serialized: true }).finally(() => setDisabled(false))
        })
        dispatch(applicationActions.pushWarning({
            title: "CHAT INIT",
            messages: { code: 'c0021', args: ["CHAT INIT"] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))

    }, [dispatch, doInitOnlineChatUser, functionStore, setDisabled])
    return <>
        <Tooltip title={<FormattedMessage id="Online Chat init all user batch.(Only can run when online chat go live!!!!!!)" />}>
            <NeumorphismButton onClick={callInitBatchAction} variant="contained" disabled={disabled} style={{ marginLeft: 8, width: 160, backgroundColor: '#003362', color: '#fff' }}>
                <FormattedMessage id="CHAT INIT BATCH" />
            </NeumorphismButton >
        </Tooltip>
    </>
}


