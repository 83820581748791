
import { Button, ButtonGroup, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@material-ui/core"
import { ColumnFreeze, Data, DataGrid, DataTypePreset, ObjectTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { DateItem, Form } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { ArrayMultimaps, Arrays, Records } from "@rithe/utils"
import FusionCharts from "fusioncharts"
import Charts from "fusioncharts/fusioncharts.charts"
import Widgets from "fusioncharts/fusioncharts.widgets"
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import ReactFusioncharts from 'react-fusioncharts'
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { CardPanel } from "../../../components/Card/CardPanel"
import { CardTab } from "../../../components/Card/CardTab"
import { TabsCard } from "../../../components/Card/TabsCard"
import { TabsCardContent } from "../../../components/Card/TabsCardContent"
import { TabsCardHeader } from "../../../components/Card/TabsCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PopoverMenu } from "../../../components/PopoverMenu"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CustomerStockFlag } from "../../../services/master/enums/CustomerStockFlag"
import { InventoryBoxFlag } from "../../../services/master/enums/InventoryBoxFlag"
import { WorkingFlag } from "../../../services/master/enums/WorkingFlag"
import { DailyRundownContainerTrackingResult } from "../../../services/smt/stockManagement/models/DailyRundownContainerTrackingResult"
import { DailyRundownFactor } from "../../../services/smt/stockManagement/models/DailyRundownFactor"
import { Daily, DailyRundownResult } from "../../../services/smt/stockManagement/models/DailyRundownResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { DateInput } from "./DateInput"


export interface SMGTS211PcUiProps {
    filters: DailyRundownFactor,
    data: DailyRundownResult,
    setFilters: React.Dispatch<SetStateAction<DailyRundownFactor>>,
    partsList: Daily[],
    setPartsList: React.Dispatch<React.SetStateAction<Daily[]>>,
    customerPartsId: number,
    setCustomerPartsId: React.Dispatch<React.SetStateAction<number>>,
    includePlanFlag: boolean,
    setIncludePlanFlag: React.Dispatch<React.SetStateAction<boolean>>,
    baseCarrierDateFlag: boolean,
    setBaseCarrierDateFlag: React.Dispatch<React.SetStateAction<boolean>>,
}

export const SMGTS211PcUi = (props: SMGTS211PcUiProps) => {
    const { partsList, setPartsList, customerPartsId, setCustomerPartsId, data } = props
    const intl = useIntl()
    const onSelectedValueChange = useCallback((value: string | number) => {
        setCustomerPartsId(value as number)
    }, [setCustomerPartsId])

    const closeCommon = (tabId: number) => {
        if (partsList?.length > 1) {
            setPartsList(partsList => partsList.filter(a => a.customerPartsId !== tabId))
        }
    }
    ReactFusioncharts.fcRoot(FusionCharts, Charts, Widgets, FusionTheme);

    return <View>
        <TabsCard>
            <TabsCardHeader selectedValue={customerPartsId} onSelectedValueChange={onSelectedValueChange}>
                {partsList.map(m => <CardTab key={m.partsNo} title={m.partsNo} subtitle={m.partsNo} value={m.customerPartsId} onClose={closeCommon} />)}
            </TabsCardHeader>
            <TabsCardContent>
                <CardPanel value={customerPartsId}>
                    {/* <SimpleCard>
                        <SimpleCardContent> */}
                    <CardGridView data={data} />
                    <SectionTitle size='large' ><FormattedMessage id='breakdownTable' /></SectionTitle>
                    <CardTab title={''} value={''} subtitle={intl.formatMessage({ id: 'actualOnlyOrIncludePlan1' })} />
                    <CardTab title={''} value={''} subtitle={intl.formatMessage({ id: 'actualOnlyOrIncludePlan2' })} />
                    <DateView  {...props} />
                    <CardDataTable data={data} />
                    <SectionTitle size='large' ><FormattedMessage id='breakdownChart' /></SectionTitle>
                    <BreakDownChart data={data} />
                    <LegendView />
                    {/* </SimpleCardContent>
                    </SimpleCard> */}
                </CardPanel>
            </TabsCardContent>
        </TabsCard>
    </View>
}


const CardGridView = ({ data }: { data: DailyRundownResult }) => {
    const intl = useIntl()
    const classes = useStyles();
    const cbdsType = useGetCompanyType()
    const { inventoryBoxFlag, custStockFlag } = data
    const alertByBoxes = inventoryBoxFlag != null && inventoryBoxFlag === InventoryBoxFlag.Y

    return (<><Grid container spacing={3}>
        <Grid item xs>
            <Paper className={classes.paper}>
                <Typography gutterBottom variant="subtitle1">
                    {alertByBoxes ? intl.formatMessage({ id: 'field.StockBoxes' }) : intl.formatMessage({ id: 'field.StockDays' })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {data.stockDaysBoxes ?? "-"}
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs>
            <Paper className={classes.paper}>
                <Typography gutterBottom variant="subtitle1">
                    {intl.formatMessage({ id: 'totalStockQty' })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {data.impTotalStockQty}
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs>
            {
                cbdsType === CbdsType.BU && <Paper className={classes.paper}>
                    <Typography gutterBottom variant="subtitle1">
                        {intl.formatMessage({ id: 'field.CustomerStock' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {data.custStockQty !== null && custStockFlag === CustomerStockFlag.Y ? data.custStockQty : "-"}
                    </Typography>
                </Paper>
            }
        </Grid>
    </Grid>

        <Grid container spacing={3}>
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Typography gutterBottom variant="subtitle1">
                        {intl.formatMessage({ id: 'field.Supplier' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">

                        {data.supplierCode === null ? "-" : data.supplierCode}
                    </Typography>
                </Paper>
            </Grid>
            {
                cbdsType === CbdsType.BU ?
                <Grid item xs>
                    <Paper className={classes.paper}>
                        <Typography gutterBottom variant="subtitle1">
                            {intl.formatMessage({ id: 'field.Customer' })}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {data.customerCode === null ? "-" : data.customerCode}
                        </Typography>
                    </Paper>
                </Grid>
                :
                <Grid item xs>
                    <Paper className={classes.paper}>
                        <Typography gutterBottom variant="subtitle1">
                            {intl.formatMessage({ id: 'field.seller' })}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {data.sellerCode === null ? "-" : data.sellerCode}
                        </Typography>
                    </Paper>
                </Grid>

            }
            <Grid item xs>
                <Paper className={classes.paper}>
                    <Typography gutterBottom variant="subtitle1">
                        {intl.formatMessage({ id: 'field.ContractNumber' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {data.externalRefNo === null ? "-" : data.externalRefNo}
                    </Typography>
                </Paper>
            </Grid>
        </Grid></>)
}

const CardDataTable = ({ data }: { data: DailyRundownResult }) => {
    const intl = useIntl()
    const { dateTimeMap, inventoryBoxFlag, minBoxes1, minBoxes2, minBoxes3, maxBoxes, minDays1, minDays2, minDays3, maxDays, dateList, dateTimeList, calendarMap, drPlanDispMap, drActualDispMap, planEtdDispMap, planEtaDispMap, incomingDispMap, planCustUsageDispMap, actualCustUsageDispMap, impStockQtyDispMap, stockDayOrBoxDispMap, containerTrackMap } = data
    const alertByBoxes = inventoryBoxFlag != null && inventoryBoxFlag === InventoryBoxFlag.Y
    const stockBoxesDays = alertByBoxes ? intl.formatMessage({ id: "field.StockBoxes" }) : intl.formatMessage({ id: "field.StockDays" })
    const calendarList = Object.entries(calendarMap !== undefined ? calendarMap : {});
    let alert1 = 0;
    let alert2 = 0;
    let alert3 = 0;
    let alertMax = 0;
    if (inventoryBoxFlag != null && inventoryBoxFlag === 1) {
        alert1 = minBoxes1 !== undefined && minBoxes1 !== null ? minBoxes1 : 0;
        alert2 = minBoxes2 !== undefined && minBoxes2 !== null ? minBoxes2 : 0;
        alert3 = minBoxes3 !== undefined && minBoxes3 !== null ? minBoxes3 : 0;
        alertMax = maxBoxes !== undefined && maxBoxes !== null ? maxBoxes : 0;
    } else {
        alert1 = minDays1 !== undefined && minDays1 !== null ? minDays1 : 0;
        alert2 = minDays2 !== undefined && minDays2 !== null ? minDays2 : 0;
        alert3 = minDays3 !== undefined && minDays3 !== null ? minDays3 : 0;
        alertMax = maxDays !== undefined && maxDays !== null ? maxDays : 0;
    }

    const objList = [
        { ...dateTimeMap, id: 0, col1: "" },
        { ...drPlanDispMap, id: 1, col1: intl.formatMessage({ id: 'field.DrPlan' }) },
        { ...drActualDispMap, id: 2, col1: intl.formatMessage({ id: 'field.DrActual' }) },
        { ...planEtdDispMap, id: 3, col1: intl.formatMessage({ id: 'field.ETDPOL' }) },
        { ...planEtaDispMap, tracking: containerTrackMap, id: 4, col1: intl.formatMessage({ id: 'field.ETAPOD' }) },
        { ...incomingDispMap, id: 5, col1: intl.formatMessage({ id: 'field.InComing' }) },
        { ...planCustUsageDispMap, id: 6, col1: intl.formatMessage({ id: 'field.OutgoingPlan' }) },
        { ...actualCustUsageDispMap, id: 7, col1: intl.formatMessage({ id: 'field.OutgoingActual' }) },
        { ...impStockQtyDispMap, id: 8, col1: intl.formatMessage({ id: 'field.EndingStockQty' }) },
        { ...stockDayOrBoxDispMap, id: 9, col1: stockBoxesDays }
    ];

    const getCellValue = useCallback((m) => {
        return () => ({ alert1: alert1, alert2: alert2, alert3: alert3, alertMax: alertMax, index: m, workFlag: calendarList.filter(f => f.includes(m))[0] });
    }, [alert1, alert2, alert3, alertMax, calendarList])

    const columns = useMemo(() => {
        const cols = [{ field: 'col1', dataTypeName: 'string', title: "", width: 300 }]
        const cols2 = dateTimeList !== undefined ? dateTimeList.map((m, index) => {
            return { field: m, dataTypeName: 'dayType', title: (dateList !== undefined ? " " + dateList[index] : ""), width: 150, fixed: index === 1 ? 'left' : '', getCellValue: getCellValue(m) }
        }) : []
        return Arrays.concat(cols, cols2)
    }, [dateList, dateTimeList, getCellValue])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <DataTypePreset />
        <DayTypeProvider />
        <Data rows={objList} columns={columns} />
        <ColumnFreeze defaultFreeze={{ col1: 'start' }} />
        {/* <ColumnFreeze disableUserControl /> */}
    </DataGrid>
}

const DateView = (props: SMGTS211PcUiProps) => {
    const intl = useIntl();
    const style = useStyles();
    const { includePlanFlag, setIncludePlanFlag, baseCarrierDateFlag, setBaseCarrierDateFlag, filters, setFilters } = props

    const includePlanClick = useCallback(() => {
        setIncludePlanFlag(true);
    }, [setIncludePlanFlag])
    const actualOnlyClick = useCallback(() => {
        setIncludePlanFlag(false);
    }, [setIncludePlanFlag])
    const carrierOnlyClick = useCallback(() => {
        setBaseCarrierDateFlag(true);
    }, [setBaseCarrierDateFlag])
    const predictionClick = useCallback(() => {
        setBaseCarrierDateFlag(false);
    }, [setBaseCarrierDateFlag])

    return (
        <div style={{ width: '100%' }}>
            <div style={{ float: 'left', textAlign: 'left', width: '70%' }}>
                <Form data={filters} setData={setFilters} labelDisplay="none" labelAlign="start" helperDisplay="tooltip" helperAlign="end" >
                    <GridItem columnSpan={3} >
                        <Grid container>
                            <Grid item xs={4} style={{ paddingTop: 5, fontSize: 16 }}>
                                {intl.formatMessage({ id: 'rundownStartDate' })}:
                            </Grid>
                            <Grid item xs={8}>
                                <DateItem
                                    field="stockDate"
                                    label={intl.formatMessage({ id: 'rundownStartDate' })}
                                    placeholder={intl.formatMessage({ id: 'startDate' })}
                                    Input={DateInput}
                                />
                            </Grid>
                        </Grid>
                    </GridItem>
                </Form>
            </div>
            <div style={{ float: 'right', textAlign: 'right' }}>
                <Grid>
                    <ButtonGroup disableRipple={true} variant="contained" className={style.buttonGroup}>
                        <Button className={includePlanFlag ? style.buttonNotChosen : style.buttonChosen} onClick={actualOnlyClick}>{intl.formatMessage({ id: 'actualOnly' })} </Button>
                        <Button className={includePlanFlag ? style.buttonChosen : style.buttonNotChosen} onClick={includePlanClick}>{intl.formatMessage({ id: 'includePlan' })} </Button>
                    </ButtonGroup>
                </Grid>
                <Grid>
                    <ButtonGroup disableRipple={true} variant="contained" className={style.buttonGroup}>
                        <Button className={baseCarrierDateFlag ? style.buttonChosen : style.buttonNotChosen} onClick={carrierOnlyClick}>{intl.formatMessage({ id: 'carrierOnly' })} </Button>
                        <Button className={baseCarrierDateFlag ? style.buttonNotChosen : style.buttonChosen} onClick={predictionClick}>{intl.formatMessage({ id: 'prediction' })} </Button>
                    </ButtonGroup>
                </Grid>
            </div>
        </div >
    )
}

const DayTypeProvider = () => {
    return <ObjectTypeProvider name="dayType" Formatter={DayFormatter} />
}

const DayFormatter = ({ value, row }: ObjectFormatterProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const theme = useTheme()
    const classes = useStyles()
    const workingFlag = value && value.workFlag && value.workFlag.length && value.workFlag.length > 1 ? value.workFlag[1] : WorkingFlag.WORKING_DAY;
    const fontColor = row.id === 9 ? getFontColorOfChart(value.alert1, value.alert2, value.alert3, value.alertMax, row[value.index]) : ''
    const stockDaysColor = row.id === 9 ? getBgColorOfChart(value.alert1, value.alert2, value.alert3, value.alertMax, row[value.index]) : ''
    const background = row.id !== 9 || stockDaysColor === '' ? (workingFlag === 1 ? '#FFFFFF' : '#DFE6EE') : stockDaysColor
    const hasHoverContent = row.id === 4 && row.tracking[value.index]
    const needScrollBar = row.id === 4 && row.tracking[value.index] && row.tracking[value.index].length > 4
    const differentEta = hasHoverContent
                            && row.tracking[value.index].filter((m: DailyRundownContainerTrackingResult) => m.carrierEta && m.predictionEta && m.carrierEta !== m.predictionEta).length > 0

    const styles: React.CSSProperties = {
        height: '100%',
        width: 'calc(100% + 12px)',
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        overflow: "hidden",
        marginLeft: -6,
        marginRight: -6,
        background: differentEta ? '#EAF6E6' : background,
        marginTop: 1,
        marginBottom: 1,
        color: fontColor,
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    return (
    <div style={styles}>
        <Grid onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
            {hasHoverContent && <PopoverMenu
                className={needScrollBar ? classes.popoverAuto : classes.popoverNone }
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                disableRestoreFocus>
                <ContainerTrackingTable trackings={row.tracking[value.index]}></ContainerTrackingTable>
            </PopoverMenu>}
            <Typography variant="body1">{row[value.index]}</Typography>
        </Grid>
    </div>
    )
}

const getFontColorOfChart = (alert1: number, alert2: number, alert3: number, alertMax: number, alertData: any) => {
    var color = "#000000";
    if (alertData === null || alertData === 'No Usage') {
        return color;
    }
    if (alert3 === 0 && alert2 === 0 && alert1 === 0 && alertMax === 0) {
        return color;
    }

    if (alertData.startsWith(">")) {
        alertData = alertData.substr(1).trim();
    }

    switch (true) {
        case (alertData <= alert3 && alert3 > 0):
            color = FontColors.alert3
            break;
        case (alertData <= alert2 && alert2 > 0):
            color = FontColors.alert2
            break;
        case (alertData <= alert1 && alert1 > 0):
            color = FontColors.alert1
            break;
        case (alertData >= alertMax && alertMax > 0):
            color = FontColors.max
            break;
        default:
            color = FontColors.noAlert
            break
    }

    return color;
}

const getBgColorOfChart = (alert1: number, alert2: number, alert3: number, alertMax: number, alertData: any) => {

    var color = "";
    if (alertData === null || alertData === 'No Usage') {
        return color;
    }
    if (alert3 === 0 && alert2 === 0 && alert1 === 0 && alertMax === 0) {
        return color;
    }

    if (alertData.startsWith(">")) {
        alertData = alertData.substr(1).trim();
    }

    switch (true) {
        case (alertData <= alert3 && alert3 > 0):
            color = BGColors.alert3
            break;
        case (alertData <= alert2 && alert2 > 0):
            color = BGColors.alert2
            break;
        case (alertData <= alert1 && alert1 > 0):
            color = BGColors.alert1
            break;
        case (alertData >= alertMax && alertMax > 0):
            color = BGColors.max
            break;
        default:
            color = BGColors.noAlert
            break
    }

    return color;
}

const BreakDownChart = React.memo(({ data }: { data: DailyRundownResult }) => {
    const { inventoryBoxFlag, uomCode, minBoxes1, minBoxes2, minBoxes3, maxBoxes, minDays1, minDays2, minDays3, maxDays, dateList, dateTimeList, stockDayOrBoxDispMap, impStockQtyDispMap } = data

    let alert1 = 0;
    let alert2 = 0;
    let alert3 = 0;
    let alertMax = 0;
    const alertByBoxes = inventoryBoxFlag != null && inventoryBoxFlag === InventoryBoxFlag.Y

    if (alertByBoxes) {
        alert1 = minBoxes1 !== undefined && minBoxes1 !== null ? minBoxes1 : 0;
        alert2 = minBoxes2 !== undefined && minBoxes2 !== null ? minBoxes2 : 0;
        alert3 = minBoxes3 !== undefined && minBoxes3 !== null ? minBoxes3 : 0;
        alertMax = maxBoxes !== undefined && maxBoxes !== null ? maxBoxes : 0;
    } else {
        alert1 = minDays1 !== undefined && minDays1 !== null ? minDays1 : 0;
        alert2 = minDays2 !== undefined && minDays2 !== null ? minDays2 : 0;
        alert3 = minDays3 !== undefined && minDays3 !== null ? minDays3 : 0;
        alertMax = maxDays !== undefined && maxDays !== null ? maxDays : 0;
    }
    //alertMax = 120;
    const intl = useIntl();
    const charData = useMemo(() => {
        if (stockDayOrBoxDispMap && dateTimeList && Records.size(stockDayOrBoxDispMap) > 0) {
            return dateTimeList.map((v, index) => {
                const stockDaysDisplay = stockDayOrBoxDispMap[v]
                const stockDaystr = stockDaysDisplay ? (stockDaysDisplay.startsWith(">") ? stockDaysDisplay.substr(1).trim() : (stockDaysDisplay.startsWith("N") || stockDaysDisplay.startsWith("-") ? '0' : stockDaysDisplay)) : '';
                const stockDays = Number(stockDaystr)
                const stockQty = impStockQtyDispMap ? impStockQtyDispMap[v] : ''
                const stockValue = stockDays > alertMax && alertMax > 0 ? alertMax * 1.1 : stockDays;
                const stockBoxesDays = alertByBoxes ? intl.formatMessage({ id: "field.StockBoxes" }) : intl.formatMessage({ id: "field.StockDays" })
                const stockName: string = intl.formatMessage({ id: "stockQty" }) + ": " + stockQty + " " + uomCode + " <br/>" + stockBoxesDays + ": " + stockDaysDisplay
                const colorStr = getBgColorOfChart(alert1, alert2, alert3, alertMax, stockDayOrBoxDispMap[v])
                const dateItem = dateList ? dateList[index] : v;
                return {
                    label: dateItem,
                    stockValue: stockValue,
                    displayValue: stockName,
                    stockColor: colorStr
                }


            })
        } else {
            return [];
        }
    }, [alert1, alert2, alert3, alertByBoxes, alertMax, dateList, dateTimeList, impStockQtyDispMap, intl, stockDayOrBoxDispMap, uomCode])

    const group = ArrayMultimaps.from(charData.map(item => [item.label, item]))

    const dataSource = {
        chart: {
            showvalues: 0,
            theme: "fusion",
            labelStep: charData?.length > 15 ? 5 : 1,
            rotateLabels: 1,
            slantLabels: 1,
            baseFontSize: 12,
            //plottooltext: "$label : $displayValue",
            plottooltext: "<div style='font-size:1rem; line-height:150%;padding: 8px'>$label <br/> $displayValue </div>",
            numVisiblePlot: 30,
            flatScrollBars: 0,
            scrollColor: "#aab3bc",
            scrollheight: 8,
            scrollPadding: 3
            // scrollShowButtons: 0,
        },
        trendlines: [{
            line: [{
                startvalue: alert1,
                endvalue: alert1,
                valueOnRight: "15",
                displayvalue: "alert1",
                color: "rgba(255,255,0,0.6)",
            },
            {
                startvalue: alert2,
                endvalue: alert2,
                valueOnRight: "15",
                displayvalue: "alert2",
                color: "rgba(255,192,0,0.72)",
            },
            {
                startvalue: alert3,
                endvalue: alert3,
                valueOnRight: "15",
                displayvalue: "alert3",
                color: "rgba(255,80,80,1)",
            },
            {
                startvalue: alertMax,
                endvalue: alertMax,
                valueOnRight: "15",
                displayvalue: "Max",
                color: "rgba(112,48,160,0.74)",
            }]
        }],

        categories: [{
            category: Arrays.from(group.keys()).map(key => ({ label: key }))
        }],
        dataset: [{
            data: Arrays.from(group.values()).map(k => {
                const stockValue = k.stockValue;
                const displayValue = k.displayValue;
                const stockColor = k.stockColor;
                return { value: stockValue, displayValue: displayValue, color: stockColor }
            })
        }]
    }

    return <ReactFusioncharts {...{
        type: 'scrollcolumn2d',
        width: '100%',
        height: 300,
        dataFormat: 'json',
        dataSource
    }} />
})

const LegendView = () => {

    const intl = useIntl();
    return <div style={{ width: '100%', display: 'flex', marginTop: 15, backgroundColor: 'white', alignItems: 'center', height: 42, borderRadius: 5, paddingBottom: 15 }}>
        <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: 20 }} >{intl.formatMessage({ id: 'legend' })}</Typography>
        <Legend alert='alert1' label={intl.formatMessage({ id: 'alert1' })} />
        <Legend alert='alert2' label={intl.formatMessage({ id: 'alert2' })} />
        <Legend alert='alert3' label={intl.formatMessage({ id: 'alert3' })} />
        <Legend alert='max' label={intl.formatMessage({ id: 'alertMax' })} />
        <Legend alert='noAlert' label={intl.formatMessage({ id: 'noAlert' })} />
    </div>
}

const Legend = ({ alert, label }: {
    alert: 'alert1' | 'alert2' | 'alert3' | 'max' | 'noAlert',
    label: string
}) => {
    return <>
        <div style={{ backgroundColor: BGColors[alert], marginLeft: 20, height: 10, width: 10 }}></div>
        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 10 }}>{label}</Typography>
    </>
}

const BGColors = {
    alert1: "#FFFF00",
    alert2: "#FFC000",
    alert3: "#FF5000",
    max: "#7030A0",
    noAlert: "#70AD47"
}

const FontColors = {
    alert1: "#000000",
    alert2: "#000000",
    alert3: "#FFFFFF",
    max: "#FFFFFF",
    noAlert: "#000000",
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    stockDayColor: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    },
    buttonChosen: {
        width: '130px',
        backgroundColor: '#00cbad',
        '&:hover': {
            backgroundColor: '#00cbad',
        },
    },
    buttonNotChosen: {
        width: '130px',
        backgroundColor: '#bdbdbd',
        '&:hover': {
            backgroundColor: '#bdbdbd',
        },
    },
    buttonGroup: {
        marginBottom: '6px',
    },
    popoverAuto: {
        pointerEvents: "auto",
    },
    popoverNone: {
        pointerEvents: "none",
    },
}))

const useHoverStyles = makeStyles({
    container: {
        background: '#F5F5F5',
        maxWidth: 660
      },
    table: {
        padding: '0px',
    },
    header: {
        background: '#DFE6EE',
        width: 20,
        height: 5,
        borderStyle: 'solid',
        borderColor: '#DCDCDC',
        borderWidth: '0.1px',
        fontWeight: 'bold',
        alignItems: 'left',
        fontSize: '10px',
        padding: '6px',
        margin: '0px',
        position: 'sticky',
        left: 0,
        zIndex: 0,
      },
    cell: {
        borderStyle: 'solid',
        borderWidth: '0.1px',
        width: 150,
        height: 5,
        background: '#F5F5F5',
        borderColor: '#DCDCDC',
        fontSize: '10px',
        padding: '6px',
        margin: '0px',
    }
  });

const ContainerTrackingTable = ({ trackings }: {trackings: DailyRundownContainerTrackingResult[]}) => {
    const classes = useHoverStyles();
    const intl = useIntl();
    const rows = [
        {field: 'containerNo', dataTypeName:'string', display: intl.formatMessage({ id: 'field.containerNo'})},
        {field: 'blNo', dataTypeName:'string', display: intl.formatMessage({ id: 'field.blNo'})},
        {field: 'carrierNo', dataTypeName:'string', display: intl.formatMessage({ id: 'field.carrierNo'})},
        {field: 'shippingQty', dataTypeName:'string', display: intl.formatMessage({ id: 'field.shippingQty'})},
        {field: 'etd', dataTypeName:'date', display: intl.formatMessage({ id: 'field.etdPol'})},
        {field: 'carrierEta', dataTypeName:'date', display: intl.formatMessage({ id: 'field.carrierEta'})},
        {field: 'predictionEta', dataTypeName:'date', display: intl.formatMessage({ id: 'field.predictionEta'})},
        {field: 'carrierCargoTrackInboundDate', dataTypeName:'date', display: intl.formatMessage({ id: 'field.carrierCargoTrackInboundDate'})},
        {field: 'predictionCargoTrackInboundDate', dataTypeName:'date', display: intl.formatMessage({ id: 'field.predictionCargoTrackInboundDate'})},
        {field: 'delayReason', dataTypeName:'string', display: intl.formatMessage({ id: 'field.delayReason'})},
        {field: 'cargoTrackingUpdatedDate', dataTypeName:'dateTime', display: intl.formatMessage({ id: 'field.cargoTrackingUpdatedDate'})},
    ]
    return (

      <TableContainer className={classes.container}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableBody>
            {rows.map((row, index) => (
                <TableRow key={`row-${index}`}>
                <TableCell component="th" scope="row" className={classes.header}>{row.display}</TableCell>
                {trackings.map((tracking, trackingIndex) => (
                    <TableCell key={`cell-${index}-${trackingIndex}`} component="th" scope="row" className={classes.cell}>
                        {(tracking as {[key: string]: string})[row.field] === null ? null :
                            row.dataTypeName === 'date' ? <FormattedDate value={(tracking as {[key: string]: string})[row.field]} dateStyle="medium"/>
                                : row.dataTypeName === 'dateTime' ? <FormattedDate value={(tracking as {[key: string]: string})[row.field]} dateStyle="medium" timeStyle="short"/>
                                : (tracking as {[key: string]: string})[row.field]}
                    </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
            <TableCell component="th" scope="row" className={classes.header}>{intl.formatMessage({ id: 'disclaimerLabel'})}</TableCell>
            <TableCell component="th" scope="row" className={classes.cell}>{intl.formatMessage({ id: 'disclaimerMessage'})}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
