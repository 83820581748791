
import { Project } from "@rithe/utils"

export type OutPackageInfo =  OutPackageInfoRequired & Partial<OutPackageInfoOptional>
type OutPackageInfoJson = OutPackageInfoJsonRequired & Partial<OutPackageInfoJsonOptional>
export const outPackageInfoResultFromJson: Project<OutPackageInfoJson, OutPackageInfo> = json => ({
    ...json,
})
export const outPackageInfoResultToJson: Project<OutPackageInfo, OutPackageInfoJson> = obj => ({
    ...obj,
})

interface OutPackageInfoRequired extends OutPackageInfoJsonRequired { }

interface OutPackageInfoJsonRequired {
    outPackageId: number,
    partsNo:string,
    uomCode:string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName: string,
    sellerBackNo: string,
    colorCode: string,
    srbq: number,
    qty: number
}

interface OutPackageInfoOptional extends OutPackageInfoJsonOptional {
}

interface OutPackageInfoJsonOptional {
}