import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type BuyerInvoiceView = Required & Partial<Optional>

type BuyerInvoiceViewJson = JsonRequired & Partial<JsonOptional>

export const buyerInvoiceViewToJson: Project<BuyerInvoiceView, BuyerInvoiceViewJson> = obj => ({
    ...obj,
    approvedDateStart: dateToJson(obj.approvedDateStart),
    approvedDateEnd: dateToJson(obj.approvedDateEnd),
    issuedDateStart: dateToJson(obj.issuedDateStart),
    issuedDateEnd: dateToJson(obj.issuedDateEnd),
    outboundDateStart: dateToJson(obj.outboundDateStart),
    outboundDateEnd: dateToJson(obj.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const buyerInvoiceViewFromJson: Project<BuyerInvoiceViewJson, BuyerInvoiceView> = json => ({
    ...json,
    approvedDateStart: dateFromJson(json.approvedDateStart),
    approvedDateEnd: dateFromJson(json.approvedDateEnd),
    issuedDateStart: dateFromJson(json.issuedDateStart),
    issuedDateEnd: dateFromJson(json.issuedDateEnd),
    outboundDateStart: dateFromJson(json.outboundDateStart),
    outboundDateEnd: dateFromJson(json.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'approvedDateStart' | 'approvedDateEnd' | 'issuedDateStart' | 'issuedDateEnd' | 'outboundDateStart' | 'outboundDateEnd'> {
    page: PageViewEntity
    approvedDateStart: Date,
    approvedDateEnd: Date,
    issuedDateStart: Date,
    issuedDateEnd: Date,
    outboundDateStart: Date,
    outboundDateEnd: Date
}

interface JsonRequired {
}

interface JsonOptional {
    inboundNo: string,
    invoiceNo: string,
    externalInvoiceNo: string,
    outboundNo: string,
    contractNo: string,
    approvedBy: string,
    buyers: string[],
    sellers: string[],
    approvedDateStart: string,
    approvedDateEnd: string,
    issuedDateStart: string,
    issuedDateEnd: string,
    outboundDateStart: string,
    outboundDateEnd: string,
    shippingModeList: number[],
    loadingPortList: string[],
    dischargePortList: string[],
    statusList: number[],
    resourceCode: string,
    page: PageViewEntityJson
}

