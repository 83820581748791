import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { EntriesItem, StringItem } from '@rithe/form'
import { Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from '../../../components/View/View'
import { useDownloadUserActive } from '../../../services/privilege/api/UserActiveApi'
import { UserStatus } from '../../../services/privilege/enums/UserStatus'
import { UserActiveListFactor } from '../../../services/privilege/models/UserActiveFactor'
import { UserActiveListResult } from '../../../services/privilege/models/UserActiveResult'
export interface CAS040PcUiProps {
  filters: UserActiveListFactor,
  setFilters: React.Dispatch<React.SetStateAction<UserActiveListFactor>>,
  search: (filters: UserActiveListFactor) => void,
  data: UserActiveListResult[]
}

export function CAS040PcUi(props: CAS040PcUiProps) {
  return <View flex>
    <SectionCard>
      <SectionCardContent>
        <DataTable {...props} />
      </SectionCardContent>
    </SectionCard>
  </View>

}

const DataTable = memo(({ filters, data, setFilters, search }: CAS040PcUiProps) => {
  const intl = useIntl()
  const [selections, setSelections] = useState<string[]>([])
  const getLoginId = useCallback((row: Row) => row.account.loginId, [])
  const getStatus = useCallback((row: Row) => row.account.active && !row.account.locked ? intl.formatMessage({ id: 'active' }) : intl.formatMessage({ id: 'inactive' }), [intl])
  const getChatStatus = useCallback((row: Row) => row.activateChat ? intl.formatMessage({ id: 'active' }) : intl.formatMessage({ id: 'inactive' }), [intl])
  const actionProps1 = useMemo(() => ({ selections, filters }), [selections, filters])
  const columns = useMemo(() => [
    { field: 'loginId', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.loginId' }), width: 200, getCellValue: getLoginId },
    { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.username' }), width: 200 },
    { field: 'email', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyMail' }), width: 200 },
    { field: 'contactCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyContact' }), width: 200 },
    { field: 'defaultCbds', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.defaultCompany' }), width: 200 },
    { field: 'activeChat', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.onlineChatStatus' }), width: 200, getCellValue: getChatStatus },
    { field: 'cbdsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.cbdsCode' }), width: 350 },
    { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 100, getCellValue: getStatus },
  ], [getChatStatus, getLoginId, getStatus, intl])

  const getRowId = useCallback((row: any) => row.id, [])
  const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, search, setFilters])
  return <DataGrid>
    <ToolbarLayout />
    <TableLayout Container={FlexScrollbar}>
      <TableHeaderLayout sticky />
      <TableBodyLayout />
    </TableLayout>
    <PaginationLayout Pagination={Pagination} />
    <DataTypePreset />
    <Data rows={data} columns={columns} getRowId={getRowId} />
    <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
    <ColumnFreeze />
    <ColumnVisibility
      defaultHiddenFields={['failReason']}
      ToolbarButton={ColumnVisibilityToolbarButton} />
    <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
    <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
    <Searching ignoreCase Input={SearchInput} />
    <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
    <Sorting />
    <Filtering />
    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    <Action width={150} />
  </DataGrid>
})

const FilterItem = memo((props: {
  filters: UserActiveListFactor,
  setFilters: React.Dispatch<React.SetStateAction<UserActiveListFactor>>,
  search: (filters: UserActiveListFactor) => void
}) => {
  const { filters, setFilters, search } = props

  const clear = useCallback((filters: UserActiveListFactor) => {
    return {page: filters.page}
  }, [])

  const filterCounter = useCallback((filters: UserActiveListFactor) => {
    return [
      filters.loginIdContains,
      filters.nameContains,
      filters.emailContains,
      filters.contactCodeContains,
      filters.onlineChatStatus,
    ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
  }, [])
  const intl = useIntl()
  const statusEntries = useMemo<[number, string][]>(() => [
    [UserStatus.Active, intl.formatMessage({ id: 'active' })],
    [UserStatus.Inactive, intl.formatMessage({ id: 'inactive' })],
  ], [intl])

  return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter} >
    <StringItem field="loginIdContains" label={intl.formatMessage({ id: 'field.loginId' })} />
    <StringItem field="nameContains" label={intl.formatMessage({ id: 'field.name' })} />
    <StringItem field="emailContains" label={intl.formatMessage({ id: 'field.companyMail' })} labelWidth={180}/>
    <StringItem field="contactCodeContains" label={intl.formatMessage({ id: 'field.companyContact' })} />
    <EntriesItem field="onlineChatStatus" label={intl.formatMessage({ id: 'field.onlineChatStatus' })} entries={statusEntries} />
  </FilterToolbarItem>
})

const DownloadAction = memo(({ selections, filters }: { selections: string[], filters: UserActiveListFactor }) => {
  const dowloadApi = useDownloadUserActive()
  const download = useCallback(() => {
    dowloadApi({ ids: selections, filter: filters })
  }, [dowloadApi, selections, filters])
  return <>
    <DownloadGroupedToolbarAction >
      {onClose => <>
        <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
      </>}
    </DownloadGroupedToolbarAction>
  </>
}
)
