import { useCallback } from "react"
import { useUpload } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { BUChangeOrderDetailResult, buChangeOrderDetailResultFromJson } from "../models/BUChangeOrderDetailResult"
import { ChangeOrderDetailResult, changeOrderDetailResultFromJson } from "../models/ChangeOrderDetailResult"
import { PlaceChangeWCDetailResult, placeChangeWCDetailResultDeserializer } from "../models/PlaceChangeWCDetailResult"
import { PlaceOrderDetailFactor, placeOrderDetailFactorSerializer } from "../models/PlaceOrderDetailFactor"
import { PlaceRegularOrderResult, placeRegularOrderResultDeserializer } from "../models/PlaceOrderDetailResult"
import { PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResultDeserializer } from "../models/PlaceOrderForCustomerDetailResult"
import { ReSpotOrderResult, reSpotOrderResultDeserializer } from "../models/ReSpotOrderDetailResult"
import { SChangeOrderDetailResult, sChangeOrderDetailResultFromJson } from "../models/SChangeOrderDetailResult"
import { SpotOrderResult, spotOrderResultDeserializer } from "../models/SpotOrderDetailResult"

export const useUploadRegularOrderFormForCustomer = () => {
    const url = '/lcbm-order-api/order/upload/regularOrderFormForCustomer'
    const payloadProject = useCallback((obj: { file: File } & PlaceOrderDetailFactor) => ({ file: obj.file, ...placeOrderDetailFactorSerializer(obj) }), [])
    const resultProject = useCallback(placeRegularOrderResultDeserializer, [])
    return useUpload<{ file: File } & PlaceOrderDetailFactor, PlaceRegularOrderResult>(url, payloadProject, resultProject)
}

export const useUploadSpotOrderFormForCustomer = () => {
    const url = '/lcbm-order-api/order/upload/spotOrderFormForCustomer'
    const payloadProject = useCallback((obj: { file: File } & PlaceOrderDetailFactor) => ({ file: obj.file, ...placeOrderDetailFactorSerializer(obj) }), [])
    const resultProject = useCallback(placeRegularOrderResultDeserializer, [])
    return useUpload<{ file: File } & PlaceOrderDetailFactor, PlaceRegularOrderResult>(url, payloadProject, resultProject)
}

export const useUploadChangeOrderByCustomer = () => {
    const url = '/lcbm-order-api/order/upload/uploadChangeOrderByCustomer'
    const payloadProject = useCallback((obj: { file: File, coId: number, roId: number }) => obj, [])
    const resultProject = useCallback(changeOrderDetailResultFromJson, [])
    return useUpload<{ file: File, coId: number, roId: number }, ChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useUploadChangeFcByCustomer = () => {
    const url = '/lcbm-order-api/order/upload/uploadChangeFcByCustomer'
    const payloadProject = useCallback((obj: { file: File, coId: number, roId: number }) => obj, [])
    const resultProject = useCallback(changeOrderDetailResultFromJson, [])
    return useUpload<{ file: File, coId: number, roId: number }, ChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useUploadOrderPrice = () => {
    const url = '/lcbm-order-api/order/upload/uploadOrderPrice'
    const payloadProject = useCallback((obj: { file: File, salesOrderNo: string }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, salesOrderNo: string }, undefined>(url, payloadProject, resultProject)
}

export const useUploadDrForSupplier = () => {
    const url = '/lcbm-order-api/order/upload/uploadDrForSupplier'
    const payloadProject = useCallback((obj: { file: File, salesOrderNo: string }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, salesOrderNo: string }, undefined>(url, payloadProject, resultProject)
}

export const useUploadSpotOrderWithContractFormForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/upload/uploadSpotOrderWithContract'
    const payloadProject = useCallback((obj: { file: File, spotId: number | undefined }) => obj, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return useUpload<{ file: File, spotId: number | undefined }, SpotOrderResult>(url, payloadProject, resultProject)
}

export const useUploadSpotOrderWithContractStep1FormForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/upload/uploadSpotOrderWithContractStep1'
    const payloadProject = useCallback((obj: { file: File, spotId: number | undefined }) => obj, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return useUpload<{ file: File, spotId: number | undefined }, SpotOrderResult>(url, payloadProject, resultProject)
}

export const useUploadReceivedSpotOrderWithContract = () => {
    const url = '/lcbm-spot-order-api/order/upload/uploadReceivedSpotOrderWithContract'
    const payloadProject = useCallback((obj: { file: File, soSpotId: number | undefined }) => obj, [])
    const resultProject = useCallback(reSpotOrderResultDeserializer, [])
    return useUpload<{ file: File, soSpotId: number | undefined }, ReSpotOrderResult>(url, payloadProject, resultProject)
}

export const useUploadPlaceChangeWithContract = () => {
    const url = '/lcbm-spot-order-api/order/upload/uploadPlaceChangeWithContract'
    const payloadProject = useCallback((obj: { file: File, coSpotId: number | undefined, roSpotId: number | undefined | null }) => obj, [])
    const resultProject = useCallback(placeChangeWCDetailResultDeserializer, [])
    return useUpload<{ file: File, coSpotId: number | undefined, roSpotId: number | undefined | null }, PlaceChangeWCDetailResult>(url, payloadProject, resultProject)
}

export const useUploadPlaceChangeWithContractForCustomer = () => {
    const url = '/lcbm-spot-order-api/order/upload/uploadPlaceChangeWithContractForCus'
    const payloadProject = useCallback((obj: { file: File, poSpotId: number | undefined, roSpotId: number | undefined | null }) => obj, [])
    const resultProject = useCallback(PlaceOrderForCustomerDetailResultDeserializer, [])
    return useUpload<{ file: File, poSpotId: number | undefined, roSpotId: number | undefined | null }, PlaceOrderForCustomerDetailResult>(url, payloadProject, resultProject)
}

// Supplier Upload
export const useUploadSuppOrderChange = () => {
    const url = '/lcbm-order-api/order/upload/uploadSuppOrderChange'
    const payloadProject = useCallback((obj: { file: File, soId: number, suppRoId: number }) => obj, [])
    const resultProject = useCallback(sChangeOrderDetailResultFromJson, [])
    return useUpload<{ file: File, soId: number, suppRoId: number }, SChangeOrderDetailResult>(url, payloadProject, resultProject)
}

// Supplier Upload
export const useUploadPNAPoBalanceFile = () => {
    const url = '/lcbm-order-api/order/upload/uploadPNAPoBalanceFile'
    const payloadProject = useCallback(obj => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadChangeOrderByBU = () => {
    const url = '/lcbm-order-api/order/upload/uploadChangeOrderByBuyerBu'
    const payloadProject = useCallback((obj: { file: File, poId: number, roId: number }) => obj, [])
    const resultProject = useCallback(buChangeOrderDetailResultFromJson, [])
    return useUpload<{ file: File, poId: number, roId: number }, BUChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useUploadChangeFcByBU = () => {
    const url = '/lcbm-order-api/order/upload/uploadChangeFcByBuyerBu'
    const payloadProject = useCallback((obj: { file: File, poId: number, roId: number }) => obj, [])
    const resultProject = useCallback(buChangeOrderDetailResultFromJson, [])
    return useUpload<{ file: File, poId: number, roId: number }, BUChangeOrderDetailResult>(url, payloadProject, resultProject)
}