import { useCallback, useState } from "react"
import { useGetSelfUserAccessHistoryByPage } from "../../../services/privilege/api/UserAccessHistoryApi"
import { AccessHistoryType } from "../../../services/privilege/enums/AccessHistoryType"
import { UserAccessHistoryListFactor, UserAccessHistoryListFactorToJson } from "../../../services/privilege/models/UserAccessHistoryFactor"
import { UserAccessHistoryListResult } from "../../../services/privilege/models/UserAccessHistoryListResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MSESF030PcUi } from "./MSESF030PcUi"

const searchCachekey = sessionKeys.Filters_MSESF030
const defaultFilters = { page: { currentPage: 0, pageSize: 100000 }, type: [AccessHistoryType.LOGIN] }
export const MSESF030 = () => {
  const [data, setData] = useState<UserAccessHistoryListResult[]>([])
  const [filters, setFilters] = useState<UserAccessHistoryListFactor>(defaultFilters)

  const getData = useGetSelfUserAccessHistoryByPage()
  const search = useCallback((filters: UserAccessHistoryListFactor) => {
    getData(filters, { silent: true, serialized: true }).then(result => {
      setData(result?.data || [])
      sessionStorage.setItem(searchCachekey, JSON.stringify(UserAccessHistoryListFactorToJson(filters)))
    })
  }, [getData, setData])

  useSearchOnLoad(search, filters)
  
  return <MSESF030PcUi
    filters={filters}
    setFilters={setFilters}
    search={search}
    data={data}
  />
}
