import { IconButton, Tooltip } from "@material-ui/core"
import { LocationOn } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { ForecastRedirectRowAction } from "../../../components/DataGrid/rowActions/ForecastRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { PrintGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { usePrintDownloadPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useCheckShippingDetailConfirm } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { useDownloadCOByFilters, useDownloadCOByOrderIds, useDownloadCustomerOrderByExcel } from "../../../services/order/apis/OrderDownloadApi"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { OrderType } from "../../../services/order/enums/OrderType"
import { CustomerOrderListFactor } from "../../../services/order/models/CustomerOrderListFactor"
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"

interface OCS010PcUiProps {
    filters: CustomerOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerOrderListFactor>>,
    search: (filters: CustomerOrderListFactor) => void,
    data: OrderListResult[],
    totalCount: number,
    customerList: TnmCustomer[]
}

export const OCS010PcUi = (props: OCS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, search, customerList }: OCS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const { getCodeName } = useGetCodeName()
    const getStatusName = useCallback((row: Row) => {
        const status = row.status as number
        const orgStatus = row.orgStatus as number
        return (orgStatus === CoStatus.FORCE_COMPLETED && status === CoStatus.PROCESSING) ? getCodeName(CodeCategory.CoStatus, status) + "(" + getCodeName(CodeCategory.CoStatus, orgStatus) + ")" : getCodeName(CodeCategory.CoStatus, status)
    }, [getCodeName])

    const columns = useMemo(() => [
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'sellerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerOrderNo' }), width: 300 },
        { field: 'orderRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderRefNo' }), width: 300 },
        { field: 'contractOrGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractOrGroupNo' }), width: 300 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 150 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 180 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 240, getCellValue: getStatusName },
        { field: 'delayStatus', dataTypeName: CodeCategory.DelayStatus, title: intl.formatMessage({ id: 'delayStatus' }), width: 180 },
    ], [getStatusName, intl])
    const getRowId = useCallback((row: any) => row.orderId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])
    const actionProps1 = useMemo(() => ({ selections, filters }), [selections, filters])
    const displayForecastDetail = useCallback((tableRow: TableRow) => tableRow.row?.orderType === OrderType.REGULAR && tableRow.row?.forecastNum !== undefined && tableRow.row?.forecastNum > 0, [])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status !== CoStatus.RECEIVED && tableRow.row?.status !== CoStatus.CANCELLED, [])
    const displayData = data.map(d => ({ ...d, contractOrGroupNo: d.contractNo ? d.contractNo : d.groupNo }))

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DelayStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.MilkrunFlag} />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        {/* <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} /> */}
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <RowActionProvider name="detail" Action={DetailRowAction} actionProps={actionProps1} />
        <RowActionProvider name="forecast" Action={ForecastRowAction} actionProps={actionProps1} display={displayForecastDetail} />
        <RowActionProvider name="viewCargoTracking" Action={ViewCargoTrackingRowAction} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['orderFrequency', 'shippingMode', 'contractRouteCode']}
            columnSettings={{
                orderNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={150} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CustomerOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerOrderListFactor>>,
    search: (filters: CustomerOrderListFactor) => void,
    customerList: TnmCustomer[],
}) => {
    const { filters, customerList, search, setFilters } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])


    const orderDateGetValue = useCallback((filters: CustomerOrderListFactor) => {
        return [filters.orderDateStart ?? null, filters.orderDateEnd ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: CustomerOrderListFactor, value: any) => {
        return { ...filters ?? {}, orderDateStart: value[0], orderDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CustomerOrderListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustomerOrderListFactor) => {
        return [
            filters.orderNo,
            filters.sellerOrderNo,
            filters.orderRefNo,
            filters.contractNo,
            filters.contractRouteCode,
            filters.customerIdList,
            filters.orderTypeList,
            filters.orderFrequencyList,
            filters.shippingModeList,
            filters.statusList,
            filters.delayStatusList,
            filters.orderDateStart || filters.orderDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <StringItem field="sellerOrderNo" label={intl.formatMessage({ id: 'field.sellerOrderNo' })} />
        <StringItem field="orderRefNo" label={intl.formatMessage({ id: 'orderRefNo' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractOrGroupNo' })} labelWidth={150} />
        <StringItem field="contractRouteCode" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.CoStatus} />
        <CodesItem field="delayStatusList" label={intl.formatMessage({ id: 'delayStatus' })} code={CodeCategory.DelayStatus} />
        <DateRangeItem field="orderDate" label={intl.formatMessage({ id: 'orderDate' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>

}

const PrintAction = ({ selections }: { selections: number[] }) => {
    const dispatch = useDispatch()
    const printApi = usePrintDownloadPDF()
    const print = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        printApi({ reportId: 'OCR010', labelId: 'Customers Order', param: JSON.stringify(selections) })
    }, [dispatch, printApi, selections])
    return <PrintGroupedToolbarAction access="ORDER.OCS010.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: CustomerOrderListFactor }) => {
    const dispatch = useDispatch()
    const dowloadApi = useDownloadCustomerOrderByExcel()
    const downloadCOByOrderIds = useDownloadCOByOrderIds()
    const downloadCOByFilters = useDownloadCOByFilters()

    const dowload = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadByExcel' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ coIds: selections })
    }, [dispatch, dowloadApi, selections])

    const download2 = useCallback(() => {
        if (selections.length <= 0) {
            downloadCOByFilters(filters)
        } else {
            downloadCOByOrderIds({ coIds: selections })
        }
    }, [downloadCOByOrderIds, downloadCOByFilters, filters, selections])

    return <DownloadGroupedToolbarAction access="ORDER.OCS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadByExcel" />} callback={dowload} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download2} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/co/detail-${tableRow.rowId}`, [])
    return <DetailRedirectRowAction tableRow={tableRow} access="ORDER.OCS010.VIEWDETAIL" path={path} />
}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/co/fc-${tableRow.rowId}`, [])
    return <ForecastRedirectRowAction tableRow={tableRow} access="ORDER.OCS010.VIEWFORECAST" path={path} />
}

const ViewCargoTrackingRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const checkConfirm = useCheckShippingDetailConfirm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const click = useCallback(() => {
        checkConfirm({ orderNo: tableRow.row?.orderNo }, { silent: true }).then(result => {
            if (result === false) {
                dispatch(applicationActions.pushError({ title: { code: 'viewCargoTracking' }, messages: { code: 'w1039' } }))
            } else {
                navigate(`/co/cargotrackingDetail-${tableRow.row?.orderNo}`)
            }
        })
    }, [checkConfirm, dispatch, navigate, tableRow.row?.orderNo])

    return <Tooltip title={<FormattedMessage id="viewCargoTracking" />}>
        <IconButton onClick={click}>
            <LocationOn />
        </IconButton>
    </Tooltip>
}
