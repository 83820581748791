import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { DelayStatus } from "../enums/DelayStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { SoStatus } from "../enums/SoStatus"

export type SalesOrderDownloadListFactor = Omit<SalesOrderListFactor, 'page'>
export type SalesOrderDownloadListFactorJson = Omit<SalesOrderListFactorJson, 'page'>
export const salesOrderDownloadListFactorToJson: Project<SalesOrderDownloadListFactor, SalesOrderDownloadListFactorJson> = obj => ({
    ...obj,
    orderDateStart: dateToJson(obj.orderDateStart),
    orderDateEnd: dateToJson(obj.orderDateEnd),
})

export type SalesOrderListFactor = Partial<Optional>
export type SalesOrderListFactorJson = Partial<JsonOptional>
export const salesOrderListFactorToJson: Project<SalesOrderListFactor, SalesOrderListFactorJson> = obj => ({
    ...obj,
    orderDateStart: dateToJson(obj.orderDateStart),
    orderDateEnd: dateToJson(obj.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const salesOrderListFactorFromJson: Project<SalesOrderListFactorJson, SalesOrderListFactor> = json => ({
    ...json,
    orderDateStart: dateFromJson(json.orderDateStart),
    orderDateEnd: dateFromJson(json.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'orderDateStart' | 'orderDateEnd' | 'page'> {
    orderTypeList: OrderType[],
    orderFrequencyList: OrderFrequency[],
    shippingModeList: ShippingMode[],
    orderDateStart: Date,
    orderDateEnd: Date,
    statusList: SoStatus[],
    delayStatusList: DelayStatus[],
    page: PageViewEntity
}


interface JsonOptional {
    orderNo: string,
    buyerOrderNo: string,
    orderRefNo: string,
    contractNo: string,
    contractRouteCode: string,
    buyer: string,
    orderTypeList: number[],
    orderFrequencyList: number[],
    shippingModeList: number[],
    orderDateStart: string,
    orderDateEnd: string,
    statusList: number[],
    delayStatusList: number[],
    creditCheckFlagList: number[],
    page: PageViewEntityJson
}
