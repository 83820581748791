import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core"
import { DoneAll, LocationOn } from "@material-ui/icons"
import RateReviewIcon from '@material-ui/icons/RateReview'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { ForecastRedirectRowAction } from "../../../components/DataGrid/rowActions/ForecastRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { PrintGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { usePrintDownloadPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useCheckShippingDetailConfirm } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { useFroceCredit, useRefreshCredit } from "../../../services/master/apis/creditRuleApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadSOByFilters, useDownloadSOByOrderIds, useDownloadSalesOrderByExcel } from "../../../services/order/apis/OrderDownloadApi"
import { useForceComplete } from "../../../services/order/apis/SalesOrderApi"
import { CreditCheckFlag } from "../../../services/order/enums/CreditCheckFlag"
import { OrderType } from "../../../services/order/enums/OrderType"
import { SoStatus } from "../../../services/order/enums/SoStatus"
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import { SalesOrderListFactor } from "../../../services/order/models/SalesOrderListFactor"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"
import { CreditLimitView } from "../CreditLimit/CreditLimit"

export interface OSS010PcUiProps {
    filters: SalesOrderListFactor,
    setFilters: React.Dispatch<SetStateAction<SalesOrderListFactor>>,
    search: (filters: SalesOrderListFactor) => void,
    data: OrderListResult[],
    totalCount: number
}

export const OSS010PcUi = (props: OSS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, search }: OSS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const { getCodeName } = useGetCodeName()

    const getStatusName = useCallback((row: Row) => {
        const status = row.status as number
        const orgStatus = row.orgStatus as number
        const checkStatus = row.creditCheckFlag as number
        if (checkStatus === CreditCheckFlag.CHECK_NG) {
            return intl.formatMessage({ id: 'checkNg' })
        }
        return (orgStatus === SoStatus.FORCE_COMPLETED && status === SoStatus.PROCESSING) ? getCodeName(CodeCategory.SoStatus, status) + "(" + getCodeName(CodeCategory.SoStatus, orgStatus) + ")" : getCodeName(CodeCategory.SoStatus, status)
    }, [getCodeName, intl])

    const columns = useMemo(() => [
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'buyerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerOrderNo' }), width: 300 },
        { field: 'orderRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderReference' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'contractOrGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractOrGroupNo' }), width: 300 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 150 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 240, getCellValue: getStatusName },
        { field: 'delayStatus', dataTypeName: CodeCategory.DelayStatus, title: intl.formatMessage({ id: 'delayStatus' }), width: 180 },
        { field: 'reviseVersion', dataTypeName: 'number', title: intl.formatMessage({ id: 'reviseVersion' }), width: 180 },
    ], [getStatusName, intl])
    const getRowId = useCallback((row: any) => row.orderId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ selections, filters }), [selections, filters])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const displayForForecastDetail = useCallback((tableRow: TableRow) => tableRow.row?.orderType === OrderType.REGULAR && tableRow.row?.forecastNum !== undefined && tableRow.row?.forecastNum > 0, [])
    const displayForForecomplete = useCallback((tableRow: TableRow) => (tableRow.row?.status === SoStatus.CONFIRMED || tableRow.row?.status === SoStatus.PROCESSING) && tableRow.row?.orgStatus !== SoStatus.FORCE_COMPLETED && tableRow.row?.contractNo !== null, [])
    const displayForCredit = useCallback((tableRow: TableRow) => tableRow.row?.creditCheckFlag && tableRow.row?.creditCheckFlag === 1 && tableRow.row?.contractNo !== null, [])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status !== SoStatus.RECEIVED && tableRow.row?.status !== SoStatus.CANCELLED && tableRow.row?.contractNo !== null, [])
    const displayData = data.map(d => ({ ...d, contractOrGroupNo: d.contractNo ? d.contractNo : d.groupNo }))

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DelayStatus} />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        {/* <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} /> */}
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <RowActionProvider name="forecast" Action={ForecastRowAction} display={displayForForecastDetail} />
        <RowActionProvider name="forceComplete" Action={ForceCompleteRowAction} actionProps={actionProps2} display={displayForForecomplete} />
        <RowActionProvider name="viewCheck" Action={CreditCheckAction} actionProps={actionProps2} display={displayForCredit} />
        <RowActionProvider name="viewCargoTracking" Action={ViewCargoTrackingRowAction} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['reviseVersion']}
            columnSettings={{
                orderNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={170} />
    </DataGrid>
}

const FilterItem = memo((props: {
    filters: SalesOrderListFactor,
    setFilters: React.Dispatch<SetStateAction<SalesOrderListFactor>>,
    search: (filters: SalesOrderListFactor) => void
}) => {
    const { filters, setFilters, search } = props

    const orderDateGetValue = useCallback((filters: SalesOrderListFactor) => {
        return [filters.orderDateStart ?? null, filters.orderDateEnd ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: SalesOrderListFactor, value: any) => {
        return { ...filters ?? {}, orderDateStart: value[0], orderDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: SalesOrderListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: SalesOrderListFactor) => {
        return [
            filters.orderNo,
            filters.buyerOrderNo,
            filters.orderRefNo,
            filters.contractNo,
            filters.contractRouteCode,
            filters.buyer,
            filters.orderTypeList,
            filters.shippingModeList,
            filters.statusList,
            filters.delayStatusList,
            filters.orderDateStart || filters.orderDateEnd,
            filters.creditCheckFlagList,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <StringItem field="buyerOrderNo" label={intl.formatMessage({ id: 'field.buyerOrderNo' })} />
        <StringItem field="orderRefNo" label={intl.formatMessage({ id: 'field.orderReference' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractOrGroupNo' })} labelWidth={150} />
        <StringItem field="contractRouteCode" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <StringItem field="buyer" label={intl.formatMessage({ id: 'buyer' })} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.PoStatus} />
        <CodesItem field="delayStatusList" label={intl.formatMessage({ id: 'delayStatus' })} code={CodeCategory.DelayStatus} />
        <CodesItem field="creditCheckFlagList" label={intl.formatMessage({ id: 'field.creditCheckFlag' })} code={CodeCategory.CreditCheckFlag} />
        <DateRangeItem field="orderDate" label={intl.formatMessage({ id: 'orderDate' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>
})

const PrintAction = ({ selections }: { selections: number[] }) => {
    const printApi = usePrintDownloadPDF()
    const print = useCallback(() => printApi({ reportId: 'OSR010', labelId: 'Sales Order', param: JSON.stringify(selections) }), [printApi, selections])
    return <PrintGroupedToolbarAction access="ORDER.OSS010.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: SalesOrderListFactor }) => {
    const dispatch = useDispatch()
    const dowloadApi = useDownloadSalesOrderByExcel()
    const downloadSOByOrderIds = useDownloadSOByOrderIds()
    const downloadSOByFilters = useDownloadSOByFilters()

    const dowload = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadByExcel' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ soIds: selections })
    }, [dispatch, dowloadApi, selections])

    const download2 = useCallback(() => {
        if (selections.length <= 0) {
            downloadSOByFilters(filters)
        } else {
            downloadSOByOrderIds({ soIds: selections })
        }
    }, [downloadSOByFilters, downloadSOByOrderIds, filters, selections])

    return <DownloadGroupedToolbarAction access="ORDER.OSS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadByExcel" />} callback={dowload} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download2} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/so/detail-${tableRow.rowId}`, [])
    return <DetailRedirectRowAction tableRow={tableRow} access="ORDER.OSS010.VIEWDETAIL" path={path} />
}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/so/fc-${tableRow.rowId}`, [])
    return <ForecastRedirectRowAction tableRow={tableRow} access="ORDER.OSS010.VIEWFORECAST" path={path} />
}

const ForceCompleteRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: SalesOrderListFactor, search: (filters: SalesOrderListFactor) => void }) => {
    const forceCompleteApi = useForceComplete()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        const functionId = functionStore.register(() => {
            forceCompleteApi({ orderIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, forceCompleteApi, functionStore, search, title])
    return <CallbackRowAction tableRow={tableRow} access="ORDER.OSS010.FORCECOMPLETED" title={<FormattedMessage id="forceComplete" />} icon={<DoneAll />} callback={callback} disabled={disabled} />
}


const CreditCheckAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: SalesOrderListFactor, search: (filters: SalesOrderListFactor) => void }) => {
    const froceCreditFun = useFroceCredit()
    const refreshCreditFun = useRefreshCredit()
    const soId = tableRow.row?.orderId
    const creditCheckFlag = tableRow.row?.creditCheckFlag
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const onClose = useCallback(() => setOpen(false), [])
    const force = useCallback(() => {
        if (soId && creditCheckFlag) {
            froceCreditFun({ soId: soId, creditCheckFlag: creditCheckFlag }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    // refrech
                    search(filters)
                }

            })
        }

    }, [creditCheckFlag, filters, froceCreditFun, search, soId])
    const refresh = useCallback(() => {
        if (soId && creditCheckFlag) {
            refreshCreditFun({ soId: soId, creditCheckFlag: creditCheckFlag }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    // refrech
                    search(filters)
                }

            })
        }
    }, [creditCheckFlag, filters, refreshCreditFun, search, soId])

    return <>
        <Tooltip title={<FormattedMessage id="checkDetail" />}><IconButton onClick={onOpen}><RateReviewIcon /></IconButton></Tooltip>
        <DarkDialog open={open} style={{ height: '700px', overflow: 'hidden', color: '#fff', }} maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="checkItemDetail" />
            </DialogTitle>
            <DialogContent>
                <CreditLimitView soId={soId} creditCheckFlag={creditCheckFlag} />
            </DialogContent>
            <DialogActions>
                <Button onClick={force} variant="contained" style={{ backgroundColor: '#0074a5' }}><FormattedMessage id="force" /></Button>
                <Button onClick={refresh} variant="contained" style={{ backgroundColor: '#0074a5' }}><FormattedMessage id="refresh" /></Button>
                <Button onClick={onClose} variant="contained" ><FormattedMessage id="close" /></Button>
            </DialogActions>
        </DarkDialog>
    </>
}

const ViewCargoTrackingRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const checkConfirm = useCheckShippingDetailConfirm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const click = useCallback(() => {
        checkConfirm({ orderNo: tableRow.row?.orderNo }, { silent: true }).then(result => {
            if (result === false) {
                dispatch(applicationActions.pushError({ title: { code: 'viewCargoTracking' }, messages: { code: 'w1039' } }))
            } else {
                navigate(`/so/cargotrackingDetail-${tableRow.row?.orderNo}`)
            }
        })
    }, [checkConfirm, dispatch, navigate, tableRow.row?.orderNo])

    return <Tooltip title={<FormattedMessage id="viewCargoTracking" />}>
        <IconButton onClick={click}>
            <LocationOn />
        </IconButton>
    </Tooltip>
}
