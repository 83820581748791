import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import {Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout} from "@rithe/data-grid";
import {DataGridRowActionProps} from "@rithe/data-grid/dist/components/basic/DataGridRowAction";
import {KeyFormatterProps} from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter";
import {Arrays, Objects, Records} from "@rithe/utils";
import {memo, useCallback, useMemo, useState} from "react";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {useFunctionStore} from "../../../Root";
import {SectionCard} from "../../../components/Card/SectionCard";
import {SectionCardContent} from "../../../components/Card/SectionCardContent";
import {SectionCardHeader} from "../../../components/Card/SectionCardHeader";
import {ColumnVisibilityToolbarButton} from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton";
import {FlexScrollbar} from "../../../components/DataGrid/components/FlexScrollbar";
import {PageInfo} from "../../../components/DataGrid/components/PageInfo";
import {PageSelect} from "../../../components/DataGrid/components/PageSelect";
import {PageSizeSelect} from "../../../components/DataGrid/components/PageSizeSelect";
import {Pagination} from "../../../components/DataGrid/components/Pagination";
import {SearchInput} from "../../../components/DataGrid/components/SearchInput";
import {EditRedirectRowAction} from "../../../components/DataGrid/rowActions/EditRedirectRowAction";
import {ViewRedirectRowAction} from "../../../components/DataGrid/rowActions/ViewRedirectRowAction";
import {DownloadGroupedToolbarAction} from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction";
import {GroupedCallbackItem} from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem";
import {UploadGroupedToolbarAction} from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction";
import {FilterToolbarItem} from "../../../components/DataGrid/toolbarItems/FilterToolbarItem";
import {CodeCategoryTypeProvider} from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider";
import {CodesItem} from "../../../components/Form/CodesItem";
import {View} from "../../../components/View/View";
import {CodeCategory} from "../../../services/master/enums/CodeCategory";
import {SmtActiveFlag} from "../../../services/master/enums/SmtActiveFlag";
import {useDownloadOrderCalculationMasterByIdById, useDownloadOrderCalculationMasterFilter, useDownloadStockManagementAndOrderCalculationMasterByFilter, useDownloadStockManagementAndOrderCalculationMasterById, useDownloadStockManagementMasterByFilter, useDownloadStockManagementMasterById} from "../../../services/smt/api/smtDownloadApi";
import {useUploadOrderCalculationMaster, useUploadStockManagementAndOrderCalculationMaster, useUploadStockManagementMaster} from "../../../services/smt/api/smtUploadApi";
import {StockManagementMasterTransfer} from "../../../services/smt/models/StockManagementMasterTransfer";
import {StockManagementMasterView} from "../../../services/smt/models/StockMangementMasterView";
import {useMatch} from "../../../utils/useMatch";
import {applicationActions} from "../../Application/applicationSlice";
import {StockOrderScreenMode} from "./MSAUF020";

interface MSAUF020PcUiProps {
    filters: StockManagementMasterView,
    setFilters: React.Dispatch<React.SetStateAction<StockManagementMasterView>>,
    search: (filters: StockManagementMasterView) => void,
    data: StockManagementMasterTransfer[],
    totalCount: number,
    display: StockOrderScreenMode
}

export const MSAUF020PcUi = (props: MSAUF020PcUiProps) => {
    const intl = useIntl()
    const { display } = props
    const subtitle = display === StockOrderScreenMode.ONLYSMT ? "stockManagementMasterSub"
        : display === StockOrderScreenMode.ONLYOC ? "orderCalculationMasterSub" : "alarmUsagePatternSub"
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: subtitle })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, search, data, display }: MSAUF020PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(() => {
        const stockManagementColumns = getStockManagementColumns(intl, display)
        const orderCalculationColumns = getOrderCalculationColumns(intl, display)

        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 230 },
            { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
            // { field: 'dataSource', dataTypeName: CodeCategory.DataSource, title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
            { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 230 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsNo' }), width: 230 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 230 },

            { field: 'commonPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.commonPartsNo' }), width: 230 },
            { field: 'specialPartsType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.specialPartsType' }), width: 230 },
            { field: 'customerStockFlag', dataTypeName: CodeCategory.CustomsFlag, title: intl.formatMessage({ id: 'field.customerStockFlag' }), width: 230 },
            { field: 'activeFlag', dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: 'field.status' }), width: 230 },
        ]

        const usagePatternColumns = [
            { field: 'deliveryCustPat', dataTypeName: 'string', title: intl.formatMessage({ id: 'deliveryCustPat' }), width: 200 },
            { field: 'custDelayPat', dataTypeName: CodeCategory.AdjustmentPattern1, title: intl.formatMessage({ id: 'custDelayPat' }), width: 200 },
            { field: 'custAdvancePat', dataTypeName: CodeCategory.AdjustmentPattern2, title: intl.formatMessage({ id: 'custAdvancePat' }), width: 200 },
        ]

        // all columns
        return Arrays.concat(fixedColumns, stockManagementColumns, usagePatternColumns, orderCalculationColumns)
    }, [display, intl])

    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, display }), [filters, setFilters, search, display])
    const actionProps1 = useMemo(() => ({ search, filters, display }), [search, filters, display])
    const actionProps2 = useMemo(() => ({ filters, selections, display }), [filters, selections, display])
    const actionProps3 = useMemo(() => ({ display }), [display])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InventoryBoxFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CustomsFlag} />
        <SMTActiveFlagTypeProvider />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OnShippingDelayPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern1} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern2} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AlertMethod} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CalculationPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SafetyStockUom} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={actionProps3} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={actionProps3} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['commonPartsNo', 'specialPartsType', 'customerStockFlag', 'activeFlag',
                'minDays1', 'minDays2', 'minDays3', 'maxDays', 'minBoxes1', 'minBoxes2', 'minBoxes3', 'maxBoxes'
                , 'inventoryBoxFlag', 'outboundFluctuation', 'onshippingDelayPat', 'deliveryCustPat', 'custDelayPat', 'custAdvancePat'
                , 'buildOutMonth', 'lastPOMonth', 'lastDeliveryDate', 'alertType', 'safetyStockUom', 'safetyStock', 'maxStock', 'pairedPartsNo', 'calculationPattern']}
            columnSettings={{
                partsNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}

const SMTActiveFlagTypeProvider = () => {
    return <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} Formatter={Formatter} />
}

const Formatter = (props: KeyFormatterProps) => {
    const { value, formatter } = props
    const active = props.value === SmtActiveFlag.ACTIVE

    return <Typography variant="body2" style={{ color: active ? 'green' : 'red' }}>
        {value === null ? '' : formatter.format(value)}
    </Typography>
}

const getStockManagementColumns = (intl: IntlShape, display: StockOrderScreenMode) => {
    if (display !== StockOrderScreenMode.ONLYOC) {
        return [
            { field: 'minDays1', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays1' }), width: 200 },
            { field: 'minDays2', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays2' }), width: 200 },
            { field: 'minDays3', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays3' }), width: 200 },
            { field: 'maxDays', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStockDays' }), width: 200 },
            { field: 'minBoxes1', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes1' }), width: 200 },
            { field: 'minBoxes2', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes2' }), width: 200 },
            { field: 'minBoxes3', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes3' }), width: 200 },
            { field: 'maxBoxes', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStockBoxes' }), width: 200 },
            { field: 'inventoryBoxFlag', dataTypeName: CodeCategory.InventoryBoxFlag, title: intl.formatMessage({ id: 'inventoryBoxFla' }), width: 200 },
            { field: 'outboundFluctuation', dataTypeName: 'string', title: intl.formatMessage({ id: 'outboundFluctuation' }), width: 200 },
            { field: 'onshippingDelayPat', dataTypeName: CodeCategory.OnShippingDelayPattern, title: intl.formatMessage({ id: 'onshippingDelayPat' }), width: 200 },
            { field: 'buildOutMonth', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.buildOutMonth' }), width: 150 },
            { field: 'lastPOMonth', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.lastPOMonth' }), width: 150 },
            { field: 'lastDeliveryDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.lastDeliveryDate' }), width: 150 },
            { field: 'alertType', dataTypeName: CodeCategory.AlertMethod, title: intl.formatMessage({ id: 'field.alertType' }), width: 150 }
        ]
    } else {
        return []
    }
}

const getOrderCalculationColumns = (intl: IntlShape, display: StockOrderScreenMode) => {
    if (display !== StockOrderScreenMode.ONLYSMT) {
        return [
            { field: 'orderGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderGroupNo' }), width: 200 },
            { field: 'safetyStockUom', dataTypeName: CodeCategory.SafetyStockUom, title: intl.formatMessage({ id: 'safetyStockUom' }), width: 200 },
            { field: 'safetyStock', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStock' }), width: 200 },
            { field: 'maxStock', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStock' }), width: 200 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'calculationPattern', dataTypeName: CodeCategory.CalculationPattern, title: intl.formatMessage({ id: 'calculationPattern' }), width: 200 },
        ]
    } else {
        return []
    }
}

const FilterItem = (props: {
    filters: StockManagementMasterView,
    setFilters: React.Dispatch<React.SetStateAction<StockManagementMasterView>>,
    search: (filters: StockManagementMasterView) => void,
    display: StockOrderScreenMode
}) => {
    const intl = useIntl()
    const { filters, search, setFilters, display } = props

    const clear = useCallback((filters: StockManagementMasterView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: StockManagementMasterView) => {
        return [
            filters.inventoryBoxFlags,
            filters.onshippingDelayPats,
            filters.sellerList,
            filters.buyerList,
            filters.sellerList,
            filters.custDelayPats,
            filters.custAdvancePats,
            filters.calculationPatterns,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const showStockManagement = display !== StockOrderScreenMode.ONLYOC ? true : false
    const showOrderCalculation = display !== StockOrderScreenMode.ONLYSMT ? true : false

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        {showStockManagement &&
            <CodesItem field='inventoryBoxFlags' label={intl.formatMessage({ id: 'inventoryBoxFla' })} code={CodeCategory.InventoryBoxFlag} />}
        {showStockManagement &&
            <CodesItem field='onshippingDelayPats' label={intl.formatMessage({ id: 'onshippingDelayPat' })} code={CodeCategory.OnShippingDelayPattern} />}
        <CodesItem field='custDelayPats' label={intl.formatMessage({ id: 'custDelayPat' })} code={CodeCategory.AdjustmentPattern1} />
        <CodesItem field='custAdvancePats' label={intl.formatMessage({ id: 'custAdvancePat' })} code={CodeCategory.AdjustmentPattern2} />
        {showOrderCalculation &&
            <CodesItem field='calculationPatterns' label={intl.formatMessage({ id: 'calculationPattern' })} code={CodeCategory.CalculationPattern} />}
    </FilterToolbarItem>
}

const UploadAction = ({ search, filters, display }: {
    search: (filters: StockManagementMasterView) => void,
    filters: StockManagementMasterView,
    display: StockOrderScreenMode
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const uploadSmtApi = useUploadStockManagementMaster()
    const uploadOcApi = useUploadOrderCalculationMaster()
    const uploadSmtOcApi = useUploadStockManagementAndOrderCalculationMaster()
    const uploadApi = display === StockOrderScreenMode.ONLYOC ? uploadOcApi : display === StockOrderScreenMode.ONLYSMT ? uploadSmtApi : uploadSmtOcApi
    const [dialog, setDialog] = useState<boolean>(false)
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            const file = files && files.length > 0 ? files[0] : null
            file && uploadApi({ file: file, isConfirm: false }, { serialized: true, silent: true })
            .then((result) => {
                if (result.messages === null || result.messages.length === 0) {
                    const title = display === StockOrderScreenMode.ONLYOC ? 'post /smt-api/stockManagementMaster/uploadOrderCalculationMaster' : 
                                        display === StockOrderScreenMode.ONLYSMT ? 'post /smt-api/stockManagementMaster/uploadStockManagementMaster' : 
                                        'post /smt-api/stockManagementMaster/uploadStockManagementAndOrderCalculationMaster'
                    dispatch(applicationActions.pushSuccess({
                        title: intl.formatMessage({ id: title}),
                        messages: [{ code: 'notice.success'}],
                    }))
                    search(filters)
                    if (result.warningFlag === 1) { 
                        setDialog(true)
                    }
                } else {
                    const functionId = functionStore.register(() => {
                        uploadApi({ file: file, isConfirm: true }, { serialized: true }).then(() => {
                            search(filters)
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: intl.formatMessage({ id: 'confirm' }),
                        messages: result.messages,
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId,
                            label: 'CONFIRM',
                        }]
                    }))
                }   
            })
        })
    }, [uploadApi, display, dispatch, intl, search, filters, functionStore])
    return <>
        <UploadGroupedToolbarAction access="STCK.MSAUF020.UPLOAD"  >
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={intl.formatMessage({id: 'upload'})} callback={() => upload(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
        <WaringMessageDialog dialog={dialog} setDialog={setDialog} />
    </>
}

const DownloadAction = ({ filters, selections, display }: {
    filters: StockManagementMasterView,
    selections: number[],
    display: StockOrderScreenMode
}) => {

    const intl = useIntl()
    const downloadSmtByFilterApi = useDownloadStockManagementMasterByFilter()
    const downloadSmtByIdApi = useDownloadStockManagementMasterById()
    const downloadOcsByFilterApi = useDownloadOrderCalculationMasterFilter()
    const downloadOcsByIdApi = useDownloadOrderCalculationMasterByIdById()
    const downloadAllByFilterApi = useDownloadStockManagementAndOrderCalculationMasterByFilter()
    const downloadAllByIdApi = useDownloadStockManagementAndOrderCalculationMasterById()

    const downloadByIdApi = display === StockOrderScreenMode.ONLYOC
        ? downloadOcsByIdApi
        : display === StockOrderScreenMode.ONLYSMT
            ? downloadSmtByIdApi
            : downloadAllByIdApi

    const downloadByFilterApi = display === StockOrderScreenMode.ONLYOC
        ? downloadOcsByFilterApi
        : display === StockOrderScreenMode.ONLYSMT
            ? downloadSmtByFilterApi
            : downloadAllByFilterApi

    const download = useCallback(() => {
        if (selections && selections.length > 0) {
            downloadByIdApi({ customerPartIdList: selections })
        } else {
            downloadByFilterApi(Objects.delete(filters, 'page'))
        }
    }, [downloadByFilterApi, downloadByIdApi, filters, selections])

    return <DownloadGroupedToolbarAction access="STCK.MSAUF020.DOWNLOAD"  >
        {onClose => <>
            <GroupedCallbackItem label={intl.formatMessage({ id: 'download' })} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const { path } = useMatch()
    const detailPath = useCallback((tableRow: TableRow) => `${path}/view-${tableRow.rowId}`, [path])
    return <ViewRedirectRowAction access="STCK.MSAUF020.DETAIL" tableRow={tableRow} path={detailPath} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const { path } = useMatch()
    const detailPath = useCallback((tableRow: TableRow) => `${path}/edit-${tableRow.rowId}`, [path])
    return <EditRedirectRowAction access="STCK.MSAUF020.EDIT" tableRow={tableRow} path={detailPath} />
}

const WaringMessageDialog = memo((props: {
    dialog: boolean,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const {dialog, setDialog} = props
    const onClose = useCallback(() => setDialog(false), [setDialog])

    return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
        <DialogTitle style={{textAlign: 'center'}}><Typography variant="h3"><FormattedMessage id="warningMessageTitle" /></Typography></DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
            <DialogContentText>
                <FormattedMessage id="warningMessageContent2" />
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    width: '30%',
                }}>
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})
