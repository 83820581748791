import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, TableCell, Tooltip, Typography } from "@material-ui/core"
import { CheckCircle, Error, LocationOn, PlayCircleFilled, Refresh } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Detail, Expanding, Filtering, ObjectTypeProvider, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridBodyCellProps } from "@rithe/data-grid/dist/components/basic/DataGridBodyCell"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { DataGridDetailFormatterProps } from "@rithe/data-grid/dist/components/detail/DataGridDetailFormatter"
import { Break, DateRangeItem, EntryItem, Form, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Records } from "@rithe/utils"
import moment from "moment"
import React, { CSSProperties, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DeleteCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/DeleteCallbackToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { Scrollbar } from "../../../components/Scrollbar"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useCreateBookmark, useDeleteTrackings, useGetSingleContainerTracking, useRefreshAllUncompletedBookmarks } from "../../../services/cargo/api/cargoTackingApi"
import { CargoTackingFactor } from "../../../services/cargo/models/CargoTackingFactor"
import { CargoTackingResults, EventResult } from "../../../services/cargo/models/CargoTackingResults"
import { CarrierEntity } from "../../../services/delivery/models/CarrierEntity"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { applicationActions } from "../../Application/applicationSlice"
import { ActionType } from "./ActionType"
import { JourneyStatus } from "./JourneyStatus"

interface CIS010PcUiProps {
    filters: CargoTackingFactor,
    setFilters: React.Dispatch<SetStateAction<CargoTackingFactor>>,
    search: (filter: CargoTackingFactor) => void,
    data: CargoTackingResults[],
    totalCount: number,
    portList: TnmPort[],
    carrierList: CarrierEntity[]
}

export const CIS010PcUi = (props: CIS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({
    filters,
    setFilters,
    search,
    data,
    portList,
    carrierList
}: CIS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const columns = useMemo(() => [
        { field: 'containerNo', dataTypeName: 'container', title: intl.formatMessage({ id: 'container' }), width: 180 },
        { field: 'blNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'blNo' }), width: 230 },
        { field: 'carrierNo', dataTypeName: 'carrier', title: intl.formatMessage({ id: 'carrierVessel' }), width: 250 },
        { field: 'ata', dataTypeName: 'ata', title: intl.formatMessage({ id: 'atapod' }), width: 230 },
        { field: 'eta', dataTypeName: 'eta', title: intl.formatMessage({ id: 'etapod' }), width: 230 },
        { field: 'scheduledEta', dataTypeName: 'string', title: intl.formatMessage({ id: 'planetapod' }), width: 230, getCellValue: (row: Row) => row.scheduledEta && moment(row.scheduledEta.substring(0, 10)).format('DD MMM YYYY') },
        { field: 'initialScheduledEta', dataTypeName: 'string', title: intl.formatMessage({ id: 'initialPlanEtaPod' }), width: 230, getCellValue: (row: Row) => row.initialScheduledEta && moment(row.initialScheduledEta.substring(0, 10)).format('DD MMM YYYY') },
        { field: 'actualDischargeLt', dataTypeName: 'discharge', title: intl.formatMessage({ id: 'dischargeDate' }), width: 180 },
        { field: 'status', dataTypeName: 'status', title: intl.formatMessage({ id: 'cargostatus' }), width: 150 },
        { field: 'lastEvent', dataTypeName: 'lastevent', title: intl.formatMessage({ id: 'lastEvent' }), width: 280 },
        { field: 'pol', dataTypeName: 'string', title: intl.formatMessage({ id: 'pol' }), width: 120 },
        { field: 'pot', dataTypeName: 'string', title: intl.formatMessage({ id: 'pot' }), width: 120 },
        { field: 'pod', dataTypeName: 'string', title: intl.formatMessage({ id: 'pod' }), width: 120 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.bookMarkId, [])

    const itemProps1 = useMemo(() => ({ filters, setFilters, search, portList }), [filters, setFilters, search, portList])
    const actionProps1 = useMemo(() => ({ search, filters, carrierList }), [carrierList, filters, search])
    const deleteActionProps = useMemo(() => ({ search, filters, selections, setSelections }), [filters, search, selections, setSelections])
    const refreshActionProps = useMemo(() => ({ search, filters, selections }), [filters, search, selections])
    const refreshRowActionProps = useMemo(() => ({ search, filters }), [filters, search])
    const display2 = useCallback((tableRow: TableRow) => true, [])

    const displayData = useMemo(() => {
        return data.map(d => {
            if ((d.actionType && d.actionType === ActionType.BY_CONTAINER_NO)
                    || (d.blNo && d.blNo === "AUTO")) {
                return { ...d, blNo: "-" }
            } else {
                return d
            }
        })
    }, [data])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ContainerTypeProvider />
        <CarrierTypeProvider />
        <ETATypeProvider />
        <ATATypeProvider />
        <StatusTypeProvider />
        <DischargeTypeProvider />
        <LastEventTypeProvider />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DeleteAction} actionProps={deleteActionProps} />
        <ToolbarActionProvider Action={RefreshAction} actionProps={refreshActionProps} />
        <RowActionProvider name="refresh" Action={RefreshRowAction} actionProps={refreshRowActionProps} display={display2} />
        <RowActionProvider name="viewCargoTrackingDetail" Action={ViewCargoTrackingDetailRowAction} display={display2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                invoiceNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemProps1} />
        <Detail DetailFormatter={DetailFormatter} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
        <Expanding BodyExpandCell={DataGridBodyCell} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CargoTackingFactor,
    search: (filter: CargoTackingFactor) => void,
    portList: TnmPort[],
    setFilters: React.Dispatch<SetStateAction<CargoTackingFactor>>,
}) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: CargoTackingFactor) => {
        return { page: filters.page }
    }, [])

    //ATA
    const ataGetValue = useCallback((filters: CargoTackingFactor) => {
        return [filters.ataStart ?? null, filters.ataEnd ?? null]
    }, [])
    const ataMapValue = useCallback((filters: CargoTackingFactor, value: any) => {
        return { ...filters ?? {}, ataStart: value[0], ataEnd: value[1] }
    }, [])


    //ETA
    const etaGetValue = useCallback((filters: CargoTackingFactor) => {
        return [filters.etaStart ?? null, filters.etaEnd ?? null]
    }, [])
    const etaMapValue = useCallback((filters: CargoTackingFactor, value: any) => {
        return { ...filters ?? {}, etaStart: value[0], etaEnd: value[1] }
    }, [])

    //Scheduled Eta
    const scheduledEtaGetValue = useCallback((filters: CargoTackingFactor) => {
        return [filters.scheduledEtaStart ?? null, filters.scheduledEtaEnd ?? null]
    }, [])
    const scheduledEtaMapValue = useCallback((filters: CargoTackingFactor, value: any) => {
        return { ...filters ?? {}, scheduledEtaStart: value[0], scheduledEtaEnd: value[1] }
    }, [])

    //Initial Scheduled Eta
    const initialScheduledEtaGetValue = useCallback((filters: CargoTackingFactor) => {
        return [filters.initialScheduledEtaStart ?? null, filters.initialScheduledEtaEnd ?? null]
    }, [])
    const initialScheduledEtaMapValue = useCallback((filters: CargoTackingFactor, value: any) => {
        return { ...filters ?? {}, initialScheduledEtaStart: value[0], initialScheduledEtaEnd: value[1] }
    }, [])

    //Discharge Date
    const dischargeDateGetValue = useCallback((filters: CargoTackingFactor) => {
        return [filters.dischargeDateStart ?? null, filters.dischargeDateEnd ?? null]
    }, [])
    const dischargeDateMapValue = useCallback((filters: CargoTackingFactor, value: any) => {
        return { ...filters ?? {}, dischargeDateStart: value[0], dischargeDateEnd: value[1] }
    }, [])



    const filterCounter = useCallback((filters: CargoTackingFactor) => {
        return [
            filters.containerNo,
            filters.blNo,
            filters.carrierNo,
            filters.ataStart || filters.ataEnd,
            filters.etaStart || filters.etaEnd,
            filters.scheduledEtaStart || filters.scheduledEtaEnd,
            filters.initialScheduledEtaStart || filters.initialScheduledEtaEnd,
            filters.dischargeDateStart || filters.dischargeDateEnd,
            filters.pol,
            filters.pot,
            filters.pod,
        ].filter(value => value !== undefined && value !== null).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="containerNo" label={intl.formatMessage({ id: 'field.containerNo' })} />
        <StringItem field="blNo" label={intl.formatMessage({ id: 'blNo' })} />
        <StringItem field="carrierNo" label={intl.formatMessage({ id: 'field.carrierNo' })} />
        <DateRangeItem field="ata" label={intl.formatMessage({ id: 'atapod' })} getValue={ataGetValue} mapValue={ataMapValue} />
        <DateRangeItem field="eta" label={intl.formatMessage({ id: 'etapod' })} getValue={etaGetValue} mapValue={etaMapValue} />
        <DateRangeItem field="scheduledEta" label={intl.formatMessage({ id: 'planetapod' })} getValue={scheduledEtaGetValue} mapValue={scheduledEtaMapValue} />
        <DateRangeItem field="initialScheduledEta" label={intl.formatMessage({ id: 'initialPlanEtaPod' })} getValue={initialScheduledEtaGetValue} mapValue={initialScheduledEtaMapValue} />
        <DateRangeItem field="dischargeDate" label={intl.formatMessage({ id: 'dischargeDate' })} getValue={dischargeDateGetValue} mapValue={dischargeDateMapValue} />
        <StringItem field="pol" label={intl.formatMessage({ id: 'pol' })} />
        <StringItem field="pot" label={intl.formatMessage({ id: 'pot' })} />
        <StringItem field="pod" label={intl.formatMessage({ id: 'pod' })} />
    </FilterToolbarItem>

}

const CreateAction = ({ filters, search, carrierList }: {
    filters: CargoTackingFactor,
    search: (filter: CargoTackingFactor) => void,
    carrierList: CarrierEntity[]
}) => {

    const [open, setOpen] = useState<boolean>(false)
    return <>
        <CreateCallbackToolbarAction title={<FormattedMessage id="createNew" />} callback={() => { setOpen(true) }} />
        <ContainerSearch open={open} onClose={() => setOpen(false)} filters={filters} search={search} carrierList={carrierList} />
    </>
}

const DeleteAction = ({ selections, filters, search, setSelections }: {
    selections: string[],
    filters: CargoTackingFactor,
    search: (filter: CargoTackingFactor) => void,
    setSelections: React.Dispatch<React.SetStateAction<string[]>>
}) => {

    const dispatch = useDispatch()
    const deleteTracking = useDeleteTrackings()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    // do delete operation
    const doDelete = useCallback(() => {
        if (selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'delete' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                deleteTracking({ bookMarkIds: selections }, { serialized: true }).then(() => {
                    setSelections([])
                    search(filters)
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }
                ]
            }))
        }
    }, [selections, dispatch, functionStore, title, deleteTracking, setSelections, search, filters])
    return <>
        <DeleteCallbackToolbarAction title={title} callback={doDelete} disabled={disabled} />
    </>
}

const RefreshAction = ({ filters, search, selections }: {
    filters: CargoTackingFactor,
    search: (filter: CargoTackingFactor) => void,
    selections: string[]
}) => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const refreshData = useRefreshAllUncompletedBookmarks()

    // do refresh
    const doRefreshData = useCallback(() => {
        if (selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'refresh' }, messages: { code: 'w0002' } }))
            return;
        }
        refreshData({ bookMarkIds: selections }, { serialized: true, silent: true }).then((result) => {
            if (result.length > 0) {
                dispatch(applicationActions.pushError({ title: { code: 'refresh' }, messages: { code: 'w0695', args: [result.join(",")] } }))
            } else {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/refreshAllUncompletedBookmarks' }),
                    messages: [{ code: 'notice.success' }],
                }))
            }
            search(filters)
        })
    }, [dispatch, filters, intl, refreshData, search, selections])

    return <>
        <CallbackToolbarAction title={<FormattedMessage id="refresh" />} icon={<Refresh />} callback={doRefreshData} />
    </>
}

const RefreshRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & {
    search: (filters: CargoTackingFactor) => void,
    filters: CargoTackingFactor
}) => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const refreshData = useRefreshAllUncompletedBookmarks()

    // do refresh
    const doRefreshData = useCallback((tableRow: TableRow) => {
        refreshData({ bookMarkIds: [tableRow.row?.bookMarkId] }, { serialized: true, silent: true }).then((result) => {
            if (result.length > 0) {
                dispatch(applicationActions.pushError({ title: { code: 'refresh' }, messages: { code: 'w0695', args: [result.join(",")] } }))
            } else {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/refreshAllUncompletedBookmarks' }),
                    messages: [{ code: 'notice.success' }],
                }))
            }
            search(filters)
        })
    }, [dispatch, filters, intl, refreshData, search])

    return <CallbackRowAction title={<FormattedMessage id="refresh" />} icon={<Refresh />} callback={doRefreshData} tableRow={tableRow} />
}

const ViewCargoTrackingDetailRowAction = ({ tableRow }: DataGridRowActionProps & {
}) => {
    const [open, setOpen] = useState<boolean>(false)
    return <>
        <CallbackRowAction title={<FormattedMessage id="viewCargoTrackingDetail" />} icon={<LocationOn />} callback={() => { setOpen(true) }} tableRow={tableRow} />
        <ViewCargoTrackingDetail open={open} onClose={() => setOpen(false)} tableRow={tableRow} />
    </>
}

const DetailFormatter = ({ row }: DataGridDetailFormatterProps) => {
    return <TrackingDetail events={row?.eventList ?? []} />
}

const EventCard = ({ events, style, sticky }: { events: EventResult[], style?: CSSProperties, sticky?: boolean }) => {
    const [selectValue, setSelectedValue] = useState<string | number>()
    const onSelectedValueChange = useCallback((value: string | number) => {
        setSelectedValue(value)
    }, [setSelectedValue])


    const eventMap: Map<number, EventResult[]> = new Map();
    events.forEach(event => {
        const bookmarkId = event.bookMarkId;
        if (!eventMap.has(bookmarkId)) {
            eventMap.set(bookmarkId, [])
        }
        eventMap.get(bookmarkId)?.push(event)
    })

    useEffect(() => {
        if (events && events.length > 0) {
            setSelectedValue(events[0].containerNo)
        } else {
            setSelectedValue("")
        }
    }, [events, setSelectedValue])

    return <ViewTabControl>
        <ViewTabs selectedValue={selectValue} onSelectedValueChange={onSelectedValueChange}>
            {Array.from(eventMap.entries()).map((entry, index) => {
                return <ViewTab label={entry[1][0]?.containerNo} value={entry[1][0]?.containerNo}></ViewTab>
            })
            }
        </ViewTabs>
        <ViewPanels>
            {Array.from(eventMap.entries()).map((entry, index) => {
                return <ViewPanel value={entry[1][0]?.containerNo}>
                    <Scrollbar style={{ width: '100%', height: '100%' }}>
                        <TrackingDetail events={entry[1]} style={style} sticky={sticky} />
                    </Scrollbar>
                </ViewPanel>
            })}
        </ViewPanels>
    </ViewTabControl>
}

const TrackingDetail = ({ events, style, sticky }: { events: EventResult[], style?: CSSProperties, sticky?: boolean }) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'locationRaw', dataTypeName: 'event', title: intl.formatMessage({ id: 'location' }), width: 180 },
        { field: 'eventTimeEstimated', dataTypeName: 'eventdate', title: intl.formatMessage({ id: 'planned' }), width: 150 },
        { field: 'eventTime', dataTypeName: 'eventdate', title: intl.formatMessage({ id: 'actual' }), width: 150 },
        { field: 'eventTypeName', dataTypeName: 'event', title: intl.formatMessage({ id: 'milestone' }), width: 300 },
    ], [intl])

    return <div style={style ? style : { paddingTop: 15, paddingBottom: 15 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout>
                <TableHeaderLayout sticky={sticky} />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <EventTypeProvider />
            <EventDateTypeProvider />
            <Data rows={events} columns={columns} />
            <Action width={178} />
        </DataGrid>
    </div>
}

const DataGridBodyCell = (props: DataGridBodyCellProps) => {
    const { align, width, colSpan, rowSpan, freezePosition, freezeOffset, children, tableRows, } = props

    const eventList = tableRows[0].row?.eventList ?? []
    const display = eventList && eventList.length && eventList.length > 0
    const styles = useStyles()
    return <TableCell colSpan={colSpan} rowSpan={rowSpan}
        style={{
            width,
            position: freezePosition ? 'sticky' : 'static',
            left: freezePosition === 'start' ? freezeOffset : undefined,
            right: freezePosition === 'end' ? freezeOffset : undefined,
            zIndex: freezePosition ? 1 : 0,
        }}
        className={styles.root}
    >
        <div style={{ width, justifyContent: align === 'center' ? 'center' : `flex-${align}`, }} className={styles.layout} >
            {display ? children : null}
        </div>
    </TableCell>
}

/** Formatter for Container  */
const ContainerFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <div >
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{row.containerNo}</Typography>
        {/* <Typography variant="body2" >{'Cloth'}</Typography> */}
        <Typography variant="body2" >{row.containerType}</Typography>
    </div>
}
const ContainerTypeProvider = () => {
    return <ObjectTypeProvider name="container" Formatter={ContainerFormatter} />
}

/** Formatter for Carrier/Verssel  */
const CarrierFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <div >
        <Typography variant="body2" >{row.carrierNo && row.carrierNo !== "AUTO" ? row.carrierNo : '-'}</Typography>
        {
            row.vessels && row.vessels.map((vessel: any) => {
                return <>
                    <Typography variant="body2" >{vessel.vesselName}</Typography>
                    <Typography variant="body2" >{vessel.voyageNo}</Typography>
                </>
            })
        }
    </div >
}
const CarrierTypeProvider = () => {
    return <ObjectTypeProvider name="carrier" Formatter={CarrierFormatter} />
}

/** Formatter for ETA @ POD  */
const ETAFormatter = ({ row }: ObjectFormatterProps) => {
    const intl = useIntl()
    return <div >
        <Typography variant="body2" style={{ color: row.eta ? "" : "red" }}>
            {row.eta ? row.eta && moment(row.eta.substring(0, 10)).format('DD MMM YYYY') : intl.formatMessage({ id: 'nullPredictiveETA' })}
        </Typography>
    </div>
}
const ETATypeProvider = () => {
    return <ObjectTypeProvider name="eta" Formatter={ETAFormatter} />
}

/** Formatter for ATA @ POD */
const ATAFormatter = ({ row }: ObjectFormatterProps) => {
    const intl = useIntl()
    return <div >
        <Typography variant="body2" style={{ color: row.ata ? "" : "red" }}>
            {row.ata ? row.ata && moment(row.ata.substring(0, 10)).format('DD MMM YYYY') : intl.formatMessage({ id: 'nullAcutalATA' })}
        </Typography>
    </div>
}
const ATATypeProvider = () => {
    return <ObjectTypeProvider name="ata" Formatter={ATAFormatter} />
}

const CalculateStatus = (row: Row) => {
    const initialPlanEta = !row.initialScheduledEta ? null : moment(row.initialScheduledEta.substring(0, 10))
    const planEta = !row.scheduledEta ? null : moment(row.scheduledEta.substring(0, 10))
    const eta = !row.eta ? null : moment(row.eta.substring(0, 10))
    const ata = !row.ata ? null : moment(row.ata.substring(0, 10))
    const actualDate = ata ? ata : eta ? eta : planEta
    const days = !initialPlanEta || !actualDate ? 0 : actualDate.diff(initialPlanEta, 'day');
    const status = row.status
    return {
        delayDays: days, status: (status === JourneyStatus.INIT || status === JourneyStatus.UPLOADED) ? 99
            : ((status === JourneyStatus.SUCCESS
                || status === JourneyStatus.SUCCESS_EARLY_JOURNEY
                || status === JourneyStatus.EARLY_JOURNEY
                || status === JourneyStatus.JOURNEY_IN_PROGRESS) ? (days <= 0 ? 1 : -1) : 0)
    }
}

/** Formatter for Status  */
const StatusFormatter = ({ value, row }: ObjectFormatterProps) => {
    const { delayDays, status } = CalculateStatus(row)
    const style = useStyles()

    // get daly
    const bgcolor = status === 99 ? '#F0B548' : (status === 1 ? '#00CCAD' : (status === 0 ? "#888888" : '#D94C00'))
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        {
            status === 99 ? <Typography variant="body2" >{'INITIALIZING'}</Typography>
                : (status === 1 ? <Typography variant="body2" >{'ON TIME'}</Typography>
                    : (status === 0 ? <Typography variant="body2" >{'COMPLETED'}</Typography>
                        : <Typography variant="body2" >{'+' + delayDays + ' DAY DELAY'}</Typography>))
        }
    </div>
}
const StatusTypeProvider = () => {
    return <ObjectTypeProvider name="status" Formatter={StatusFormatter} />
}

const DischargeTypeProvider = () => {
    return <ObjectTypeProvider name="discharge" Formatter={DischargeFormatter} />
}
/** Formatter for Status  */
const DischargeFormatter = ({ value, row }: ObjectFormatterProps) => {
    const dischargeDate = row.actualDischargeLt ? row.actualDischargeLt : row.scheduledDischarge
    return <div >
        <Typography variant="body2" style={{ color: dischargeDate ? "" : "red" }}>
            {dischargeDate ? dischargeDate && moment(dischargeDate.substring(0, 10)).format('DD MMM YYYY') : null}
        </Typography>
    </div>
}
/** Formatter for LastEvent  */
const LastEventFormatter = ({ value, row }: ObjectFormatterProps) => {
    const enventContents = row.lastEventDate ? moment(row.lastEventDate).format('DD MMM YYYY') + " : " + row.lastEvent : ""
    return <div >
        <Typography variant="body2">{enventContents}</Typography>
    </div>
}
const LastEventTypeProvider = () => {
    return <ObjectTypeProvider name="lastevent" Formatter={LastEventFormatter} />
}

/** Formatter for LastEvent  */
const EventFormatter = ({ value, row }: ObjectFormatterProps) => {
    const isPassEnvent = row.eventTime !== null && row.eventTime !== undefined
    return <>
        {isPassEnvent ?
            <Typography variant="body2" style={{ color: '#888888' }}>{value}</Typography>
            :
            <Typography variant="body2" style={{ color: 'blue', fontWeight: 'bold' }}>{value}</Typography>
        }
    </>
}
const EventTypeProvider = () => {
    return <ObjectTypeProvider name="event" Formatter={EventFormatter} />
}
/** Formatter for LastEvent  */
const EventDateFormatter = ({ value, row }: ObjectFormatterProps) => {
    const isPassEnvent = row.eventTime !== null && row.eventTime !== undefined
    return < >
        {!value ? ''
            : isPassEnvent ?
                <Typography variant="body2" style={{ color: '#888888' }}>{moment(value).format('DD MMM YYYY')}</Typography>
                :
                <Typography variant="body2" style={{ color: 'blue', fontWeight: 'bold' }}>{moment(value).format('DD MMM YYYY')}</Typography>
        }
    </>
}
const EventDateTypeProvider = () => {
    return <ObjectTypeProvider name="eventdate" Formatter={EventDateFormatter} />
}


const ContainerSearch = ({ open, onClose, search, filters, carrierList }: {
    open: boolean,
    onClose: () => void,
    filters: CargoTackingFactor,
    search: (filter: CargoTackingFactor) => void,
    carrierList: CarrierEntity[]
}) => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const [step, setStep] = useState<number>(0)
    const [createByBl, setCreateByBl] = useState<boolean>(false)
    const [createByCntr, setCreateByCntr] = useState<boolean>(false)
    const [disableSearch, setDisableSearch] = useState<boolean>(false)
    const [events, setEvents] = useState<EventResult[]>([])
    const createBookmark = useCreateBookmark()
    const getContainerTackingInfo = useGetSingleContainerTracking()
    const [condition, setCondition] = useState<{ containerNo: string, blNo: string, carrierNo: string }>({ containerNo: '', blNo: '', carrierNo: '' })
    const [error, setError] = useState<boolean>(false)
    const carriersMap = useMemo(() => carrierList.map(c => [c.carrierCode, c.carrierName] as [string, string]), [carrierList])

    const callback = useCallback(() => {
        setStep(0)
        setError(false)
        setCreateByBl(true)
        setCreateByCntr(true)
        setDisableSearch(true)
        setEvents([])
        setStep(1)
        createBookmark(condition, { serialized: false, silent: false }).then((result) => {
            if (result) {
                // call back
                setStep(2)
                getContainerTackingInfo(result, { serialized: false, silent: false }).then((event) => {
                    if (event && event.events && event.events.length !== 0) {
                        // call back
                        setStep(9)
                        setEvents(event.events)
                    }
                    else {
                        // status is INIT
                        dispatch(applicationActions.pushWarning({ title: intl.formatMessage({ id: 'search' }), messages: { code: 'w0707' } }))
                        setStep(9)
                    }
                }).finally(() => {
                    if (condition.blNo) {
                        setCreateByCntr(false)
                    } else if (condition.containerNo) {
                        setCreateByBl(false)
                    }
                    setDisableSearch(false)
                }).catch(() => {
                    setDisableSearch(false)
                    setError(true)
                })
            }
        }).catch(() => {
            setError(true)
            setDisableSearch(false)
            if (condition.blNo) {
                setCreateByBl(true)
            } else if (condition.containerNo) {
                setCreateByCntr(true)
            }
        })
    }, [condition, createBookmark, dispatch, getContainerTackingInfo, intl])

    const clear = useCallback(() => {
        setStep(0)
        setEvents([])
        setError(false)
        setCondition({ containerNo: '', blNo: '', carrierNo: '' })
        setCreateByBl(false)
        setCreateByCntr(false)
    }, [])

    const refresh = useCallback(() => {
        if (step === 9) {
            // refresh
            search(filters)
        }
    }, [filters, search, step])

    const clearCarrier = useCallback((field: string, value: any) => {
        if (field !== 'containerNo') {
            return
        }
        setCondition((con) => ({ ...con, carrierNo: '' }))
    }, [])

    const style = useStyles()

    return <CardDialog open={open} onClose={onClose} fullScreen={false} maxWidth='md'>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{'Search Container Tracking'}</Typography></DialogTitle>
        <DialogContent style={{ height: 400 }}>
            <Divider />
            <Grid container xs={12} >
                <Grid item xs={3} className={style.dashedLine}>
                    <Form data={condition} setData={setCondition} columnCount={13} labelWidth={80} onChange={clearCarrier}>
                        <EntryItem colSpan={5} field="carrierNo" label={intl.formatMessage({ id: 'Carrier No' })} entries={carriersMap} disabled={condition.blNo ? false : (condition.containerNo ? true : false)} />
                        <Break />
                        <StringItem colSpan={5} field="containerNo" label={intl.formatMessage({ id: 'Container No' })} />
                        <Break />
                        <GridItem columnSpan={5}>
                            <Tooltip title={<FormattedMessage id="createBookmarkByBlNo" />} >
                                <div><StringItem colSpan={6} field="blNo" label={intl.formatMessage({ id: 'B/L No' })} /></div>
                            </Tooltip>
                        </GridItem>
                        <Break />
                        <GridItem columnSpan={5} style={{ marginTop: 20, }}>
                            <Button variant="contained" color="primary" fullWidth onClick={callback} disabled={disableSearch}>
                                <FormattedMessage id="search" />
                            </Button>
                        </GridItem>
                    </Form>
                </Grid>
                <Grid item xs={9} style={{ marginTop: 10, paddingLeft: 20 }}>
                    <Form data={condition} setData={setCondition} columnCount={13} labelWidth={80} >
                        <GridItem columnSpan={10} style={{}}>
                            {(createByBl || condition.blNo) ? <Typography variant="body1" >{'>> Create container bookmark by B/L No'}</Typography>
                                : (createByCntr || condition.containerNo) ?
                                    <Typography variant="body1" >{'>> Create container bookmark by Container No'}</Typography> : <></>
                            }
                        </GridItem>
                        <GridItem columnSpan={1}>
                            {step >= 2 ? <CheckCircle style={{ color: '#00CCAD' }} />
                                : step === 1 ? (error ? ((createByCntr || createByBl) ? <Error style={{ color: '#D94C00' }} /> : <></>)
                                    : ((createByCntr || createByBl) ? <CircularProgress size={"1.5rem"} style={{ color: '#0DADEA' }} /> : <></>))
                                    : (condition.blNo || condition.containerNo) ? <PlayCircleFilled style={{ color: 'blue' }} /> : <></>
                            }
                        </GridItem>
                        <Break />
                        <GridItem columnSpan={10}>
                            {(createByBl || condition.blNo || createByCntr || condition.containerNo) ?
                                <Typography variant="body1" >{'>> Fetch container tracking information'}</Typography> : <></>
                            }
                        </GridItem>
                        <GridItem columnSpan={1}>
                            {step >= 3 ? <CheckCircle style={{ color: '#00CCAD' }} />
                                : step === 2 ? (error ? <Error style={{ color: '#D94C00' }} /> : <CircularProgress size={"1.5rem"} style={{ color: '#0DADEA' }} />)
                                    : (condition.blNo || condition.containerNo) ? <PlayCircleFilled style={{ color: 'blue' }} /> : <></>
                            }
                        </GridItem>
                        <Break />
                        <GridItem columnSpan={13}>
                            <div style={{ height: 300 }}>
                                <EventCard events={events} sticky />
                            </div>
                        </GridItem>
                    </Form>
                </Grid>
            </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
            <DialogAction title={<FormattedMessage id="Close" />} callback={() => {
                refresh()
                clear()
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const ViewCargoTrackingDetail = ({ open, onClose, tableRow }: {
    open: boolean,
    onClose: () => void,
    tableRow: TableRow
}) => {
    // const toPath = `https://app.portcast.io/v2/container_tracking/map/${tableRow.row?.externalBookmarkId}`
    const toPath = `https://app.portcast.io/embed/tracking/container/${tableRow.row?.externalBookmarkId}/map`
    const eventsList = tableRow.row?.eventList
    const intl = useIntl()

    return <CardDialog open={open} onClose={onClose} fullScreen={false} maxWidth="xl" fullWidth>
        <DialogTitle style={{ cursor: 'move' }} >
            <Typography variant="h3">{intl.formatMessage({ id: 'containerNumber' }) + ": " + tableRow.row?.containerNo}</Typography>
        </DialogTitle>
        <DialogContent style={{ height: 700 }}>
            <Divider />
            <Grid container xs={12} style={{ marginTop: 10 }}>
                <Grid item xs={4} style={{}}>
                    <Scrollbar flexbox style={{ height: 670 }}>
                        {eventsList.map((event: EventResult, index: number) => {
                            const isPassEnvent = event.eventTime !== null && event.eventTime !== undefined
                            const color = isPassEnvent ? '#888888' : 'blue'
                            const date = event.eventTime ? event.eventTime : event.eventTimeEstimated
                            return <Grid container xs={12} key={index}>
                                <Grid item xs={2} style={{ paddingBottom: 20 }}>
                                    {isPassEnvent ?
                                        <Typography variant="body2" style={{ color: '#888888' }}>{moment(date.substring(0, 10)).format('DD MMM YYYY')}</Typography>
                                        : <Typography variant="body2" style={{ color: 'blue', fontWeight: 'bold' }}>{moment(date.substring(0, 10)).format('DD MMM YYYY')}</Typography>}
                                </Grid>
                                <div style={{ marginRight: 30, marginTop: 5, }}>
                                    <div style={{ height: 10, width: 10, borderRadius: 5, backgroundColor: color, }} />
                                    {index !== eventsList.length - 1 && <div style={{ height: '100%', borderLeftWidth: '2px', borderLeftColor: color, borderLeftStyle: 'solid', marginLeft: 4, paddingBottom: 20 }}></div>}
                                </div>
                                <Grid item xs={7} style={{ paddingBottom: 20 }}>
                                    <Grid item xs={12} >
                                        {isPassEnvent ?
                                            <Typography variant="body2" style={{ color: '#888888' }}>{intl.formatMessage({ id: 'milestone' }) + ": " + event.eventTypeName}</Typography>
                                            :
                                            <Typography variant="body2" style={{ color: 'blue', fontWeight: 'bold' }}>{intl.formatMessage({ id: 'milestone' }) + ": " + event.eventTypeName}</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12} >
                                        {isPassEnvent ?
                                            <Typography variant="body2" style={{ color: '#888888' }}>{intl.formatMessage({ id: 'location' }) + ": " + event.locationRaw}</Typography>
                                            :
                                            <Typography variant="body2" style={{ color: 'blue', fontWeight: 'bold' }}>{intl.formatMessage({ id: 'location' }) + ": " + event.locationRaw}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Scrollbar>
                </Grid>
                <Grid item xs={8} >
                    <iframe src={toPath} title="map" style={{ width: '100%', height: '100%' }}></iframe>
                </Grid>
            </Grid >
        </DialogContent >
        <Divider />
        <DialogActions>
            <DialogAction title={<FormattedMessage id="Close" />} callback={() => {
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}




const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        boxSizing: 'border-box',
    },
    layout: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: `0 ${theme.spacing(1)}px`,
        boxSizing: 'border-box',
        overflow: 'hidden',
    },
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    dashedLine: {
        height: 383,
        borderRight: 'dashed',
        borderRightWidth: '2px',
        borderRightColor: 'rgba(0, 0, 0, 0.12)'
    }
}))