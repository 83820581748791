export const routeList: { [key: string]: string[] } = {
    "MARS.MLS010.VIEW": ["/company"],
    "MARS.MLS011.VIEW": ["/company/create"],
    "MARS.MLS020.VIEW": ["/bu"],
    "MARS.MLS021.VIEW": ["/bu/create"],
    "MARS.MLS030.VIEW": ["/customer"],
    "MARS.MLS031.VIEW": ["/customer/create"],
    "MARS.MLS040.VIEW": ["/supplier"],
    "MARS.MLS041.VIEW": ["/supplier/create"],
    "MARS.MLS050.VIEW": ["/dc"],
    "MARS.MLS051.VIEW": ["/dc/create"],
    "MARS.MLS060.VIEW": ["/port"],
    "MARS.MLS070.VIEW": ["/globalparts"],
    "MARS.MLS071.VIEW": ["/globalparts/create", "/globalparts/edit"],
    "MARS.MLS080.VIEW": ["/unitparts"],
    "MARS.MLS081.VIEW": ["/unitparts/create", "/unitparts/edit"],
    "MARS.MLS090.VIEW": ["/contract"],
    "MARS.MLS100.VIEW": ["/contractroute"],
    "MARS.MLS110.VIEW": ["/payment"],
    "MARS.MLS111.VIEW": ["/payment/create"],
    "MARS.MLS120.VIEW": ["/shippingroute"],
    "MARS.MLS121.VIEW": ["/shippingroute/create"],
    "MASTER.MLS180.VIEW": ["/creditrules"],
    "MASTER.MLS190.VIEW": ["/creditsetting"],
    "MARS.MLS250.VIEW": ["/cargostatus"],
    "LOGI.LSS010.VIEW": ["/shippingdetail"],
    "LOGI.CIS010.VIEW": ["/containerJourney"],
    "LOGI.CIS011.VIEW": ["/cargotrackingDetail"],
    "DASH.DAS010.VIEW": ["/dashboard/pomanagement"],
    "DASH.DAS012.VIEW": ["/dashboard/costs"],
    "DASH.DAS013.VIEW": ["/dashboard/vendorAnalysis"],
    "DASH.DAS014.VIEW": ["/dashboard/inventory"],
    "DASH.DAS015.VIEW": ["/dashboard/acctReceivable"],
    "MARS.MLS130.VIEW": ["/calendar"],
    "MARS.MLS131.VIEW": ["/calendar/create"],
    "MARS.MGS010.VIEW": ["/commondownloadupload"],
    "MARS.MLS170.VIEW": ["/fobSetting"],
    "MARS.MLS171.VIEW": ["/fobSetting/create"],
    "MARS.MSESF021.VIEW": ["/user-setting"],
    "MARS.MOS020.VIEW": ["/requestTo"],
    "MARS.MOS021.CREATEADD": ["/requestTo/addNewPart"],
    "MARS.MOS021.CREATEMODIFY": ["/requestTo/editPacking", "/requestTo/editPrice"],
    "MARS.MOS021.CREATEREMOVE": ["/requestTo/remove"],
    "MARS.MOS030.VIEW": ["/receivedRequest"],
    "MARS.MSESF030.VIEW": ["/selfAccessHistory"],
    "ORDER.OCS030.VIEW": ["/monitoring"],
    "ORDER.OCS010.VIEW": ["/co"],
    "ORDER.OFS010.VIEW": ["/cfc"],
    "ORDER.OPS010.VIEW": ["/po"],
    "ORDER.OPS020.VIEW": ["/pospot"],
    "ORDER.ORS010.VIEW": ["/cc"],
    "ORDER.OSS010.VIEW": ["/so"],
    "ORDER.ORS020.VIEW": ["/scro"],
    "ORDER.OSS020.VIEW": ["/soMonitoring"],
    "ORDER.OCS022.VIEW": ["/rece", "/placebuorder"],
    "ORDER.ONS010.VIEW": ["/placecustorder"],
    "ORDER.ONS010REGULAR.VIEW": ["/placecustorder-regular"],
    "ORDER.ONS010SPOT.VIEW": ["/placecustorder-spot"],
    "ORDER.ONS010SOWC.VIEW": ["/placecustorder-sowc"],
    "ORDER.OCS021.VIEW": ["/placecustorder-sowc/create"],
    "ORDER.ONS030.VIEW": ["/cro", "/pcwc"],
    "ORDER.ONS030ORDER.VIEW": ["/cro-order"],
    "ORDER.ONS030FORECAST.VIEW": ["/cro-forecast"],
    "ORDER.OCS027.VIEW": ["/repcwc", "/rc"],
    "LOGI.LDS010.VIEW": ["/di"],
    "LOGI.LDS010BU.VIEW": ["/diForBu"],
    "LOGI.LDS013.VIEW": ["/di/download"],
    "LOGI.LIS010.VIEW": ["/inbound"],
    "LOGI.LIS014.VIEW": ["/inbound/create"],
    "LOGI.LIS013.VIEW": ["/inbound/confirm"],
    "LOGI.LOS011.VIEW": ["/deliveryplan"],
    "LOGI.LOS020.VIEW": ["/outboundPrioritization"],
    "LOGI.LOS030.VIEW": ["/outbound"],
    "LOGI.LOS040.VIEW": ["/outboundToWIP"],
    "LOGI.LOS033.VIEW": ["/outbound/parts"],
    "LOGI.LOS034.VIEW": ["/outbound/create"],
    "ACCT.AIS010.VIEW": ["/gr"],
    "ACCT.AIS020.VIEW": ["/gi"],
    "ACCT.AIS060.VIEW": ["/ar", "/ap"],
    "LOGI.AIS030.VIEW": ["/expcustomsinvoice"],
    "LOGI.AIS040.VIEW": ["/impcustomsinvoice"],
    "LOGI.MTS010.VIEW": ["/shippingstatus"],
    "MT.MTS020.VIEW": ["/partsinventory"],
    "MT.MTS030.VIEW": ["/packageinventory"],
    "MT.MTS040.VIEW": ["/dowloadinbplan"],
    "MT.MTS050.VIEW": ["/dowloadOverallVisualisation"],
    "ACCS.CAS010.VIEW": ["/role"],
    "ACCS.CAS011.VIEW": ["/role/create"],
    "ACCS.CAS020.VIEW": ["/user"],
    "ACCS.CAS021.VIEW": ["/user/create"],
    "ACCS.CAS030.VIEW": ["/accessHistory"],
    "ACCS.CAS040.VIEW": ["/userActive"],
    "STCK.SMGTS110.VIEW": ["/weeklyRundown"],
    "STCK.SMGTS210.VIEW": ["/dailyRundownList"],
    "STCK.SMGTS211.VIEW": ["/weeklyRundown/dailyRundown", "/dailyRundownList/dailyRundown"],
    "STCK.VSRPS010.VIEW": ["/pokayoke"],
    "STCK.CSUGS130.VIEW": ["/project"],
    "STCK.CSUGS131.VIEW": ["/project/create"],
    "STCK.CSUGS120.VIEW": ["/cuDownloadUpload"],
    "STCK.SMGTS310.VIEW": ["/customerStockList"],
    "STCK.SMGTS410.VIEW": ["/stockAdjustmentList"],
    "STCK.SMGTS610.VIEW": ["/impInventoryHistoryList"],
    "STCK.CSUHS110.VIEW": ["/usageHistoryParentLandingpg"],
    "STCK.CSUHS120.VIEW": ["/usageHistory"],
    "STCK.CSUHS140.VIEW": ["/actualUsage"],
    "STCK.CSUHS150.VIEW": ["/customerActualUsage"],
    "STCK.MSOGS010.VIEW": ["/orderCalculationGrouping"],
    "STCK.MSOGS020.VIEW": ["/orderCalculationGroupingEhm"],
    "STCK.MSAUF010.VIEW": ["/alarmUsagePattern"],
    "STCK.MSAUF020SM.VIEW": ["/stockManagementMaster"],
    "STCK.MSAUF020OC.VIEW": ["/orderCalculationMaster"],
    "STCK.MSAUF020SMOC.VIEW": ["/stockManagementOrderCalcMaster"],
    "STCK.OCCLS013.VIEW": ["/orderCalculation/createCalculate", "/orderCalculationPNA/createCalculate", "/orderCalculationEhm/createCalculate"],
    "STCK.OCCLS010.VIEW": ["/orderCalculation"],
    "STCK.OCCLS010PNA.VIEW": ["/orderCalculationPNA"],
    "STCK.OCCLS010SMT.VIEW": ["//orderCalculationEhm"],
    "STCK.BTETL010.VIEW": ["/batch"],
    "TOOL.TOLS010.VIEW": ["/tools"],
    "LOGI.TDL010.VIEW": ["/todolist"],
    "TOOL.TDL011.VIEW": ["/todolist/create"],
    "INT.INT010.VIEW": ["/manageExternalData"],
    "INT.INT011.VIEW": ["/manageExternalData/create", "/manageExternalData/edit-:templateId"],
    "INT.INT020.VIEW": ["/myUpload"],
    "INT.INT030.VIEW": ["/integrationBatch"],
    "INT.INT040.VIEW": ["/externalOrderList"],
    "INT.INT050.VIEW": ["/bcro"],
    "INT.INT060.VIEW": ["/bccHistory"],
    "INT.INT070.VIEW": ["/comparisonTool"],
    "ORDER.ONS040.VIEW": ["/bro"],
    "ORDER.ONS040ORDER.VIEW": ["/bro-order"],
    "ORDER.ONS040FORECAST.VIEW": ["/bro-forecast"],
}
