import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { CoStatus } from "../enums/CoStatus"
import { DelayStatus } from "../enums/DelayStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { PoStatus } from "../enums/PoStatus"
import { SoStatus } from "../enums/SoStatus"

export type OrderListResult = Required & Partial<Optional>
type OrderListResultJson = JsonRequired & Partial<JsonOptional>
export const orderListResultFromJson: Project<OrderListResultJson, OrderListResult> = json => ({
    ...json,
    orderDate: dateFromJson(json.orderDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'orderDate'> {
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    shippingMode: ShippingMode,
    orderDate: Date,
    status: PoStatus | SoStatus | CoStatus,
    delayStatus: DelayStatus,
}

interface JsonRequired {
    orderId: number,
    orderNo: string,
}

interface JsonOptional {
    orderRefNo: string,
    customerId: number,
    customerCode: string,
    orderType: number,
    orderFrequency: number,
    contractNo: string,
    contractRouteCode: string,
    buyer: string,
    shippingMode: number,
    orderDate: string,
    status: number,
    orgStatus: number,
    delayStatus: number,
    reviseVersion: number,
    creditCheckFlag: number,
    forecastNum: number,
    groupId: number,
    groupNo: string,
    contractOrGroupNo: string
    sellerOrderNo: string
    buyerOrderNo: string
}
