import { DialogActions, DialogContent } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, EntriesItem, Form, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useDownloadOutboundToWIP, useDownloadOutboundToWIPById, useDownloadWIPStockAdjustHistory } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useUploadOutboundToWIP, useUploadWIPStockAdjust } from "../../../services/delivery/apis/deliveryUploadApi"
import { OutboundToWIPEntityList } from "../../../services/delivery/models/OutboundToWIPEntityList"
import { OutboundToWIPFactor } from "../../../services/delivery/models/OutboundToWIPFactor"
import { WIPAdjustFactor } from "../../../services/delivery/models/WIPAdjustFactor"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { applicationActions } from "../../Application/applicationSlice"
import { TnvCbds } from "../../../services/master/models/TnvCbds"

export interface LOS040PcUiProps {
    totalCount: number,
    data: OutboundToWIPEntityList[]
    search: (filters: OutboundToWIPFactor) => void,
    filters: OutboundToWIPFactor,
    setFilters: React.Dispatch<React.SetStateAction<OutboundToWIPFactor>>,
    customerList: TnmCustomer[],
    supplierList: TnvCbds[],
}

export const LOS040PcUi = (props: LOS040PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, customerList, supplierList }: LOS040PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([]);

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 250 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 250 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 },
        { field: 'expRegion', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
        { field: 'outboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.outboundDate' }), width: 150 },
        { field: 'outboundQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 150 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList, supplierList }), [filters, setFilters, search, customerList, supplierList])
    const actionPropsDownload = useMemo(() => ({ filters, selections, customerList }), [filters, selections, customerList])
    const actionPropsUpload = useMemo(() => ({ search, filters }), [filters, search])
    const getRowId = useCallback((row: any) => row.outboundToWipId, []);

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionPropsUpload} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionPropsDownload} />
        <ColumnFreeze />
        <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: OutboundToWIPFactor,
    setFilters: React.Dispatch<React.SetStateAction<OutboundToWIPFactor>>,
    search: (filters: OutboundToWIPFactor) => void,
    customerList: TnmCustomer[],
    supplierList: TnvCbds[],
}) => {
    const { filters, setFilters, search, customerList, supplierList } = props
    const clear = useCallback((filters: OutboundToWIPFactor) => {
        return { page: filters.page }
    }, [])
    const outboundDateGetValue = useCallback((filters: OutboundToWIPFactor) => {
        return [filters.outboundDateStart ?? null, filters.outboundDateEnd ?? null]
    }, [])
    const outboundDateMapValue = useCallback((filters: OutboundToWIPFactor, value: any) => {
        return { ...filters ?? {}, outboundDateStart: value[0], outboundDateEnd: value[1] }
    }, [])

    const filterCounter = useCallback((filters: OutboundToWIPFactor) => {
        return [
            (filters.outboundDateStart || filters.outboundDateEnd) ? [filters.outboundDateStart, filters.outboundDateEnd] : [],
            filters.partsNo,
            filters.customerCodes,
            filters.contractNo,
            filters.unitPartsNo,
            filters.supplierCodes,
            filters.backNo,
            filters.expCountrys,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.customerCode, m.customerCode] as [string, string]) : [], [customerList])
    const supplierMap = useMemo(() => supplierList ? supplierList.map(m => [m.cbdsCode, m.cbdsCode] as [string, string]) : [], [supplierList])

    const regionList = useMemo(() => supplierList ? supplierList.map(m => m.regionCode as string) : [], [supplierList])
    const distinctRegionList = Array.from(new Set(regionList)).sort();
    const expRegionMap = useMemo(() => distinctRegionList ? distinctRegionList.map(m => [m, m] as [string, string]) : [], [distinctRegionList])

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <EntriesItem field="customerCodes" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customersMap} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field="unitPartsNo" label={intl.formatMessage({ id: 'field.unitPartsNo' })} />
        <EntriesItem field="supplierCodes" label={intl.formatMessage({ id: 'field.supplierCode' })} entries={supplierMap} />
        <StringItem field="backNo" label={intl.formatMessage({ id: 'field.backNo' })} />
        <EntriesItem field="expCountrys" label={intl.formatMessage({ id: 'field.expCountry' })} entries={expRegionMap} />
        <DateRangeItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })} getValue={outboundDateGetValue} mapValue={outboundDateMapValue} />
    </FilterToolbarItem>
}

const DownloadAction = ({ filters, selections, customerList }: { filters: OutboundToWIPFactor, selections: number[], customerList: TnmCustomer[] }) => {
    const intl = useIntl()
    const downloadApi = useDownloadOutboundToWIP()
    const downloadBySelectionApi = useDownloadOutboundToWIPById()
    const downloadWIPStockAdjustHistory = useDownloadWIPStockAdjustHistory()
    const [open, setOpen] = useState<boolean>(false)
    const onClose = useCallback(() => { setOpen(false) }, [])
    const [data, setData] = useState<WIPAdjustFactor>({})

    const downloadOutboundToWIP = useCallback(() => {
        if (selections.length <= 0) {
            downloadApi(Objects.delete(filters, 'page'))
        } else {
            downloadBySelectionApi({ outboundToWipIds: selections })
        }
    }, [downloadApi, downloadBySelectionApi, filters, selections])

    const downloadAdjustment = useCallback(() => {
        downloadWIPStockAdjustHistory(data)
        setOpen(false)
    }, [data, downloadWIPStockAdjustHistory, setOpen])

    const openDownloadPopUp = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.customerCode, m.customerCode] as [string, string]) : [], [customerList])
    const adjustDateGetValue = useCallback((filters: WIPAdjustFactor) => {
        return [filters.adjustDateStart ?? null, filters.adjustDateEnd ?? null]
    }, [])
    const adjustDateMapValue = useCallback((filters: WIPAdjustFactor, value: any) => {
        return { ...filters ?? {}, adjustDateStart: value[0], adjustDateEnd: value[1] }
    }, [])

    return <>
        <DownloadGroupedToolbarAction >
            {onClose => <>
                <GroupedCallbackItem access='LOGI.LOS040.DOWNLOADOUTBOUND' label={<FormattedMessage id="downloadOutboundToWIP" />} callback={downloadOutboundToWIP} onClose={onClose} />
                <GroupedCallbackItem access='LOGI.LOS040.DOWNLOADADJUST' label={<FormattedMessage id="downloadWipAdjustHistory" />} callback={openDownloadPopUp} onClose={onClose} />
            </>}
        </DownloadGroupedToolbarAction>
        <DarkDialog keepMounted={false} maxWidth={"lg"} open={open} onClose={onClose} >
            <DialogHeader ><FormattedMessage id="Select Download Condition" /></DialogHeader>
            <DialogContent style={{ height: 420 }}>
                <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={1}>
                    <EntriesItem field="customerCodes" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customersMap} />
                    <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
                    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                    <StringItem field="unitPartsNo" label={intl.formatMessage({ id: 'field.unitPartsNo' })} />
                    <DateRangeItem field="adjustDate" label={intl.formatMessage({ id: 'adjustedDate' })} getValue={adjustDateGetValue} mapValue={adjustDateMapValue} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                <DownloadCallbackCardAction callback={downloadAdjustment} title={<FormattedMessage id="download" />} />
            </DialogActions>
        </DarkDialog >
    </>
}


const UploadAction = ({ search, filters }: { search: (filters: OutboundToWIPFactor) => void, filters: OutboundToWIPFactor }) => {
    const uploadOutboundToWIPApi = useUploadOutboundToWIP();
    const uploadWIPStockAdjust = useUploadWIPStockAdjust();
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const upload = useCallback((file: File) => {
        uploadOutboundToWIPApi({ file, isConfirm: false }, { serialized: true, silent: true }).then(warningMessages => {
            if (warningMessages.length === 0) {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /lcbm-logistics-api/upload/outboundtowip' }),
                    messages: [{ code: 'notice.success' }],
                }))
                search(filters)
            } else {
                const functionId = functionStore.register(() => {
                    uploadOutboundToWIPApi({ file, isConfirm: true }, { serialized: true }).then(() => {
                        search(filters)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: intl.formatMessage({ id: 'confirm' }),
                    messages: warningMessages,
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        })
    }, [dispatch, filters, functionStore, intl, search, uploadOutboundToWIPApi])
    const uploadOuboundToWIPMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files === null || files.length === 0) {
                return
            } else {
                upload(files[0])
            }
        })
    }, [upload])

    const uploadAdjustMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files === null || files.length === 0) return
            uploadWIPStockAdjust({ file: files[0], isConfirm: false }, { serialized: true })
        })
    }, [uploadWIPStockAdjust])

    return <UploadGroupedToolbarAction >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem access='LOGI.LOS040.UPLOADOUTBOUND' label={<FormattedMessage id="uploadOutboundToWIP" />} callback={() => uploadOuboundToWIPMethod(popupUpload)} onClose={onClose} />
            <GroupedCallbackItem access='LOGI.LOS040.UPLOADADJUST' label={<FormattedMessage id="uploadWipAdjust" />} callback={() => uploadAdjustMethod(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

