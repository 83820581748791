import { Button, Card, CardContent, MenuItem, MenuList, Popover, PopoverProps, Tooltip, useTheme, withStyles } from "@material-ui/core"
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { AutoCompleteItem, DateItem, EntryItem, Form, Message, NumberItem, StringItem, TimeItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records, usePopover } from "@rithe/utils"
import moment from "moment"
import React, { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { Access } from "../../../components/Access/Access"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { BlackSimpleCard } from "../../../components/Card/BlackSimpleCard"
import { BlackSimpleCardContent } from "../../../components/Card/BlackSimpleCardContent"
import { BlackSimpleCardHeader } from "../../../components/Card/BlackSimpleCardHeader"
import { CardPanel } from "../../../components/Card/CardPanel"
import { CardTab } from "../../../components/Card/CardTab"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { TabsCard } from "../../../components/Card/TabsCard"
import { TabsCardContent } from "../../../components/Card/TabsCardContent"
import { TabsCardHeader } from "../../../components/Card/TabsCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { DottedDivider } from "../../../components/Divider/DottedDivider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { useDownloadSimpleOutboundFromScreen } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useSaveAndSubmitSimpleOutbound, useSaveSimpleOutbound } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useUploadSimpleOutboundForCreate } from "../../../services/delivery/apis/deliveryUploadApi"
import { DownloadOutBoundPartsInfo } from "../../../services/delivery/models/DownloadOutBoundPartsInfo"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { ContainerInfo, InnerPackageInfo, OutboundCreateFactor, OutboundPartsInfo, OuterPackageInfo } from "../../../services/delivery/models/OutboundCreateFactor"
import { PendingOutboundRollPackage } from "../../../services/delivery/models/PedingOutboundRollPackageInfo"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyCode } from "../../../utils/ApplicationUtils"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { DownloadSimpleOutboundForm } from "./DownloadSimpleOutboundForm"
import { defaultContainers } from "./LOS035"

interface LOS035PcUiProps {
    data: OutboundCreateFactor,
    setData: Dispatch<SetStateAction<OutboundCreateFactor>>,
    containers: ContainerInfo[],
    setContainers: Dispatch<SetStateAction<ContainerInfo[]>>,
    hugeData: boolean,
    setHugeData: Dispatch<SetStateAction<boolean>>,
    buyerList: Pair[],
    receiverList: Pair[],
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    dbRollPackageList: PendingOutboundRollPackage[],
    containerTypes: TnmContainerType[],
    restPackageList: (buyer: string) => void,
    resetNotYetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[]
}

export const LOS035PcUi = (props: LOS035PcUiProps) => {
    const { buyerList, receiverList,
        receiverOuterPackages, dbRollPartList, dbRollPackageList, containerTypes, 
        restPackageList, resetNotYetDeliveryQty,
        data, setData, containers, setContainers, hugeData, setHugeData } = props
    const intl = useIntl()

    const [screenSelectPkgIds, setScreenSelectPkgIds] = useState<Pair[]>([])
    return <View actions={[
        <SaveAction data={data} containers={containers} />,
        <SaveAndSubmitAction data={data} containers={containers} />
    ]}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                step
                title={intl.formatMessage({ id: 'step1OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step1OfCreateOutboundSub' })}
                actions={[
                    <Step1DownloadAction dbRollPartList={dbRollPartList} dbRollPackageList={dbRollPackageList} buyerList={buyerList} receiverList={receiverList} outbound={data} containers={containers} screenSelectPkgIds={screenSelectPkgIds}/>,
                    <Step1UploadAction resetNotYetDeliveryQty={resetNotYetDeliveryQty} restPackageList={restPackageList} setData={setData} setContainers={setContainers} setHugeData={setHugeData}/>,
                ]}
            />
            <SectionCardContent>
                <Step1DownloadUploadCard />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                step
                title={intl.formatMessage({ id: 'step2OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step2OfCreateOutboundSub' })}
            />
            <SectionCardContent>
                <OutboundBasicView data={data} setData={setData} setContainers={setContainers} buyerList={buyerList} receiverList={receiverList} restPackageList={restPackageList} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                step
                title={intl.formatMessage({ id: 'step3OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step3OfCreateOutboundSub' })}
            />
            <SectionCardContent>
                { hugeData ? <ContainerPackageGridView containers={containers} setContainers={setContainers} containerTypes={containerTypes} />
                    : <ContainerPackageView dbRollPartList={dbRollPartList} containers={containers} setContainers={setContainers} containerTypes={containerTypes} receiverOuterPackages={receiverOuterPackages} setScreenSelectPkgIds={setScreenSelectPkgIds}/>
                }
            </SectionCardContent>
        </SectionCard>
    </View>
}

const SaveAction = ({ data, containers }: {
    data: OutboundCreateFactor,
    containers: ContainerInfo[]
}) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const saveOutbound = useSaveSimpleOutbound()
    const [disabled, setDisabled] = useState<boolean>(false)

    const saveOutboundMethod = useCallback(() => {
        setDisabled(true)
        saveOutbound({ ...data, containers: containers }, { serialized: true, silent: true }).then((outboundNo: string) => {
            dispatch(applicationActions.pushSuccess({
                title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/outbound/save' }),
                messages: [{ code: 'notice.success' }],
            }))
            if (!data.outboundNo && outboundNo) {
                navigate("/outbound/edit-simple-" + outboundNo)
            }
        }).finally(() => {
            setDisabled(false)
        })
    }, [saveOutbound, data, containers, dispatch, intl, navigate])
    return <SaveCallbackViewAction outlined access="LOGI.LOS035.SAVE" callback={saveOutboundMethod} disabled={disabled} />
}

const SaveAndSubmitAction = ({ data, containers }: {
    data: OutboundCreateFactor,
    containers: ContainerInfo[]
}) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const navigate = useNavigate()
    const saveAndSubmitOutbound = useSaveAndSubmitSimpleOutbound()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    const submitActionMethod = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            saveAndSubmitOutbound({ ...data, containers: containers }, { serialized: true, silent: true }).then(warningMessages => {
                if (warningMessages.length === 0) {
                    dispatch(applicationActions.pushSuccess({
                        title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/createOutbound' }),
                        messages: [{ code: 'notice.success' }],
                    }))
                    navigate("/outbound")
                } else {
                    // if failed to call portcast, push info
                    const message = warningMessages.find(m => m.code === "i0004")
                    if (message) {
                        dispatch(applicationActions.pushInfo({
                            title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/listtracking' }),
                            messages: [{ code: message.code, args: message.args }],
                        }))
                        navigate("/outbound")
                    } else {
                        const functionId = functionStore.register(() => {
                            saveAndSubmitOutbound({ ...data, isConfirm: true, containers: containers }, { serialized: true }).then(messages => {
                                if (messages.filter(m => m.code !== "c0025").length === 0) {
                                    dispatch(applicationActions.pushSuccess({
                                        title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/simple-outbound/saveAndSubmit' }),
                                        messages: [{ code: 'c0026' }],
                                    }))
                                }
                                navigate("/outbound")
                            })
                        })
                        dispatch(applicationActions.pushWarning({
                            title: intl.formatMessage({ id: 'confirm' }),
                            messages: warningMessages,
                            actions: [{
                                label: 'CANCEL'
                            }, {
                                functionId,
                                label: 'CONFIRM',
                            }]
                        }))
                    }
                }

            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, saveAndSubmitOutbound, data, containers, intl, navigate])

    // comp
    return <SubmitCallbackViewAction access="LOGI.LOS035.SUBMIT" callback={submitActionMethod} disabled={disabled} />
}

const Step1DownloadAction = ({ buyerList, receiverList, dbRollPartList, dbRollPackageList, outbound, containers, screenSelectPkgIds }: {
    dbRollPartList: InnerPartsResult[],
    dbRollPackageList: PendingOutboundRollPackage[],
    buyerList: Pair[],
    receiverList: Pair[],
    outbound: OutboundCreateFactor,
    containers: ContainerInfo[],
    screenSelectPkgIds: Pair[]
}) => {

    // use popover
    const { open, anchorEl, onOpen, onClose } = usePopover()
    const [pop, setPop] = useState<boolean>(false)
    const downloadApi = useDownloadSimpleOutboundFromScreen()
    
    const openDownloadForm = useCallback(() => {
        setPop(true)
    }, [])

    //companyType
    const shipper = useGetCompanyCode()
    const downloadFromScreen = useCallback(() => {
        // selections
        const pkgIds: number[] = screenSelectPkgIds.map(p => p.second as number)
        downloadApi(prepareDownloadFromScreen(outbound, containers, buyerList, receiverList, shipper ?? '', pkgIds ))
    }, [buyerList, containers, downloadApi, outbound, receiverList, screenSelectPkgIds, shipper])
    
    const downloadBlankFile = useCallback(() => downloadApi([]), [downloadApi])
    return <>
        <Button onClick={onOpen} variant="outlined" style={{ marginLeft: 8, minWidth: 230 }} color="secondary">
            <FormattedMessage id="DownloadOutboundForm" />
        </Button >
        <PopoverMenu open={open} anchorEl={anchorEl} onClose={onClose} >
            <MenuList>
                {<Access access="LOGI.LOS035.DOWNLOAD">
                    <Tooltip title={<FormattedMessage id="downloadFromScreen" />}>
                        <MenuItem onClick={() => {
                            downloadFromScreen()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadFromScreen" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadBlankOutbound" />}>
                        <MenuItem onClick={() => {
                            downloadBlankFile()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBlankOutbound" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadBySelectTextiles" />}>
                        <MenuItem onClick={() => {
                            openDownloadForm()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBySelectTextiles" />
                        </MenuItem>
                    </Tooltip>
                </Access>}
            </MenuList>
        </PopoverMenu>
        <DownloadSimpleOutboundForm open={pop} setOpen={setPop} dbRollPartList={dbRollPartList} dbRollPackageList={dbRollPackageList} buyerList={buyerList} receiverList={receiverList} outbound={outbound} containers={containers} />
    </>
}

const Step1UploadAction = ({ setData, setContainers, resetNotYetDeliveryQty, restPackageList, setHugeData }: {
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    setHugeData: React.Dispatch<React.SetStateAction<boolean>>,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    restPackageList: (buyer: string) => void,
    resetNotYetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[],
}) => {
    const uploadApi = useUploadSimpleOutboundForCreate()
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadApi({ file: files[0] }, { serialized: true }).then((result: OutboundCreateFactor) => {
            if (result) {
                setData(data => ({ ...result, containers: undefined, outboundNo: data.outboundNo }))
                setContainers(resetNotYetDeliveryQty(result.containers ?? defaultContainers))
                // set parts and set net
                restPackageList(result.buyer)

                result.containers?.forEach(container => {
                    setHugeData(pre => pre ? pre : container.outerPackageList != null && container.outerPackageList?.length > 30)
                })
            }
        })
    }, [resetNotYetDeliveryQty, restPackageList, setContainers, setData, setHugeData, uploadApi])
    return <UploadCallbackCardAction access="LOGI.LOS035.UPLOAD" callback={uploadFrom} title={<FormattedMessage id="UploadOutboundForm" />} />
}

const Step1DownloadUploadCard = memo((props: {}) => {
    return <></>
})


const OutboundBasicView = ({ data, setData, restPackageList, receiverList, buyerList, setContainers }: {
    data: OutboundCreateFactor,
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    buyerList: Pair[],
    receiverList: Pair[],
    restPackageList: (buyer: string) => void,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {
    const intl = useIntl()
    const receivers = useMemo(() => receiverList.map(m => [m.first, m.second] as [string, string]), [receiverList])
    const buyers = useMemo(() => Arrays.distinct(buyerList.map(m => [m.first, m.second] as [string, string])), [buyerList])
    const [messages, setMessages] = useState<Message[]>([])
    const checkFields = useMemo(() => ({
        receiver: { labelId: 'receiverCode', required: true, length: { max: 30 } },
        outboundDate: { labelId: 'field.outboundDate', required: true },
        outboundRefNo: { labelId: 'field.outboundRefNo', required: true, length: { max: 30 } },
        outboundType: { labelId: 'field.outboundType', required: true },
        bookingNo: { labelId: 'field.bookingNo', required: false },
    }), [])
    const filedCheck = useFieldChecker(checkFields, setMessages)
    const mapBuyerValue = useCallback((data, value) => {
        // do warning
        restPackageList(value)
        data.buyer !== value && setContainers(defaultContainers)
        return { ...data, buyer: value }
    }, [restPackageList, setContainers])

    return <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
            <EntryItem field="buyer" required label={intl.formatMessage({ id: 'buyer' })} entries={buyers} mapValue={mapBuyerValue} />
            <EntryItem field="receiver" required label={intl.formatMessage({ id: 'receiverCode' })} entries={receivers} />
            <StringItem field="outboundRefNo" required label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
            <CodeItem field="outboundType" required label={intl.formatMessage({ id: 'field.outboundType' })} code={CodeCategory.UploadOutboundType} />
            <DateItem field="outboundDate" required label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
            <TimeItem field="outboundTime" label={intl.formatMessage({ id: 'field.outboundTime' })}></TimeItem>
            <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
            <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <StringItem field="bookingNo" label={intl.formatMessage({ id: 'field.bookingNo' })} />
        </Form>
        
}

const ContainerPackageView = ({ containers, setContainers, containerTypes, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const theme = useTheme()

    return <>
        <PartsSummaryView containers={containers} />
        <ContainerPackagesSummaryView containers={containers} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <ContainerPackagesTabView dbRollPartList={dbRollPartList} containers={containers} setContainers={setContainers} containerTypes={containerTypes} receiverOuterPackages={receiverOuterPackages} setScreenSelectPkgIds={setScreenSelectPkgIds} />
    </>
}

const PartsSummaryView = ({ containers }: {
    containers: ContainerInfo[]
}) => {

    const intl = useIntl()
    const theme = useTheme()
    const partsSummaryList = useMemo(() => {
        const partsList = containers.flatMap(f => f.outerPackageList ?? []).flatMap(f => f.innerPackageList ?? []).flatMap(f => f.partsList ?? [])
        const partsNoList = Arrays.distinct(partsList.map(m => m.partsNo))
        return partsNoList.map((partsNo, index) => {
            const filterList = partsList.filter(f => f.partsNo === partsNo)
            return {
                rowNo: index + 1,
                partsNo: partsNo,
                partsName: filterList[0].sellerPartsName,
                qty: filterList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0),
            }
        })
    }, [containers])
    const columns = useMemo(() => [
        { field: 'rowNo', dataTypeName: 'number', title: intl.formatMessage({ id: 'rowNo' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 400 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 400 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalQuantity' }), width: 300 }
    ], [intl])

    return <div style={{ width: '100%', height: 300, marginBottom: theme.spacing(2) }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsSummaryList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={15} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}

const ContainerPackagesSummaryView = ({ containers }: {
    containers: ContainerInfo[]
}) => {
    const intl = useIntl()
    const containerPackageSummary = useMemo(() => {
        const packageList = containers.flatMap(m => m.outerPackageList ?? [])
        return {
            numOfContainer: containers.length,
            contentOfContainer: Arrays.distinct(containers.map(m => m.commodityType ?? '')).join(','),
            typeOfContainer: Arrays.distinct(containers.map(m => m.containerType ?? '')).join(','),
            totalGWOfContainer: containers.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
            numOfPackage: packageList.length,
            totalM3OfPackage: packageList.map(m => m.m3 ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
            totalNWOfPackage: packageList.map(m => m.netWeight ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
            totalGWOfPackage: packageList.map(m => m.grossWeight ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
        }
    }, [containers])

    return <Card>
        <CardContent>
            <Form data={containerPackageSummary} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
                <GridItem columnSpan={2}><SectionTitle size="small">{intl.formatMessage({ id: 'containerInfo' })}</SectionTitle></GridItem>
                <NumberItem field="numOfContainer" readonly label={intl.formatMessage({ id: 'numOfContainer' })} />
                <StringItem field="contentOfContainer" readonly label={intl.formatMessage({ id: 'contentOfContainer' })} />
                <StringItem field="typeOfContainer" readonly label={intl.formatMessage({ id: 'typeOfContainer' })} />
                <NumberItem field="totalGWOfContainer" readonly label={intl.formatMessage({ id: 'totalGWOfContainer' })} />

                <GridItem columnSpan={2}><SectionTitle size="middle">{intl.formatMessage({ id: 'packageInfo' })} </SectionTitle></GridItem>
                <NumberItem field="numOfPackage" readonly label={intl.formatMessage({ id: 'numOfPackage' })} />
                <NumberItem field="totalM3OfPackage" readonly label={intl.formatMessage({ id: 'totalM3OfPackage' })} />
                <NumberItem field="totalNWOfPackage" readonly label={intl.formatMessage({ id: 'totalNWOfPackage' })} />
                <NumberItem field="totalGWOfPackage" readonly label={intl.formatMessage({ id: 'totalGWOfPackage' })} />
            </Form>
        </CardContent>
    </Card>
}

const ContainerPackagesTabView = ({ containers, setContainers, containerTypes, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const dispatch = useDispatch()
    const [containerIndex, setContainerIndex] = useState<number>(0)

    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])

    const closeCommon = (tabId: number) => {
        if (tabId === (containers.length - 1) && tabId > 0) {
            setContainerIndex(containers.length - 2)
        }
        setContainers(containers => {
            if (containers.length > 1) {
                return containers.filter((_, index) => index !== tabId)
            } else {
                dispatch(applicationActions.pushError({ title: { code: 'remove' }, messages: { code: 'w0362' } }))
                return containers
            }
        })
    }

    return <TabsCard>
        <TabsCardHeader selectedValue={containerIndex} onSelectedValueChange={onSelectedValueChange} actions={[<AddContainerAction setContainers={setContainers} />]}>
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} onClose={closeCommon} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <CardPanel value={containerIndex}>
                <ContainerPackageCardView
                    containerIndex={containerIndex}
                    container={containers[containerIndex]}
                    setContainers={setContainers}
                    containerTypes={containerTypes}
                    receiverOuterPackages={receiverOuterPackages}
                    dbRollPartList={dbRollPartList}
                    setScreenSelectPkgIds={setScreenSelectPkgIds}
                />
            </CardPanel>
        </TabsCardContent>
    </TabsCard>
}

const AddContainerAction = ({ setContainers }: {
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const callback = useCallback(() => {
        setContainers(containers => [...containers, ...defaultContainers])
    }, [setContainers])
    return <CallbackCardAction callback={callback} title={<FormattedMessage id="addContainer" />} />
}

const ContainerPackageCardView = memo(({ container, setContainers, containerIndex, containerTypes, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const { outerPackageList } = container

    return <>
        <ContainerInfoView container={container} containerIndex={containerIndex} setContainers={setContainers} containerTypes={containerTypes} />
        <OutPackageListView dbRollPartList={dbRollPartList} containerIndex={containerIndex} receiverOuterPackages={receiverOuterPackages} outerPackageList={outerPackageList} setContainers={setContainers} setScreenSelectPkgIds={setScreenSelectPkgIds} />
    </>
})

const ContainerInfoView = memo(({ container, containerIndex, setContainers, containerTypes }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
}) => {

    const suggestions = containerTypes.map(m => m.containerTypeCode ?? '')
    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<ContainerInfo>>>(nextDraftDataFunc => {
        setContainers(containers => {
            const currentContainer = containers[containerIndex]
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(currentContainer) : nextDraftDataFunc
            return containers.map((m, index) => index === containerIndex ? draftData : m)
        })
    }, [containerIndex, setContainers])

    const getNumberOfCarton = useCallback(() => {
        return container.outerPackageList?.length ?? 0
    }, [container.outerPackageList?.length])

    const getPartsQty = useCallback(() => {
        const partsList = (container.outerPackageList ?? []).flatMap(m => m.innerPackageList ?? []).flatMap(m => m.partsList)
        return partsList.map(m => m.qty ?? 0).reduce((a, b) => floatAdd(a + b, 5), 0)
    }, [container.outerPackageList])

    return <Form data={container} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" >
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="containerInfo" /></SectionTitle></GridItem>
        <AutoCompleteItem field="containerType" label={<FormattedMessage id='typeOfContainer' />} suggestions={suggestions} />
        <NumberItem field="numberOfCarton" readonly label={<FormattedMessage id='numberOfCarton' />} getValue={getNumberOfCarton} />
        <StringItem field="commodityType" label={<FormattedMessage id='typeOfCommodities' />} />

        <NumberItem field="qty" readonly label={<FormattedMessage id='quantity' />} getValue={getPartsQty} />
        <StringItem field="containerNo" label={<FormattedMessage id='field.containerNo' />} />
        <StringItem field="sealNo" label={<FormattedMessage id='field.sealNo' />} />

        <NumberItem field="m3" label={<FormattedMessage id='containerM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='containerNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='containerGW' />} />
    </Form>
})

const floatAdd = (float: number, digit: number) => {
    var m = Math.pow(10, digit)
    return Math.round(float * m) / m 
}

const OutPackageListView = memo(({ outerPackageList, containerIndex, setContainers, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    containerIndex: number,
    outerPackageList?: OuterPackageInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const theme = useTheme()

    const clickAddOuterPackage = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const newContainer = {
                ...container, outerPackageList: [...packageList, { outerPackageNo: '', outboundByPackage: false, innerPackageList: [] }]
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, setContainers])

    return <div style={{ width: '100%', paddingTop: theme.spacing(2) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withInContainerOuterPackage" /></SectionTitle></GridItem>
        <GridItem style={{ border: 'dotted', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
            <Button onClick={clickAddOuterPackage} style={{ justifyContent: 'start', paddingLeft: theme.spacing(2), width: '100%', fontSize: 14, fontWeight: 'bold' }}><FormattedMessage id="addOuterPackage2" /></Button>
        </GridItem>
        {outerPackageList?.map((outerPackage, index) => {
            return <GridItem>
                <OutPackageInfoCardView
                    containerIndex={containerIndex}
                    outerPackageIndex={index}
                    outerPackage={outerPackage}
                    setContainers={setContainers}
                    receiverOuterPackages={receiverOuterPackages}
                    dbRollPartList={dbRollPartList}
                    setScreenSelectPkgIds={setScreenSelectPkgIds}
                />
            </GridItem>
        })}
    </div>
})

const OutPackageInfoCardView = memo(({ outerPackage, containerIndex, outerPackageIndex, setContainers, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    outerPackage: OuterPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const { outerPackageNo, innerPackageList } = outerPackage
    const removeOuterPackage = useCallback(() => {
        setContainers(containers => {
            return containers.map((m, index) => index === containerIndex ? { ...m, outerPackageList: m.outerPackageList?.filter((_, pkgIdx) => pkgIdx !== outerPackageIndex) } : m)
        })
        setScreenSelectPkgIds(ids => {
            return ids.filter(id => id.first !== outerPackage.outerPackageNo)
        })
    }, [containerIndex, outerPackage.outerPackageNo, outerPackageIndex, setContainers, setScreenSelectPkgIds])

    return <BlackSimpleCard allowCollapse>
        <BlackSimpleCardHeader clear={removeOuterPackage} title={'Outer Package ' + (outerPackageIndex + 1) + ': ' + (outerPackageNo && outerPackageNo !== '' ? outerPackageNo : '')} />
        <BlackSimpleCardContent>
            <OutPackageBasicInfoView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                outerPackage={outerPackage}
                setContainers={setContainers}
                receiverOuterPackages={receiverOuterPackages}
                dbRollPartList = {dbRollPartList}
                setScreenSelectPkgIds={setScreenSelectPkgIds}
            />
            <WithInOutboundPackagePartsView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageList={innerPackageList}
                setContainers={setContainers}
            />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const OutPackageBasicInfoView = memo(({ outerPackage, containerIndex, outerPackageIndex, setContainers, receiverOuterPackages, dbRollPartList, setScreenSelectPkgIds }: {
    outerPackage: OuterPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    receiverOuterPackages: PendingOutboundRollPackage[],
    dbRollPartList: InnerPartsResult[],
    setScreenSelectPkgIds: Dispatch<SetStateAction<Pair[]>>,
}) => {

    const pkgSuggestions = useMemo(() => Arrays.distinct(receiverOuterPackages.map(m => m.outerPackageNo as string)), [receiverOuterPackages])
    const searchPackage = useCallback((packageNo) => {
        if (receiverOuterPackages.some(s => s.outerPackageNo === packageNo)) {
            const selectedPkg = receiverOuterPackages.find(p => p.outerPackageNo === packageNo)
            if (selectedPkg !== null) {
                setScreenSelectPkgIds(ids => {
                    if (selectedPkg?.inbPkgId && !ids.some(id => id.second === selectedPkg.inbPkgId)) {
                        ids.push({ first: packageNo, second: selectedPkg.inbPkgId })
                    }
                    return ids
                })
                const outerPkg: OuterPackageInfo = {
                    productionDate: selectedPkg?.productionDate,
                    grossWeight: selectedPkg?.grossWeightOuter,
                    netWeight: selectedPkg?.netWeightOuter,
                    m3: selectedPkg?.m3Outer,
                    palletNo: selectedPkg?.palletNo,
                    outerPackageNo: packageNo,
                    outerPackageType: selectedPkg?.outerPackageType,
                    originalOutboundRefNo: selectedPkg?.originalOutboundRefNo,
                    outboundByPackage: true,
                    innerPackageList: [{
                        isBlank: true,
                        partsList: dbRollPartList.filter(p => selectedPkg?.sellerPartsNo === p.sellerPartsNo).map(p => {
                            return {
                                ...p,
                                qty: selectedPkg?.qty,
                                notYetDeliveryQty: selectedPkg?.qty
                            }
                        })
                    }]
                }
                setContainers(containers => {
                    const container = containers[containerIndex]
                    const packageList = container.outerPackageList ?? []
                    const newContainer = {
                        ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? outerPkg : m)
                    }
                    return containers.map((m, index) => index === containerIndex ? newContainer : m)
                })
            }
        }
    }, [containerIndex, dbRollPartList, outerPackageIndex, receiverOuterPackages, setContainers, setScreenSelectPkgIds])
    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<OuterPackageInfo>>>(nextDraftDataFunc => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const orgPackageData = packageList[outerPackageIndex]
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(orgPackageData) : nextDraftDataFunc
            // do check if package is change 
            if (draftData.outerPackageNo !== orgPackageData.outerPackageNo) {
                searchPackage(draftData.outerPackageNo)
            }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? draftData : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, outerPackageIndex, searchPackage, setContainers])

    return <Form data={outerPackage} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" >
        <AutoCompleteItem field="outerPackageNo" label={<FormattedMessage id='outerPackageNo' />} suggestions={pkgSuggestions} />
        <StringItem field="palletNo" label={<FormattedMessage id='palletNo' />} />
        <StringItem field="outerPackageType" label={<FormattedMessage id='outerPackageType' />} readonly={true}/>
        <DateItem field="productionDate" label={<FormattedMessage id='productionDate' />} />

        <NumberItem field="m3" label={<FormattedMessage id='packageM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='packageNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='packageGW' />} />
    </Form>
})

const WithInOutboundPackagePartsView = memo(({ innerPackageList, containerIndex, outerPackageIndex, setContainers, }: {
    innerPackageList: InnerPackageInfo[],
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const theme = useTheme()
    const partsList = useMemo(() => {
        const innerPackage = innerPackageList.find(f => f.isBlank === true)
        return innerPackage?.partsList ?? []
    }, [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageParts" /></SectionTitle></GridItem>
        <GridItem >
            <PartsInfoListView
                innerPackageIndex={0}
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                partsList={partsList}
                setContainers={setContainers}
            />
        </GridItem>
    </div>
})

const PartsInfoListView = memo(({ partsList, containerIndex, outerPackageIndex, innerPackageIndex, setContainers }: {
    partsList: OutboundPartsInfo[],
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 280 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 220 },
        { field: 'sellerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'quantity' }), width: 150 },
        { field: 'notYetDeliveryQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'availableDeliveryQty' }), width: 250 },
    ], [intl])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('qty')).map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const partsList = innerPackageInfo.partsList
            const newPartsList = partsList.map(item => item.soDetailId === row.soDetailId ? row as OutboundPartsInfo : item)
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? { ...innerPackageInfo, partsList: newPartsList } : m)
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
        return true
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])

    const actionProps = useMemo(() => ({ containerIndex, outerPackageIndex, innerPackageIndex, setContainers }), [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])

    const getRowId = useCallback((row: any) => row.soDetailId, [])

    return <div style={{ width: '100%', height: 300 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <Data rows={partsList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName', 'sellerBackNo', 'colorCode']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Editing
                enableInlineEdit={true}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Sorting />
            <Filtering />
            <Action width={80} />
        </DataGrid>
    </div>
})

const DeleteRowAction = ({ tableRow, containerIndex, outerPackageIndex, innerPackageIndex, setContainers }: DataGridRowActionProps & {
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const deleteAction = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const partsList = innerPackageInfo.partsList
            const newPartsList = partsList.filter(item => item.soDetailId !== tableRow.row?.soDetailId)
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? { ...innerPackageInfo, partsList: newPartsList } : m).filter(ip => !ip.isBlank || (ip.partsList && ip.partsList.length > 0))
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers, tableRow.row?.soDetailId])

    return <DeleteCallbackRowAction tableRow={tableRow} callback={deleteAction} />
}

const PopoverMenu = withStyles(theme => ({
    root: {
    },
    paper: {
        minWidth: 230,
        background: theme.palette.background.popover.main,
        color: theme.palette.getContrastText(theme.palette.background.popover.main),
        borderRadius: theme.spacing(1.5),
    },
}))((props: PopoverProps) => <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    {...props}
/>)

const ContainerPackageGridView = ({ containers, setContainers, containerTypes }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [containerIndex, setContainerIndex] = useState<number>(0)
    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])
    const closeCommon = (tabId: number) => {
        if (tabId === (containers.length - 1) && tabId > 0) {
            setContainerIndex(containers.length - 2)
        }
        setContainers(containers => {
            if (containers.length > 1) {
                return containers.filter((_, index) => index !== tabId)
            } else {
                dispatch(applicationActions.pushError({ title: { code: 'remove' }, messages: { code: 'w0362' } }))
                return containers
            }
        })
    }

    return <>
        <ContainerPackagesSummaryView containers={containers} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <TabsCard>
        <TabsCardHeader selectedValue={containerIndex} onSelectedValueChange={onSelectedValueChange} >
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} onClose={closeCommon} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <ContainerInfoView container={containers[containerIndex]} containerIndex={containerIndex} setContainers={setContainers} containerTypes={containerTypes} />
            <GridItem style={{ border: 'none', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
                <PackageGridView container={containers[containerIndex]} containerIndex={containerIndex} setContainers={setContainers} />
            </GridItem>
        </TabsCardContent>
    </TabsCard>
    </>
}

interface PackagePartResult {
    lineId: number,

    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: Date,
    outboundByPackage: boolean,
    m3Outer?: number
    netWeightOuter?: number
    grossWeightOuter?: number

    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    soDetailId: number,
    contractRouteId: number,
    qty?: number,
    notYetDeliveryQty?: number,
    uomCode?: string,
    netWeight?: number,
    grossWeight?: number,
    externalOrderNo?: string,
    sellingPrice?: number
    rolledPartsFlag?: number,
    netWeightPart?: number
    grossWeightPart?: number
}

const PackageGridView = ({ container, containerIndex, setContainers }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {
    const [entityList, setEntityList] = useState<PackagePartResult[]>([])
    useMemo(() => {
        let index = 0
        const contPartsList: PackagePartResult[] = []
        container.outerPackageList?.forEach(pkg => {
            pkg.innerPackageList.forEach(box => {
                box.partsList.forEach(part => {
                    contPartsList.push({
                        lineId: index++,
                        ...pkg,
                        ...part,
                        m3Outer: pkg.m3,
                        netWeightOuter: pkg.netWeight,
                        grossWeightOuter: pkg.grossWeight,
                        netWeightPart: part.netWeight,
                        grossWeightPart: part.grossWeight,
                    })
                })
            })
        })
        setEntityList(contPartsList)
    }, [container.outerPackageList])

    const intl = useIntl()
    const outerPackageCategories = useMemo(() => [{ key: 'outerPackage', value: intl.formatMessage({ id: 'outerPkgInfo' }) }], [intl])
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 220 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 280 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uom' }), width: 100 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 120 },

        { field: 'palletNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'palletNo' }), categories: outerPackageCategories, width: 240 },
        { field: 'outerPackageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), categories: outerPackageCategories, width: 240 },
        { field: 'outerPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgType' }), categories: outerPackageCategories, width: 200 },
        { field: 'productionDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'productionDate' }), categories: outerPackageCategories, width: 200 },
        { field: 'm3Outer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: outerPackageCategories, width: 120 },
        { field: 'netWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: outerPackageCategories, width: 120 },
        { field: 'grossWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: outerPackageCategories, width: 150 },
    ], [intl, outerPackageCategories])

    const defaultEditingDisabled = Records.from(columns
        .filter(f => !f.field.includes('qty') 
            && !f.field.includes('m3Outer')
            && !f.field.includes('netWeightOuter')
            && !f.field.includes('grossWeightOuter')
            && !f.field.includes('productionDate'))
        .map(({ field }) => [field, { editingDisabled: true }]))
        
    const containerConverter = useCallback((list: PackagePartResult[], previous: ContainerInfo) => {
        if (list.length === 0) {
            return {}
        }
        
        const outerPackageMap: { [outerPkgNo: string] : OutboundPartsInfo[] } = {}
        const outerPackages: OuterPackageInfo[] = []
        list.forEach(e => {
            outerPackages.push({ 
                outerPackageNo: e.outerPackageNo, 
                outerPackageType: e.outerPackageType, 
                productionDate: e.productionDate, 
                m3: e.m3Outer, 
                netWeight: e.netWeightOuter, 
                grossWeight: e.grossWeightOuter,
                outboundByPackage: e.outboundByPackage,
                innerPackageList: [] 
            })

            if (!outerPackageMap[e.outerPackageNo]) {
                outerPackageMap[e.outerPackageNo] = []
            }
            outerPackageMap[e.outerPackageNo].push({
                soNo: e.soNo,
                sellerCode: e.sellerCode,
                sellerPartsNo: e.sellerPartsNo,
                sellerPartsName: e.sellerPartsName,
                sellerBackNo: e.sellerBackNo,
                colorCode: e.colorCode,
                srbq: e.srbq,
                partsNo: e.partsNo,
                soDetailId: e.soDetailId,
                contractRouteId: e.contractRouteId,
                qty: e.qty,
                notYetDeliveryQty: e.notYetDeliveryQty,
                uomCode: e.uomCode,
                netWeight: e.netWeightPart,
                grossWeight: e.grossWeightPart,
                externalOrderNo: e.externalOrderNo,
                sellingPrice: e.sellingPrice,
                rolledPartsFlag: e.rolledPartsFlag
            })
        })

        outerPackages.forEach(outer => {
            if (outerPackageMap[outer.outerPackageNo]) {
                const inner: InnerPackageInfo = {
                    isBlank: true,
                    partsList: outerPackageMap[outer.outerPackageNo]
                }
                outer.innerPackageList = [inner]
            }
        })

        const newContainer: ContainerInfo = {
            ...previous,
            outerPackageList: outerPackages
        }
        return newContainer
    }, [])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setEntityList(previous => {
            const list: PackagePartResult[] = previous.map((item, idx) => item.lineId === row.lineId ? row as PackagePartResult : item)
            setContainers(containers => {
                containers[containerIndex] = containerConverter(list, containers[containerIndex])
                return containers
            })
            return list
        })
        return true
    }, [containerConverter, containerIndex, setContainers])

    return <div style={{ width: '100%', height: 700 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={entityList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)}/>
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={true}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}

const prepareDownloadFromScreen = (data: OutboundCreateFactor, containers: ContainerInfo[], buyerList: Pair[], receiverList: Pair[], shipper: string, screenSelectPkgIds: number[]) => {
    const receiver = receiverList.find(f => f.first === data.receiver)?.second as string
    const buyer = buyerList.find(f => f.first === data.buyer)?.second as string
    const downloadList: DownloadOutBoundPartsInfo[] = []
    
    containers.forEach(ct => {
        ct.outerPackageList?.forEach(op => {
            op.innerPackageList.forEach(ip => {
                ip.partsList.forEach(part => {
                    downloadList.push({
                        shipper: shipper,
                        outboundNo: data.outboundRefNo,
                        outboundDate: data.outboundDate,
                        outboundTime: data.outboundTime ? moment(data.outboundTime).format(moment.HTML5_FMT.TIME_SECONDS) : undefined,
                        bookingNo: data.bookingNo,
                        partsNo: part.partsNo,
                        uomCode: part.uomCode,
                        outboundQty: part.qty,
                        shippingMode: data.shippingMode,
                        outboundType: data.outboundType,
                        receiver: receiver,
                        buyer: buyer,
                        etd: data.etd,
                        eta: data.eta,
                        containerTruckNo: ct.containerNo,
                        containerTruckType: ct.containerType,
                        commodityType: ct.commodityType,
                        sealNo: ct.sealNo,
                        containerM3: ct.m3,
                        containerNetWeight: ct.netWeight,
                        containerGrossWeight: ct.grossWeight,
                        productionDate: op.productionDate,
                        palletNo: op.palletNo,
                        outerPackageNo: op.outerPackageNo,
                        outerPackageType: op.outerPackageType,
                        outerM3: op.m3,
                        outerNetWeight: op.netWeight,
                        outerGrossWeight: op.grossWeight,
                        innerPackageNo: ip.boxNo,
                        innerPackageType: ip.ipPackageType,
                        innerM3: ip.m3,
                        innerNetWeight: ip.netWeight,
                        innerGrossWeight: ip.grossWeight,
                        soNo: part.soNo,
                        sellerCode: part.sellerCode,
                        sellerPartsNo: part.sellerPartsNo,
                        sellerPartsName: part.sellerPartsName,
                        sellerBackNo: part.sellerBackNo,
                        colorCode: part.colorCode,
                        srbq: part.srbq,
                        remainingQty: part.notYetDeliveryQty,
                        originalOutboundRefNo: op.originalOutboundRefNo,
                    })
                })
            })
        })
    })
    return downloadList
}