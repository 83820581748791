import { DialogActions, DialogContent, Typography } from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { Check, Sync, VpnKey } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, StringItem, useFieldComponent } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import appConfig from "../../../configs/appConfig"
import { Language, effectiveLanguage } from "../../../configs/i18n/Language"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadUserDetail } from "../../../services/system/apis/userApi"
import { UserStatus } from "../../../services/system/enums/UserStatus"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import { cas020Actions, useCAS020Selector } from "./CAS020Slice"
import messagesEnUs from './i18n/cas020.en-US.json'

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': messagesEnUs,
    'zh-CN': messagesEnUs,
}

export const CAS020 = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cas020Actions.listUsers())
        dispatch(cas020Actions.listCbdss())
    }, [dispatch])

    return <ScreenIntlProvider>
        <View flex>
            <SectionCard>
                <SectionCardContent>
                    <DataTable />
                </SectionCardContent>
            </SectionCard>
        </View>
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

const DataTable = () => {
    const intl = useIntl()
    const users = useCAS020Selector(state => state.users)
    const cbdss = useCAS020Selector(state => state.cbdss)
    const [selections, setSelections] = useState<string[]>([])

    const getLoginId = useCallback((row: Row) => row.account.loginId, [])
    const getCbds = useCallback((row: Row) => {
        return row.cbdss.map((userCbds: any) => {
            return cbdss.find(cbds => cbds.cbdsUid === userCbds.cbdsUid)?.cbdsCode ?? ''
        }).join(', ')
    }, [cbdss])
    const getStatus = useCallback((row: Row) => {
        if (row.account.active && !row.account.locked) {
            return intl.formatMessage({ id: 'enum.active' })
        } else if (row.account.active && row.account.locked) {
            return intl.formatMessage({ id: 'enum.locked' })
        } else {
            return intl.formatMessage({ id: 'enum.inactive' })
        }
    }, [intl])
    const columns = useMemo(() => [
        { field: 'loginId', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.loginId' }), width: 150, getCellValue: getLoginId },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.name' }), width: 200 },
        { field: 'email', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyEmail' }), width: 300 },
        { field: 'contactCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyContactCode' }), width: 150 },
        { field: 'localCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyLocalCode' }), width: 150 },
        { field: 'phone', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyPhone' }), width: 150 },
        { field: 'cbds', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds' }), width: 250, getCellValue: getCbds },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.status' }), width: 100, getCellValue: getStatus },
    ], [getCbds, getLoginId, getStatus, intl])
    const getRowId = useCallback((row: Row) => row.id, [])

    const poweruser = useApplicationSelector(state => state.auth.user?.powerUser)
    const currentLoginId = useApplicationSelector(state => state.auth.user?.account.loginId)
    const displayEdit = useCallback((tableRow: TableRow) => poweruser || tableRow.row?.account.loginId === currentLoginId, [currentLoginId, poweruser])
    const displayChangePassword = useCallback((tableRow: TableRow) => tableRow.row?.account.loginId === currentLoginId, [currentLoginId])
    const actionProps1 = useMemo(() => ({ selections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.UserStatus} />
        <Data rows={users} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={CreateAction} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} display={displayEdit} />
        <RowActionProvider name="changePassword" Action={ChangePasswordRowAction} display={displayChangePassword} />
        <RowActionProvider name="resetPassword" Action={ResetPasswordRowAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['localCode', 'phone']}
            columnSettings={{
                loginId: { disableUserControl: true },
                userName: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={UsersFilter} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={160} />
    </DataGrid>
}

const UsersFilter = () => {
    const intl = useIntl()
    const sessionKey = `${appConfig.appFullName}:CAS020.filter`
    const dispatch = useDispatch()
    const [filter, setFilter] = useState(() => {
        let cachedFilter = {}
        try {
            cachedFilter = JSON.parse(sessionStorage.getItem(sessionKey) ?? '{}')
        } catch (e) {}
        const defaultFilter = {
            loginIdContains: null as string | null,
            nameContains: null as string | null,
            emailContains: null as string | null,
            cbdsUidIn: null as string[] | null,
            statusIn: null as number[] | null,
            contactCodeContains: null as string | null,
            localCodeContains: null as string | null,
            phoneContains: null as string | null,
        }
        return { ...defaultFilter, ...cachedFilter }
    })
    const filterCounter = useCallback((filter: any) => {
        return [
            filter.loginIdContains,
            filter.nameContains,
            filter.emailContains,
            // filter.cbdsUidIn,
            filter.statusIn,
            filter.contactCodeContains,
            filter.localCodeContains,
            filter.phoneContains,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const clear = useCallback(() => {
        return {
            loginIdContains: null,
            nameContains: null,
            emailContains: null,
            cbdsUidIn: null,
            statusIn: null,
            contactCodeContains: null,
            localCodeContains: null,
            phoneContains: null,
        }
    }, [])
    const submit = useCallback(() => {
        dispatch(cas020Actions.setFilter({
            nameContains: filter.nameContains === null ? undefined : filter.nameContains,
            emailContains: filter.emailContains === null ? undefined : filter.emailContains,
            phoneContains: filter.phoneContains === null ? undefined : filter.phoneContains,
            contactCodeContains: filter.contactCodeContains === null ? undefined : filter.contactCodeContains,
            localCodeContains: filter.localCodeContains === null ? undefined : filter.localCodeContains,
            statusIn: filter.statusIn === null ? undefined : filter.statusIn,
            loginIdContains: filter.loginIdContains === null ? undefined : filter.loginIdContains,
            cbdsUidIn: filter.cbdsUidIn === null ? undefined : filter.cbdsUidIn,
        }))
        dispatch(cas020Actions.listUsers())
        sessionStorage.setItem(sessionKey, JSON.stringify(filter))
    }, [dispatch, filter, sessionKey])

    // const cbdss = useCAS020Selector(state => state.cbdss)
    // const cbdsEntries = useMemo<[string, string][]>(() => cbdss.map(cbds => ([cbds.cbdsUid, cbds.cbdsCode])), [cbdss])
    const statusEntries = useMemo<[number, string][]>(() => [
        [UserStatus.Active, intl.formatMessage({ id: 'enum.active' })],
        [UserStatus.Inactive, intl.formatMessage({ id: 'enum.inactive' })],
        [UserStatus.Locked, intl.formatMessage({ id: 'enum.locked' })]
    ], [intl])

    return <FilterToolbarItem
        columnCount={3}
        filters={filter}
        onFiltersChange={setFilter}
        filterCounter={filterCounter}
        clear={clear}
        onSubmit={submit}
    >
        <StringItem field="loginIdContains" label={intl.formatMessage({ id: 'filter.loginId' })} />
        <StringItem field="nameContains" label={intl.formatMessage({ id: 'filter.name' })} />
        <StringItem field="emailContains" label={intl.formatMessage({ id: 'filter.companyEmail' })} />
        {/* <EntriesItem field="cbdsUidIn" label={intl.formatMessage({ id: 'filter.cbdsUids' })} entries={cbdsEntries} /> */}
        <EntriesItem field="statusIn" label={intl.formatMessage({ id: 'filter.statuss' })} entries={statusEntries} />
        <StringItem field="contactCodeContains" label={intl.formatMessage({ id: 'filter.companyContactCode' })} />
        <StringItem field="localCodeContains" label={intl.formatMessage({ id: 'filter.companyLocalCode' })} />
        <StringItem field="phoneContains" label={intl.formatMessage({ id: 'filter.companyPhone' })} />
    </FilterToolbarItem>
}

const DownloadAction = ({ selections }: {
    selections: string[]
}) => {
    const dispatch = useDispatch()

    const dowloadApi = useDownloadUserDetail()
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadUserDetail' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ userIds: selections })
    }, [dispatch, dowloadApi, selections])

    return <DownloadGroupedToolbarAction access="ACCS.CAS020.DOWNLOAD" >
        {onClose =>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        }
    </DownloadGroupedToolbarAction>
}


const CreateAction = () => {
    return <CreateRedirectToolbarAction
        power
        access="ACCS.CAS020.CREATENEW"
        title={<FormattedMessage id="action.create" />}
        path="/user/create" />
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/user/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction
        tableRow={tableRow}
        access="ACCS.CAS020.VIEWDETAIL"
        title={<FormattedMessage id="action.view" />}
        path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/user/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction
        tableRow={tableRow}
        access="ACCS.CAS020.EDIT"
        title={<FormattedMessage id="action.edit" />}
        path={path} />
}

const ChangePasswordRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = useState(false)
    const [oldPassword, setOldPassword] = useState<string | null>('')
    const [newPassword, setNewPassword] = useState<string | null>('')
    const [confirmPassword, setConfirmPassword] = useState<string | null>('')
    const [pass, setPass] = useState([false, false, false, false, false])

    useEffect(() => {
        setPass(pass => {
            const pass0 = !!newPassword && newPassword.length >= 8
            const pass1 = /[a-z]/.test(newPassword || '') && /[A-Z]/.test(newPassword || '')
            const pass2 = /[a-zA-Z]/.test(newPassword || '') && /[0-9]/.test(newPassword || '')
            const pass3 = /[!"#$%&'()*+,-./:;=?@[\]^_`{|}~]/.test(newPassword || '')
            const pass4 = !!newPassword && newPassword.length > 0 && !/[<>]/.test(newPassword || '')
            if (pass0 !== pass[0] || pass1 !== pass[1] || pass2 !== pass[2] || pass3 !== pass[3] || pass4 !== pass[4]) {
                return [pass0, pass1, pass2, pass3, pass4]
            } else {
                return pass
            }
        })
    }, [newPassword])

    const { Control, Label, Input, InputWrapper } = useFieldComponent('PasswordInput', {})
    const dispatch = useDispatch()
    return <>
        <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="action.changePassword" />} icon={<VpnKey />} callback={() => setOpen(true)} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="title.changePassword" />
            </DialogHeader>
            <DialogContent>
                <div>
                    <GridContainer>
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.oldPassword" /></Label>}
                            input={<Input
                                field="oldPassword"
                                value={oldPassword}
                                setValue={setOldPassword}
                            />}
                        />
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.newPassword" /></Label>}
                            input={<Input
                                field="newPassword"
                                value={newPassword}
                                setValue={setNewPassword}
                            />}
                        />
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.confirmPassword" /></Label>}
                            input={<Input
                                field="confirmPassword"
                                value={confirmPassword}
                                setValue={setConfirmPassword}
                            />}
                        />
                        <GridItem style={{ marginTop: 16 }}>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[0] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ At least 8 characters."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[1] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ A mixture of both uppercase and lowercase letters."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[2] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ A mixture of letters and numbers."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[3] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ Inclusion of at least one special character, e.g., ! @ # ? ] "}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[4] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ Note: do not use < or > in your password."}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction outlined disabled={!pass.every(v => v) || newPassword !== confirmPassword} title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas020Actions.changePassword({ oldPassword: oldPassword || '', newPassword: newPassword || '' }))
                    setOpen(false)
                }} />
            </DialogActions>
        </CardDialog>
    </>
}

const ResetPasswordRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = useState(false)
    const loginId = tableRow.row?.account.loginId
    const dispatch = useDispatch()
    return <>
        <CallbackRowAction power tableRow={tableRow} title={<FormattedMessage id="action.resetPassword" />} icon={<Sync />} callback={() => setOpen(true)} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="title.resetPassword" />
            </DialogHeader>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction outlined title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas020Actions.resetPassword({ username: loginId }))
                    setOpen(false)
                }} />
            </DialogActions>
        </CardDialog>
    </>
}