import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { BookmarkCreateResult, bookmarkCreateResultFromJson } from "../models/BookmarkCreateResult"
import { CargoTackingDetailResult, cargoTackingDetailResultFromJson } from "../models/CargoTackingDetailResult"
import { CargoTackingFactor, cargoTackingFactorFactorToJson } from "../models/CargoTackingFactor"
import { CargoTackingResults, EventResult, cargoTackingResultsFromJson } from "../models/CargoTackingResults"
import { SearchConditionFactor, searchConditionFactorFromJson } from "../models/SearchConditionFactor"
import { SearchConditionResult, Suggestion, searchConditionResultFromJson, suggestionToJson } from "../models/SearchConditionResult"

export const useGetCargoTrackingSuggestions = () => {
    const url = '/lcbm-shipping-detail-api/api/cargotrackingdetail/suggestions'
    const payloadProject = useCallback(searchConditionFactorFromJson, [])
    const resultProject = useCallback(searchConditionResultFromJson, [])
    return usePost<SearchConditionFactor, SearchConditionResult>(url, payloadProject, resultProject)
}

export const useListCargoTrackingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/cargotrackingdetail/list'
    const payloadProject = useCallback((obj: { suggestions: Suggestion[], limit: number }) => ({ suggestions: obj.suggestions.map(suggestionToJson), limit: obj.limit }), [])
    const resultProject = useCallback(cargoTackingDetailResultFromJson, [])
    return usePost<{ suggestions: Suggestion[], limit: number }, CargoTackingDetailResult>(url, payloadProject, resultProject)
}

export const useGetCargoTrackingPageList = () => {
    const url = '/lcbm-shipping-detail-api/api/listtracking'
    const payloadProject = useCallback(cargoTackingFactorFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(cargoTackingResultsFromJson), [])
    return usePost<CargoTackingFactor, PageResultEntity<CargoTackingResults>>(url, payloadProject, resultProject)
}

export const useCreateBookmark = () => {
    const url = '/lcbm-shipping-detail-api/api/createBookingMark'
    const payloadProject = useCallback((obj: { containerNo: string, blNo: string, carrierNo: string }) => obj, [])
    const resultProject = useCallback(Projects.array(bookmarkCreateResultFromJson), [])
    return usePost<{ containerNo: string, blNo: string, carrierNo: string }, BookmarkCreateResult[]>(url, payloadProject, resultProject)
}

export const useGetSingleContainerTracking = () => {
    const url = '/lcbm-shipping-detail-api/api/fetchTrackings'
    const payloadProject = useCallback(Projects.array(bookmarkCreateResultFromJson), [])
    const resultProject = useCallback((json: { events: EventResult[] }) => json, [])
    return usePost<BookmarkCreateResult[], { events: EventResult[] }>(url, payloadProject, resultProject)
}

export const useDeleteTrackings = () => {
    const url = '/lcbm-shipping-detail-api/api/deleteTrackings'
    const payloadProject = useCallback((obj: { bookMarkIds: string[] }) => obj.bookMarkIds, []);
    const resultProject = Projects.IGNORE;
    return usePost<{ bookMarkIds: string[] }, undefined>(url, payloadProject, resultProject);
}

export const useRefreshAllUncompletedBookmarks = () => {
    const url = '/lcbm-shipping-detail-api/api/refreshAllUncompletedBookmarks'
    const payloadProject = useCallback(obj => obj.bookMarkIds, []);
    const resultProject = useCallback(obj => obj, []);
    return usePost<{ bookMarkIds?: string[] }, string[]>(url, payloadProject, resultProject);
}

export const useRefreshCargoTrackingBybatch = () => {
    const url = '/lcbm-shipping-detail-api/api/refreshCargoTrackingBybatch'
    const payloadProject = Projects.IGNORE;
    const resultProject = useCallback(obj => obj, []);
    return usePost<undefined, string[]>(url, payloadProject, resultProject);
}

export const useExportContainerDataByBatch = () => {
    const url = '/lcbm-shipping-detail-api/api/exportContainerDataByBatch'
    const payloadProject = Projects.IGNORE;
    const resultProject = useCallback(obj => obj, []);
    return usePost<undefined, string[]>(url, payloadProject, resultProject);
}