import { DialogActions, DialogContent, IconButton, Tooltip } from "@material-ui/core"
import { Clear, SaveOutlined } from "@material-ui/icons"
import DoneIcon from '@material-ui/icons/Done'
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, ObjectTypeProvider, PaginationLayout, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { Break, DateItem, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { LibertyTypeProvider } from "../../../components/DataGrid/typeProviders/LibertyTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SoSpotStatus } from "../../../services/master/enums/SoSpotStatus"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useDownloadReceivedSpotOrder } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadReceivedSpotOrderWithContract } from "../../../services/order/apis/OrderUploadApi"
import { useConfirmAndSetFinal, useConfirmAndSwitch, useConfirmParts, useRejectParts, useSaveReSoSpotOrder } from "../../../services/order/apis/ReceivedPlaceOrderApi"
import { PartsDetail, ReSpotOrderResult } from "../../../services/order/models/ReSpotOrderDetailResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { ReSpotOrderResultBasic, useMergeDatas, useSplitDatas } from "./OCS023"
import { useShippingPlanCategory, useShippingPlanCategory2 } from "./ShippingPlanCategory"


interface OCS023PcUiProps {
    search: (orderId: number | null | undefined) => void,
    receiveDcList: TnvCbds[],
    basic: ReSpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReSpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    currencyList: TnmCurrency[],
    mode: ScreenMode,
    uomList: TnmUom[],
    paymentTermList: TnmPaymentTerms[],
}

export const OCS023PcUi = (props: OCS023PcUiProps) => {
    const { search, receiveDcList, basic, setBasic, forecasts, setForecasts, inboundPlans, currencyList, uomList, setInboundPlans, paymentTermList } = props
    const [messages, setMessages] = useState<Message[]>([] as Message[])
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const actions = [<ConfirmAndSwitchAction basic={basic} forecasts={forecasts} inboundPlans={inboundPlans} formValidate={formValidate} />, <ConfirmAndSetEndAction basic={basic} forecasts={forecasts} inboundPlans={inboundPlans} formValidate={formValidate}/>]
    const intl = useIntl()
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])

    const buttonActions = useMemo(() => {
        let actions2 = [<DownloadAction search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />]
        if (basic.status < 30) {
            actions2.push(<UploadAction search={search} uomArr={uomArr} editable={true} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />)
        }
        return actions2
    }, [basic, forecasts, inboundPlans, search, setBasic, setForecasts, setInboundPlans, uomArr])

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitleSub' })}
                actions={basic.status < 30 ? [<TempSave basic={basic} search={search} forecasts={forecasts} inboundPlans={inboundPlans} setMessages={setMessages}></TempSave>] : []}
            />
            <SectionCardContent>
                <OrderSummaryForm paymentTermList={paymentTermList} currencyList={currencyList} basic={basic} setBasic={setBasic} messages={messages} receiveDcList={receiveDcList} setMessages={setMessages}/>
            </SectionCardContent>
        </SectionCard>

        < SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'downloadOrUploadTitle' })}
                subtitle={intl.formatMessage({ id: 'downloadOrUploadTitleSub' })}
                actions={buttonActions}
            />
            <SectionCardContent>
            </SectionCardContent>
        </SectionCard >

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={4}
                title={intl.formatMessage({ id: 'inputShippingPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTable search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OrderSummaryForm = ({ basic, setBasic, messages, receiveDcList, currencyList, paymentTermList, setMessages }: {
    basic: ReSpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReSpotOrderResultBasic>>,
    messages: Message[],
    receiveDcList: TnvCbds[],
    currencyList: TnmCurrency[],
    paymentTermList: TnmPaymentTerms[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const intl = useIntl()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const readonly = (basic.status >= SoSpotStatus.Confirmed) ? true : false

    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter((m) => m.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])
    const currMap: [string, string][] = useMemo(() => currencyList.map(m => [m.currencyCode, m.currencyCode]), [currencyList])
    const paymentMap: [number, string][] = useMemo(() => paymentTermList.map((m) => [m.paymentTermsId, m.paymentTermsCode + '(' + m.description + ')']) ?? [], [paymentTermList])

    return <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} columnCount={3}>
        <StringItem field='soNo' readonly={true} label={intl.formatMessage({ id: 'salesOrderNo' })} />
        <CodeItem field="orderType" readonly={true} label={intl.formatMessage({ id: 'orderType' })} code={CodeCategory.OrderType} />
        <CodeItem field="status" readonly={true} label={intl.formatMessage({ id: 'status' })} code={CodeCategory.SoSpotStatus} />
        <Break />
        <StringItem field="buyerCode" readonly={true} label={intl.formatMessage({ id: 'buyer' })} />
        <EntryItem field="inboundDcId" readonly={true} label={intl.formatMessage({ id: 'receiveDc' })} entries={dcMap} />
        <EntryItem field="shipperDcId" readonly={readonly} label={intl.formatMessage({ id: 'shipperDc' })} entries={dcMap} />
        <Break />
        <CodeItem field="shippingMode" readonly={readonly} label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <Break />
        <EntryItem field="paymentTermsId" colSpan={2} readonly={readonly} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} entries={paymentMap} />
        <EntryItem field="currency" required readonly={readonly} label={intl.formatMessage({ id: 'field.currency' })} entries={currMap} />
        <Break />
        <StringItem field="remark" colSpan={2} readonly={readonly} label={intl.formatMessage({ id: 'field.remark' })} />
        <CodeItem field="customsFlag" readonly={readonly} label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
    </Form>
}

const TempSave = ({ basic, forecasts, setMessages, inboundPlans, search }: {
    basic: ReSpotOrderResultBasic,
    forecasts: PartsDetail[],
    inboundPlans: PartsDetail[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    search: (orderId: number | null | undefined) => void,
}) => {
    const navigate = useNavigate()
    const saveInfo = useSaveReSoSpotOrder()
    const mergeDatas = useMergeDatas()
    const intl = useIntl()
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        let flag = true
        if (forecasts && forecasts.length > 0) {
            forecasts.filter(f => f.orderLot || f.orderQty || f.sSpq).forEach(val => {
                if (!checkNumber(val.orderQty, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderQty' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.orderLot, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderLot' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.sSpq, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'spq' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.sellingPrice, 2)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'unitPrice' }, messages: { code: 'integerCheck' } }))
                    return
                }
            })
        }
        if (!checkLength(basic.remark ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'remark' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'remark' }), 255, 1] } }))
        }
        if (!flag) return
        const data = mergeDatas(basic, forecasts, inboundPlans)
        setDisabled(true)
        saveInfo(data, { serialized: true }).then(result => {
            navigate(`/rece/modify-${result.soSpotId}`)
            // search(result.spotId)
        }).finally(() => {
            setDisabled(false)
        })
    }, [basic, dispatch, forecasts, inboundPlans, intl, mergeDatas, navigate, saveInfo])
    return <>
        <CallbackToolbarAction callback={onclickToSave} title={<FormattedMessage id="save" />} icon={<SaveOutlined />} disabled={disabled}/>
    </>
}


const getFormCheckFields = (required: boolean) => {
    return {
        // globalPartsNo: { labelId: 'globalPartsNo', required: required, length: { max: 255 } },
        currency: { labelId: 'field.currency', required: required },
    }
}

interface ShippingPlanProps {
    basic: ReSpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReSpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    editable: boolean,
    uomArr: string[],
    search: (orderId: number | null | undefined) => void,
}



const ShippingPlanTable = memo((props: ShippingPlanProps) => {
    const { forecasts, setForecasts, editable, basic, uomArr, search } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const userType = useGetCompanyType()
    const showAdd: boolean = basic.status === SoSpotStatus.Confirmed
    const planDates = useMemo(() => {
        return forecasts && forecasts.length > 0 ? Arrays.distinct(forecasts.flatMap(parts => parts.soSpotDrBoList).map(plan => (plan.crdDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [forecasts])
    const esPlanDates = useMemo(() => {
        return forecasts && forecasts.length > 0 ? Arrays.distinct(forecasts.flatMap(parts => parts.estimatedInboundPlanList).map(plan => (plan.planDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [forecasts])

    const nextPlanDates = useMemo(() => {
        return forecasts && forecasts.length > 0 ? Arrays.distinct(forecasts.flatMap(parts => parts.nextSellerSoSpotDrSimuBoList).map(plan => (plan.estimatedCrdDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [forecasts])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 220 },
            { field: 'soPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'sSpq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'sellingPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.unitPrice' }), width: 150 },
        ] as Column[]

        //BUyer Plan
        const getInboundCategories = (index: number) => [
            { key: 'inbound', value: intl.formatMessage({ id: 'buyerRequestedInboundPlan' }) },
            { key: `inbound${index}`, value: intl.formatDate(planDates[index], { dateStyle: 'medium' }) },
        ]
        const inboundPlanColumns = Arrays.range(0, planDates.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'Qty' }), categories: getInboundCategories(index), width: 150, getCellValue: (row: Row) => row.soSpotDrBoList.find((f: any) => f.crdDate.getTime() === planDates[index].getTime())?.crdQty ?? null, },
        ])

        //Next 
        const getNextInboundCategories = (index: number) => [
            { key: 'inbound', value: intl.formatMessage({ id: 'nextSellerEstimatedInboundPlan' }) },
            { key: `inbound${index}`, value: intl.formatDate(nextPlanDates[index], { dateStyle: 'medium' }) },
        ]
        const nextInboundPlanColumns = Arrays.range(0, nextPlanDates.length).flatMap(index => [
            { field: 'estimatedQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'Qty' }), categories: getNextInboundCategories(index), width: 150, getCellValue: (row: Row) => row.nextSellerSoSpotDrSimuBoList.find((f: any) => f.estimatedCrdDate.getTime() === nextPlanDates[index].getTime())?.estimatedQty ?? null, },
        ])

        //ES
        const getEsPlanCategories = (index: number) => [
            { key: 'esplan', value: intl.formatMessage({ id: 'estimatedInboundPlan' }) },
            { key: `plan2${index}`, value: intl.formatDate(esPlanDates[index], { dateStyle: 'medium' }) }]
        const esqtytitle = intl.formatMessage({ id: 'Qty' })
        const dataTypeName = (basic.shipperDcId || userType === CbdsType.SUPP) ? 'shippingPlan' : 'shippingPlan2'
        const esplanColumns = esPlanDates.map((planDate, index) => ({
            field: `planQty2${index}`,
            dataTypeName: dataTypeName,
            title: esqtytitle,
            categories: getEsPlanCategories(index),
            width: 180,
            getCellValue: (row: Row) => row.estimatedInboundPlanList.find((f: any) => f.planDate.getTime() === planDate.getTime())?.planQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.estimatedInboundPlanList?.some((plan: any) => plan.planDate.getTime() === planDate.getTime())) {
                    return ({ ...row, estimatedInboundPlanList: row.estimatedInboundPlanList?.map((plan: any) => plan.planDate.getTime() === planDate.getTime() ? { ...plan, planQty: value } : plan) })
                } else {
                    const estimatedInboundPlanList = row.estimatedInboundPlanList
                    estimatedInboundPlanList.push({
                        planDate: planDate,
                        planQty: value,
                    })
                    return ({ ...row, estimatedInboundPlanList: estimatedInboundPlanList })
                }
            }
        }))

        if (basic.status >= SoSpotStatus.Confirmed) {
            return Arrays.concat(fixedColumns, inboundPlanColumns, esplanColumns, nextInboundPlanColumns)
        } else {
            return Arrays.concat(fixedColumns, inboundPlanColumns)
        }
    }, [basic.shipperDcId, basic.status, esPlanDates, intl, nextPlanDates, planDates, userType])

    const mergeDatas = useMergeDatas()
    const data = mergeDatas(basic, forecasts, forecasts)
    const actionProps = useMemo(() => ({ forecasts, data, search }), [forecasts, data, search])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setForecasts(forecasts => forecasts.map(item => item.soSpotDetailId === row.soSpotDetailId ? row as PartsDetail : item))
        return true
    }, [setForecasts])

    const defaultEditingDisabled = basic.status < 30 ?
        Records.from(columns.filter(f => ((basic.shipperDcId || userType === CbdsType.SUPP) ? !f.field.includes('planQty2') : !f.field.includes('-1')) && !f.field.includes('sellingPrice') && !f.field.includes('uomCode') && !f.field.includes('soPartsNo') && !f.field.includes('sSpq') && !f.field.includes('orderLot')).map(({ field }) => [field, { editingDisabled: true }]))
        :
        Records.from(columns.filter(f => ((basic.shipperDcId || userType === CbdsType.SUPP) ? !f.field.includes('planQty2') : !f.field.includes('-1'))).map(({ field }) => [field, { editingDisabled: true }]))

    const [actions, setActions] = useState<RequestInboundPlanProps>({ open: false, editDate: null })
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("planQty2") && setActions({ open: true, editDate: esPlanDates[Number(field.substring(8))] })
    }, [esPlanDates])
    const shippingPlanCategory = useShippingPlanCategory(clickHeader)
    const shippingPlanCategory2 = useShippingPlanCategory2()

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={forecasts} columns={columns} />
            <StringTypeProvider />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <NumberTypeProvider name="shippingPlan2" Category={shippingPlanCategory2} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <RowActionProvider name="confirm" Action={ConfirmPartsRowAction} actionProps={actionProps} />
            <RowActionProvider name="reject" Action={RejectPartsRowAction} actionProps={actionProps} />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => showAdd} />
            <ColumnFreeze />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Action width={200} />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={forecasts} setInboundPlans={setForecasts} />
    </div>
})

interface RequestInboundPlanProps {
    open: boolean,
    editDate: Date | null
}
const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null })} />
}
const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestInboundPlanProps,
    setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null }>({ inboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])
    const intl = useIntl()
    useEffect(() => {
        setFactor({ inboundPlanDate: editDate })
    }, [editDate])
    const aplyDate = useCallback(() => {
        if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0441' } }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            if (inboundPlans.flatMap(f => f.estimatedInboundPlanList).some(s => moment(s.planDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, estimatedInboundPlanList: !editDate ? [...m.estimatedInboundPlanList, {
                        soDetailId: m.soSpotDetailId,
                        planDate: inboundPlanDate,
                        planQty: null
                    }] : m.estimatedInboundPlanList.map(plan => plan.planDate.getTime() === editDate.getTime() ? { ...plan, planDate: inboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundPlanDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}


const UploadAction = (props: ShippingPlanProps) => {
    const { basic, setBasic, setForecasts, setInboundPlans } = props
    const uploadReSpotOrder = useUploadReceivedSpotOrderWithContract()
    const splitDatas = useSplitDatas()

    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadReSpotOrder({ file: files[0], soSpotId: basic.soSpotId }, { serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)
                }
            })
        })
    }, [basic.soSpotId, setBasic, setForecasts, setInboundPlans, splitDatas, uploadReSpotOrder])

    return <UploadGroupedToolbarAction >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = (props: ShippingPlanProps) => {
    const { basic, forecasts, inboundPlans } = props
    const mergeDatas = useMergeDatas()
    const down = useDownloadReceivedSpotOrder()
    const download = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        down(data)
    }, [basic, down, forecasts, inboundPlans, mergeDatas])
    return <DownloadGroupedToolbarAction >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ConfirmAndSetEndAction = memo((props: {
    basic: ReSpotOrderResultBasic, forecasts: PartsDetail[], inboundPlans: PartsDetail[], formValidate: (data: any, actionId?: string | undefined) => boolean 
}) => {
    const { basic, forecasts, inboundPlans, formValidate } = props

    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const confirmAndSetFinal = useConfirmAndSetFinal()
    const dispatch = useDispatch()
    const intl = useIntl()

    const onclickSave = useCallback(() => {
        if(!formValidate(basic)) {
            return
        }
        let flag = true
        if (!checkLength(basic.remark ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'remark' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'remark' }), 255, 1] } }))
        }
        if (!flag) return
        const data = mergeDatas(basic, forecasts, inboundPlans)
        confirmAndSetFinal(data, { serialized: true }).then(result => {
            if (result) {
                navigate(`/rece`)
            }
        })
    }, [formValidate, basic, mergeDatas, forecasts, inboundPlans, confirmAndSetFinal, dispatch, intl, navigate])

    if (basic.status < 30) return <CallbackViewAction outlined title={<FormattedMessage id="confirmAndSetFinal" />} callback={onclickSave} />
    return <></>


})

const ConfirmAndSwitchAction = memo((props: {
    basic: ReSpotOrderResultBasic, forecasts: PartsDetail[], inboundPlans: PartsDetail[], formValidate: (data: any, actionId?: string | undefined) => boolean
}) => {
    const { basic, forecasts, inboundPlans, formValidate } = props
    const navigate = useNavigate()
    const companyType = useGetCompanyType()
    const mergeDatas = useMergeDatas()
    const confirmAndSwitch = useConfirmAndSwitch()
    const dispatch = useDispatch()
    const intl = useIntl()

    const onclickSave = useCallback(() => {
        if (!formValidate(basic)) {
            return 
        }
        let flag = true
        if (!checkLength(basic.remark ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'remark' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'remark' }), 255, 1] } }))
        }
        if (!flag) return
        const data = mergeDatas(basic, forecasts, inboundPlans)
        confirmAndSwitch(data, { serialized: true }).then(result => {
            if (result && result.spotId) {
                navigate(`/placebuorder/sowcDetailEdit-${result.spotId}`)
            }
        })
    }, [formValidate, basic, mergeDatas, forecasts, inboundPlans, confirmAndSwitch, dispatch, intl, navigate])

    if (companyType === CbdsType.BU && basic.status < 30) return <CallbackViewAction title={<FormattedMessage id="comfirm and switch" />} callback={onclickSave} />
    return <></>

})

const checkNumber = (field: number, type: number) => {
    if (type === 1) {
        if (field && field <= 0) {
            return false
        }
        return true
    } else if (type === 2) {
        if (field && field < 0) {
            return false
        }
        return true
    }
}

const ConfirmPartsRowAction = ({ tableRow, data, search }: DataGridRowActionProps & { data: ReSpotOrderResult, search: (orderId: number | null | undefined) => void, }) => {
    const confirmParts = useConfirmParts()
    // const saveInfo = useSaveReSoSpotOrder()
    const intl = useIntl()
    const detailId = tableRow.row?.soSpotDetailId
    const detailData: PartsDetail = data.ocs023ParamDetailBo.filter(m => m.soSpotDetailId === detailId)[0]
    const viewClick = useCallback(() => {
        // saveInfo(data, { silent: true, serialized: true }).then(result => {
        confirmParts(detailData, { serialized: true }).then(result => {
            if (result) {
                search(result.soSpotId)
            }
        })
        // })
    }, [confirmParts, detailData, search])

    const status = tableRow.row?.status
    const flag = tableRow.row?.finalFlag
    const basicStatus = data.status
    if (basicStatus === 30 && status <= 30 && (flag === 1 || data.shipperDcId)) {
        return (
            <Tooltip title={intl.formatMessage({ id: 'confirmParts' })}>
                <IconButton onClick={viewClick}><DoneIcon /></IconButton>
            </Tooltip>
        )
    } else {
        return <></>
    }
}

const RejectPartsRowAction = ({ tableRow, data, search }: DataGridRowActionProps & { data: ReSpotOrderResult, search: (orderId: number | null | undefined) => void, }) => {
    const detailId = tableRow.row?.soSpotDetailId
    const detailData: PartsDetail = data.ocs023ParamDetailBo.filter(m => m.soSpotDetailId === detailId)[0]
    const nextSellerStatus = tableRow.row?.nextSellerStatus
    const intl = useIntl()
    const rejectParts = useRejectParts()
    // const saveInfo = useSaveReSoSpotOrder()
    const viewClick = useCallback(() => {
        // saveInfo(data,{serialized:true,silent:true}).then(result => {
        // if(result){
        rejectParts(detailData, { serialized: true }).then(result => {
            if (result) {
                search(result.soSpotId)
            }
        })
        // }
        // })
    }, [detailData, rejectParts, search])

    const detailStatus = tableRow.row?.status
    const basicStatus = data.status

    if (detailStatus === 10 && (basicStatus === 10 || (basicStatus === 30 && nextSellerStatus === 20))) {
        //if (detailStatus === 10 && (basicStatus === 10 || (basicStatus === 20 && nextSellerStatus === 20))) {
        return <>
            <Tooltip title={intl.formatMessage({ id: 'rejectParts' })} >
                <IconButton onClick={viewClick}><Clear /></IconButton>
            </Tooltip>
        </>
    } else {
        return <></>
    }
}

const StringTypeProvider = () => {
    return <ObjectTypeProvider name="stringFormat" Formatter={StringFormatter} />
}

const StringFormatter = ({ value }: ObjectFormatterProps) => {
    return <>
        {value}
    </>
}

const checkLength = (field: string, length: number) => {
    if (field.length > length) return false
    return true
}
