import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetActiveConttype } from "../../../services/common/apis/commonMasterApi"
import { useGetBuyerList, useGetOutboundInfoForEdit, userGetInventoryAllParts, userGetInventoryRollPackages } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { UploadOutboundType } from "../../../services/delivery/enums/UploadOutboundType"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { ContainerInfo, OutboundCreateFactor } from "../../../services/delivery/models/OutboundCreateFactor"
import { PendingOutboundRollPackage } from "../../../services/delivery/models/PedingOutboundRollPackageInfo"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { ShippingMode } from "../../../services/master/enums/ShippingMode"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { Pair } from "../../../services/utils/Pair"
import { today } from "../../../utils/ApplicationUtils"
import { LOS035PcUi } from "./LOS035PcUi"

export const LOS035 = () => {

    const { outboundNo } = useParams() as any

    const [buyerList, setBuyerList] = useState<Pair[]>([])
    const [receiverList, setReceiverList] = useState<Pair[]>([])
    const [dbOuterPackageList, setDbOuterPackageList] = useState<PendingOutboundRollPackage[]>([])
    const [outerPackageList, setOuterPackageList] = useState<PendingOutboundRollPackage[]>([])
    const [dbRollPartList, setDbRollPartList] = useState<InnerPartsResult[]>([])
    const [containerTypes, setContainerTypes] = useState<TnmContainerType[]>([])
    const [outboundInfo, setOutboundInfo] = useState<OutboundCreateFactor>(undefined as any)
    const [data, setData] = useState<OutboundCreateFactor>(defaultOutboundInfo)
    const [containers, setContainers] = useState<ContainerInfo[]>(defaultContainers)
    const [load, setLoad] = useState<number>(0)

    const getBuyerList = useGetBuyerList()
    const getReceiverList = useComMasterGetActiveCbds()
    const getRolledPartsList = userGetInventoryAllParts()
    const getRollPackageList = userGetInventoryRollPackages()
    const getContainerTypes = useComMasterGetActiveConttype()
    const getOutboundInfo = useGetOutboundInfoForEdit()
    const [hugeData, setHugeData] = useState<boolean>(true)

    const resetNotYetDeliveryQty = useCallback((containers: ContainerInfo[]) => {

        const calculateNotYetDeliveryQty = (dbOuterPackageList: PendingOutboundRollPackage[], dbRollPartList: InnerPartsResult[], outerPackageNo: String, sellerPartsNo: String, sellerCode:String) => {
            // if exist same qyt
            if(dbOuterPackageList.some(f => f.outerPackageNo === outerPackageNo && f.sellerPartsNo === sellerPartsNo)) {
                return dbOuterPackageList.find(f => f.outerPackageNo === outerPackageNo && f.sellerPartsNo === sellerPartsNo)?.qty
            }
            // query total not yet delivery qty
            const totalNotYetDevliveryQty = dbRollPartList.find(f => f.sellerPartsNo === sellerPartsNo && f.sellerCode === sellerCode)?.notYetDeliveryQty ?? 0
            const totalNonUnpackingQty = dbOuterPackageList.filter(f => f.sellerPartsNo === sellerPartsNo).reduce((acc, cur) => acc + (cur.qty ?? 0), 0)  
            return totalNotYetDevliveryQty - totalNonUnpackingQty
        }
        
        //TODO
        const afterValue = containers.map(container => ({
            ...container,
            outerPackageList: (container.outerPackageList ?? []).map(outerPackage => ({
                outerPackageNo: outerPackage.outerPackageNo,
                palletNo: outerPackage.palletNo,
                outerPackageType: outerPackage.outerPackageType,
                productionDate: outerPackage.productionDate,
                m3: outerPackage.m3,
                netWeight: outerPackage.netWeight,
                grossWeight: outerPackage.grossWeight,
                outboundByPackage: outerPackage.outboundByPackage,
                innerPackageList: (outerPackage.innerPackageList ?? []).map(innerParts => ({
                    ...innerParts,
                    partsList: (innerParts.partsList ?? []).map(parts => ({
                        ...parts,
                        notYetDeliveryQty: calculateNotYetDeliveryQty(dbOuterPackageList, dbRollPartList, outerPackage.outerPackageNo, parts.sellerPartsNo,  parts.sellerCode),
                        rolledPartsFlag: dbRollPartList.find(f => f.sellerPartsNo === parts.sellerPartsNo && f.sellerCode === parts.sellerCode)?.rolledPartsFlag,
                    }))
                }))
            }))
        }))
        return afterValue;
    }, [dbOuterPackageList, dbRollPartList])

    const restPackageList = useCallback((buyer: string) => {
        // find buyer
        const buyerInfo = buyerList.find(f => f.first === buyer);
        const buyerRolledParts = dbRollPartList.filter(m => m.buyers.includes(buyerInfo?.second as string ?? ''))
        const sellerPartNoList = buyerRolledParts.map(m => m.sellerPartsNo)
        setOuterPackageList(dbOuterPackageList.filter(m => m.sellerPartsNo && sellerPartNoList.includes(m.sellerPartsNo)))
    }, [buyerList, dbOuterPackageList, dbRollPartList])

    useEffect(() => {

        getBuyerList(undefined, { silent: true }).then((result) => {
            setBuyerList(result?.buyerList || [])
        })
        getReceiverList({ types: [CbdsType.DC, CbdsType.CUST] }, { silent: true }).then((result) => {
            setReceiverList(result?.map(m => ({ first: m.cbdsUid, second: m.cbdsCode })) || [])
        })

        getRolledPartsList({}, { silent: true, serialized: true }).then((result) => {
            setDbRollPartList(result || [])
            const sellerPartNos = result.map(m => m.sellerPartsNo)
            getRollPackageList({ sellerPartNos: sellerPartNos }, { silent: true }).then((packages) => {
                setDbOuterPackageList(packages)
                setLoad(load => load + 1)
            })
            setLoad(load => load + 1)
        })

        getContainerTypes(undefined, { silent: true }).then((result) => {
            setContainerTypes(result || [])
        })
    }, [getBuyerList, getContainerTypes, getRolledPartsList, getRollPackageList, getReceiverList])

    useEffect(() => {
        if (outboundNo !== null && outboundNo !== undefined) {
            getOutboundInfo({ outboundNo: outboundNo }, { silent: true, serialized: true }).then((result) => {
                setOutboundInfo(result)
                setLoad(load => load + 1)
                result.containers?.forEach(container => {
                    setHugeData(container.outerPackageList != null && container.outerPackageList?.length > 20)
                })
            })
        } else {
            setHugeData(false)
        }
    }, [getOutboundInfo, outboundNo])

    useEffect(() => {
        if (load === 3 && outboundInfo) {
            setData({ ...outboundInfo, containers: undefined })
            setContainers(resetNotYetDeliveryQty(outboundInfo.containers ?? defaultContainers))
            // set parts and set net
            restPackageList(outboundInfo.buyer)
        }
    }, [load, outboundInfo, resetNotYetDeliveryQty, restPackageList])

    return <LOS035PcUi
        data={data}
        setData={setData}
        containers={containers}
        setContainers={setContainers}
        hugeData={hugeData}
        setHugeData={setHugeData}
        buyerList={buyerList}
        receiverList={receiverList}
        receiverOuterPackages={outerPackageList}
        dbRollPartList={dbRollPartList}
        dbRollPackageList={dbOuterPackageList}
        containerTypes={containerTypes}
        restPackageList={restPackageList}
        resetNotYetDeliveryQty={resetNotYetDeliveryQty}
    />
}

export const defaultOutboundInfo = {
    buyer: '',
    receiver: '',
    outboundDate: today(),
    outboundRefNo: '',
    outboundType: UploadOutboundType.OUTBOUND,
    shippingMode: ShippingMode.SEA,
    datasource: OutboundDatasource.INTERNAL_NO_ORDER,
}

export const defaultContainers = [{
    containerNo: '',
    outerPackageList: []
}]

